import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

import { Autocomplete, TextField } from "@mui/material";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Post Points Coupon method

import { PostAdminCoupon } from "utils/apis/ecommerce/coupons";
import "app.css";

function mapTextToEnumValue(text) {
  switch (text) {
    case "Disable":
      return 0;
    case "Enable":
      return 1;
    default:
      return "";
  }
}

function mapEnumValueToText(enumValue) {
  switch (enumValue) {
    case 0:
      return "Disable";
    case 1:
      return "Enable";
    default:
      return "";
  }
}

function CouponCreate() {
  const defualtFormFields = {
    name: "",
    code: "",
    discount: "",
    type: 0, // TODO
    total_max: "",
    status: 1, // TODO
    for_customer_id: null,
    date_start: "",
    date_end: "",
    uses_total: null,
    uses_customer: null,
  };

  const [formFields, setFormFields] = useState(defualtFormFields);

  const {
    name,
    code,
    discount,
    total_max,
    status,
    type,
    date_start,
    date_end,
    for_customer_id,
    uses_total,
    uses_customer,
  } = formFields;

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  const handleStatusChange = (_, value, reason) => {
    if (reason === "clear") {
      return setFormFields((previousState) => ({
        ...previousState,
        status: "",
      }));
    }

    if (reason === "selectOption") {
      if (value) {
        return setFormFields((previousState) => ({
          ...previousState,
          status: mapTextToEnumValue(value),
        }));
      }
    }
  };

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    return setFormFields({ ...formFields, [name]: value });
  };

  const formatDate = (dateString) => {
    const dt = new Date(dateString);
    const isoFormat = dt.toISOString();
    return isoFormat;
  };

  const handleStartDateChange = (_, value) => {
    setFormFields((previousState) => {
      return { ...previousState, date_start: formatDate(value) };
    });
  };

  const handleEndDateChange = (_, value) => {
    setFormFields((previousState) => {
      return { ...previousState, date_end: formatDate(value) };
    });
  };

  // // handle create points coupon submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate name and code and discount and start, end dates values
    if (!name || !code || !discount | !date_start || !date_end || !status) {
      return setErrorMSG("Please fill all the fields");
    }
    /// send post coupon request
    const result = await PostAdminCoupon({
      name,
      code,
      discount,
      status,
      type,
      total_max,
      for_customer_id,
      date_start,
      date_end,
      uses_customer,
      uses_total,
    });

    //  this is not an error
    if (!(result instanceof Error)) {
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Coupon created successfuly");

        // navigate to points coupons list page
        navigate("/ecommerce/general-coupons-list");
      } else {
        // backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // repsonse error
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center">
              <MDBox mb={3}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Coupon
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New Coupon
                  </MDTypography>
                </MDBox>
                <MDBox sx={{ mb: 2 }}>
                  <Autocomplete
                    name="status"
                    onChange={handleStatusChange}
                    defaultValue={mapEnumValueToText(status)}
                    options={["Enable", "Disable"]}
                    renderInput={(params) => <TextField {...params} label="Status" />}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    value={name}
                    name="name"
                    fullWidth
                    label="Name"
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput value={code} name="code" label="Code" onChange={handleChange} />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    sx={{ mr: 1 }}
                    value={discount}
                    name="discount"
                    label="Discount"
                    onChange={handleChange}
                    type="number"
                  />
                  <MDInput
                    sx={{ mr: 1 }}
                    value={uses_total}
                    name="uses_total"
                    label="uses total"
                    onChange={handleChange}
                    type="number"
                  />
                  <MDInput
                    sx={{ mr: 1 }}
                    value={uses_customer}
                    name="uses_customer"
                    label="uses customer"
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    value={for_customer_id}
                    name="for_customer_id"
                    fullWidth
                    label="for customer id"
                    onChange={handleChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    value={total_max}
                    name="total_max"
                    fullWidth
                    label="Total Max"
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDDatePicker
                    input={{
                      id: "start_date",
                      placeholder: "Select start date",
                    }}
                    options={{
                      enableTime: true,
                      enableSeconds: true,
                    }}
                    onChange={handleStartDateChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDDatePicker
                    input={{
                      id: "end_date",
                      placeholder: "Select end date",
                    }}
                    options={{
                      enableTime: true,
                      enableSeconds: true,
                    }}
                    onChange={handleEndDateChange}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CouponCreate;
