import React, { createContext, useReducer } from "react";

const SET_USERS_ACTIONS = {
  SET_USERS: "SET_USERS",
  CLEAR_USERS: "CLEAR_USERS",
  SET_ACTIVE_CUSTOMERMUMBERSHIPID: "SET_ACTIVE_CUSTOMERMUMBERSHIPID",
};

// Initial state of the users context

const initialState = {
  usersmembership: null,
  activeCustomerMembershipId: null,
};
// Users context reducer function
const usersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USERS_ACTIONS.SET_USERS:
      return { ...state, usersmembership: payload };
    case SET_USERS_ACTIONS.CLEAR_USERS:
      return initialState;
    case SET_USERS_ACTIONS.SET_ACTIVE_CUSTOMERMUMBERSHIPID:
      return { ...state, activeCustomerMembershipId: payload };
    default:
      return state;
  }
};

// Create the user context
const UsersMContext = createContext();

// Users context provider component
const UsersMContextProvider = ({ children }) => {
  const [{ usersmembership, activeCustomerMembershipId }, dispatch] = useReducer(
    usersReducer,
    initialState
  );

  // Action to set the user context
  const setUsersM = (usersmembership) => {
    dispatch({ type: SET_USERS_ACTIONS.SET_USERS, payload: usersmembership });
  };

  // Action to clear the user context
  const clearUsersM = () => {
    dispatch({ type: SET_USERS_ACTIONS.CLEAR_USERS });
  };

  const setActiveCustomerMembershipId = (id) => {
    dispatch({ type: SET_USERS_ACTIONS.SET_ACTIVE_CUSTOMERMUMBERSHIPID, payload: id });
  };

  const value = {
    usersmembership,
    activeCustomerMembershipId,
    setUsersM,
    clearUsersM,
    setActiveCustomerMembershipId,
  };
  return <UsersMContext.Provider value={value}>{children}</UsersMContext.Provider>;
};

export { UsersMContext, UsersMContextProvider };
