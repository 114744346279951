import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import YesOrNoDialog from "elements/YesOrNoDialog";

import { useContext, useEffect, useState } from "react";

// request uitils
import { FetchAdminCategoryById, FetchSubCategories } from "utils/apis/catalog/categories";

import { UserContext } from "context/user";

import noImage from "assets/images/no-image.jpg";
import Stack from "@mui/material/Stack";

import CircularProgress from "@mui/material/CircularProgress";
import NotFound404 from "assets/images/404.gif";

import DataTable from "./DataTable/index";
import { subCategoriesListHeader } from "./data/dataTableData";

import { DeleteAdminCategory } from "utils/apis/catalog/categories";

import MDButton from "components/MDButton";

import { NotificationsContext } from "context/notifications";

export default function CategoryDetailsComponent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [parentCategory, setParentCategory] = useState(null);
  const [childsCategories, setChildsCategories] = useState(null);
  const [loadingOrNot, setLoadingOrNot] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);
  const handleDeleteCategory = async () => {
    const { category_id } = parentCategory;
    const result = await DeleteAdminCategory(category_id);

    if (result.data.status) {
      setSuccessMSG("Category Deleted Successfuly");
      navigate("/catalog/categories");
    } else {
      setErrorMSG("Something went wrong");
    }
  };

  useEffect(() => {
    if (id) {
      const fetchCategoryAndSubCategories = async () => {
        setLoadingOrNot(true);

        const result = await FetchAdminCategoryById(id);

        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error) && result.data) {
          // set state of category
          setParentCategory(result.data);

          /// fetch sub categories
          const subCategoriesResult = await FetchSubCategories(id);
          if (!(subCategoriesResult instanceof Error) && result.data) {
            const { count, next, previous, results } = subCategoriesResult.data;
            setChildsCategories({
              count,
              next,
              previous,
              data: results,
            });
          } else {
            // this is an error
            setErrorMSG(subCategoriesResult.message);
          }
        } else {
          // result = error
          // if error status === 404, ignore the error and just show the 404 image (check the template expression inside the JSX).
          // else will set the error message to show it in case if there is network error or something else
          if (!(result.message === "Request failed with status code 404")) {
            setErrorMSG(result.message);
          }
        }
      };

      fetchCategoryAndSubCategories();
    }
  }, [id]);
  return (
    <MDBox mb={9} className="before-footer">
      <Grid container justifyContent="center">
        {loadingOrNot ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <Grid item xs={12}>
            <Card>
              {parentCategory ? (
                <>
                  <Grid
                    container
                    sx={{ alignItems: "center", justifyContent: "space-between" }}
                    spacing={4}
                  >
                    <Grid item xs={12} md={6}>
                      {/* <img
                        src={parentCategory.image}
                        width="100%"
                        height="auto"
                        style={{ borderTopLeftRadius: "4px" }}
                      /> */}
                      <MDBox
                        component="img"
                        src={parentCategory.image}
                        alt="category image"
                        width="100%"
                        height="auto"
                        sx={{
                          objectFit: "cover",
                          borderTopLeftRadius: "4px",
                          "@media only screen and (max-width: 435px)": {
                            borderTopRightRadius: "4px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 1,
                        "@media only screen and (max-width: 435px)": {
                          gap: 0,
                          marginInlineStart: 2,
                        },
                      }}
                    >
                      <MDTypography>#ID: {parentCategory.category_id}</MDTypography>
                      <MDTypography>Name : {parentCategory.description[0]?.name}</MDTypography>
                      <Stack
                        direction="row"
                        // justifyContent="space-between"
                        // alignItems="center"
                        spacing={1}
                      >
                        <MDTypography>Color : </MDTypography>
                        <MDBox
                          sx={{
                            background: parentCategory.category_color,
                            borderRadius: "50%",
                            display: "inline-block",
                          }}
                          color="white"
                          variant="gradient"
                          shadow="lg"
                          opacity={1}
                          p={2}
                        ></MDBox>
                      </Stack>
                      <MDTypography>
                        Transparency : {parentCategory.category_transparency}%
                      </MDTypography>
                      <MDTypography>sort order : {parentCategory.sort_order}</MDTypography>
                      <YesOrNoDialog
                        heading="Are you sure want to delete this Category !?"
                        description="if you delete it, you can not back from this"
                        functiontoDo={handleDeleteCategory}
                        openDialog={openDialog}
                        closeFunction={handleClose}
                      />
                      <MDButton
                        color="error"
                        onClick={handleClickOpen}
                        sx={{ marginTop: 1, display: "inline" }}
                      >
                        Delete
                      </MDButton>
                    </Grid>
                  </Grid>

                  <Grid container>
                    {childsCategories?.data.length > 0 && (
                      <>
                        <MDBox>
                          <MDTypography
                            sx={{
                              marginInlineStart: 3,
                              marginBlockStart: 2,
                              fontWeight: "bold",
                            }}
                          >
                            Sub Categories
                          </MDTypography>
                        </MDBox>

                        <DataTable
                          table={{
                            columns: subCategoriesListHeader,
                            rows: childsCategories.data.map((child) => ({
                              id: child.category_id,
                              name: child.description[0]?.name || "no name",
                              image: child.image,
                              actions: child.category_id,
                            })),
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <MDBox
                  my={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <MDBox component="img" alt="category picture" src={NotFound404} />
                </MDBox>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}
