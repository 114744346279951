import MDBox from "components/MDBox";

import { DeleteAdminAttributes } from "utils/apis/catalog/products/productsAttributes";
import { useContext } from "react";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";
import { ProductsAttributesContext } from "context/products/productAttributes";
import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

import YesOrNoDialog from "elements/YesOrNoDialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Stack from "@mui/material/Stack";
import { useState } from "react";

//import BrandEdit from "layouts/catalog/brands/brand-edit";
//import { useNavigate } from "react-router-dom";

const ActionsCell = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  const { productsAttributes, setProductsAttributes } = useContext(ProductsAttributesContext);

  // // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  const handleDeleteProductAttributes = async () => {
    const result = await DeleteAdminAttributes(id);

    if (!(result instanceof Error)) {
      if (!result.data.error && result.status === 204) {
        // delete it from ui
        const { count, next, previous, data } = productsAttributes;
        const newAttributes = data?.filter((attribute) => attribute.attribute_id != id);
        setProductsAttributes({
          count,
          next,
          previous,
          data: newAttributes,
        });

        handleClose();
        // show deleted alert
        setSuccessMSG("Attribute Deleted Successfuly");
      } else {
        // show error alert
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <>
      <YesOrNoDialog
        heading="Are you sure you want to delete this attribute !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteProductAttributes}
        openDialog={openDialog}
        closeFunction={handleClose}
      />

      <Stack direction="row" spacing={1}>
        <HighlightOffIcon
          color="primary"
          fontSize="medium"
          onClick={handleClickOpen}
          sx={{ cursor: "pointer" }}
        />

        <Link to={`/catalog/products/attributes/edit/${id}`}>
          <Icon color="info" fontSize="medium">
            edit
          </Icon>
        </Link>
      </Stack>
    </>
  );
};

export default ActionsCell;
