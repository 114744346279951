import React from "react";
import DataTable from "./DataTable";
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { FormControl, Select, InputLabel, Grid } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Data table headers
import { ordersHeaders } from "./data/dataTableData";
// Request Utils
import { UserContext } from "context/user";
import { useContext, useEffect } from "react";
import { FetchAdminOrders, FetchAdminOrders1 } from "../../../../utils/apis/sales/orders";

import CircularProgress from "@mui/material/CircularProgress";

import { OrdersContext } from "context/orders";
import { NotificationsContext } from "context/notifications";

import { displayBasicDate } from "utils/methods";

import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { convertStatusIdToString } from "utils/methods";
export default function OrdersListComponent({ customer_id, by_product, by_coupon }) {
  // Local State
  const { orders, setOrders, clearOrders, ordersFilters, setOrdersFilter } =
    useContext(OrdersContext);

  const [orderId, setOrderID] = useState(ordersFilters.orderId || null);
  const [customerId, setCustomerId] = useState(
    customer_id ? customer_id : ordersFilters.customerId || null
  );
  const [byProduct, setByProduct] = useState(by_product || null);
  const [customerFirstname, setCustomerFirstname] = useState(
    ordersFilters.customerFirstname || null
  );
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(
    ordersFilters.customerPhoneNumber || null
  );
  const [selectedStatus, setSelectedStatus] = useState(
    ordersFilters.orderStatusId ? convertStatusIdToString(ordersFilters.orderStatusId) : null
  );
  const [orderStatusId, setOrderStatusId] = useState(ordersFilters.orderStatusId || null);
  const [paymentType, setPaymentType] = useState(ordersFilters.paymentType || null);
  const [coupon, setCoupon] = useState(by_coupon ? by_coupon : ordersFilters.coupon || null);
  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});

  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  const [getNewResults, setGetNewResults] = useState(0);

  useEffect(() => {
    const fetchOrders = async () => {
      let searchKeyObject;
      if (
        !orderId &&
        !customerId &&
        orderStatusId == null &&
        !customerFirstname &&
        !customerPhoneNumber &&
        !coupon &&
        !byProduct &&
        paymentType == null
      ) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            order_id: orderId,
            customer_id: customerId,
            order_status_id: orderStatusId,
            customer_firstname: customerFirstname,
            customer_phone_number: customerPhoneNumber,
            payment_type: paymentType,
            coupon: coupon,
            by_product: byProduct,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
        setSearchKeyObjectPagination(searchKeyObject);
      }

      const result = await FetchAdminOrders1(searchKeyObject);

      if (!(result instanceof Error)) {
        if (!result.data.error && result.data) {
          const { results, next, previous, count } = result.data;
          return setOrders({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    };

    fetchOrders();
  }, [
    orderId,
    customerId,
    orderStatusId,
    customerFirstname,
    customerPhoneNumber,
    paymentType,
    coupon,
    byProduct,
    getNewResults,
  ]);

  // useEffect(() => {
  //   return () => {
  //     // Cleanup code runs before the component is unmounted
  //     clearOrders();
  //   };
  // }, []);

  // TODO:

  useEffect(() => {
    setOrdersFilter("orderId", orderId);
    setOrdersFilter("customerId", customerId);
    setOrdersFilter("orderStatusId", orderStatusId);
    setOrdersFilter("customerFirstname", customerFirstname);
    setOrdersFilter("customerPhoneNumber", customerPhoneNumber);
    setOrdersFilter("paymentType", paymentType);
    setOrdersFilter("coupon", coupon);
  }, [
    orderId,
    customerId,
    orderStatusId,
    selectedStatus,
    customerFirstname,
    customerPhoneNumber,
    paymentType,
    coupon,
    byProduct,
  ]);

  const handleStatusChange = (val) => {
    setOrderStatusId(val);
    setSelectedStatus(convertStatusIdToString(val));

    closeMenu();
  };

  const closeMenuFun = () => {
    setSelectedStatus(null);
    setOrderStatusId(null);
    closeMenu;
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => handleStatusChange(1)}>New Order</MenuItem>
      <MenuItem onClick={() => handleStatusChange(5)}>Completed</MenuItem>
      <MenuItem onClick={() => handleStatusChange(7)}>Cancelled Order</MenuItem>
      <MenuItem onClick={() => handleStatusChange(11)}>Refunded</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenuFun}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      setGetNewResults(Math.random());
    }
  };

  return (
    <MDBox my={3}>
      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <MDInput
              label="order id"
              variant="outlined"
              onBlur={(e) => {
                setOrderID(e.target.value);
              }}
              onKeyDown={(e) => handleKeyPress(e)}
              defaultValue={orderId}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="customer id"
              variant="outlined"
              onBlur={(e) => {
                setCustomerId(e.target.value);
              }}
              defaultValue={customerId}
              onKeyDown={(e) => handleKeyPress(e)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="customer first name"
              variant="outlined"
              onBlur={(e) => {
                setCustomerFirstname(e.target.value);
              }}
              defaultValue={customerFirstname}
              onKeyDown={(e) => handleKeyPress(e)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {" "}
            <MDInput
              label="customer phone"
              variant="outlined"
              onBlur={(e) => {
                setCustomerPhoneNumber(e.target.value);
              }}
              defaultValue={customerPhoneNumber}
              onKeyDown={(e) => handleKeyPress(e)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="coupon"
              variant="outlined"
              onBlur={(e) => {
                setCoupon(e.target.value);
              }}
              defaultValue={coupon}
              onKeyDown={(e) => handleKeyPress(e)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="order_status_label_id">payment type</InputLabel>
              <Select
                labelId="order_status_label_id"
                id="order_status_select"
                label="payment type"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
                fullWidth
                sx={{
                  minWidth: 120,
                  lineHeight: "2.72rem",
                }}
              >
                <MenuItem value={"cash"}>cash</MenuItem>
                <MenuItem value={"paytabs"}>paytabs</MenuItem>
                <MenuItem value={"zain_cash"}>zain_cash</MenuItem>
                <MenuItem value={null}>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="searching by date"
              variant="outlined"
              // onBlur={(e) => {
              //   setOrderID(e.target.value);
              // }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDBox display="flex">
              <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                {selectedStatus ? selectedStatus : "All"}
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/* <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          spacing={0.8}
        >
          <Stack direction={{ xs: "column", sm: "row" }} spacing={0.8}></Stack>
        </MDBox> */}

      <Card>
        {!orders?.data && (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        )}
        {orders?.data && (
          <DataTable
            table={{
              columns: ordersHeaders,
              rows: orders.data.map((order) => ({
                id: order.order_id,
                customer: order.payment_firstname + " " + order.payment_lastname,
                shipmentName: order.payment_firstname + " " + order.payment_lastname,
                customerNumber: order.customer_number,
                coupon: order.coupon,
                status: order.order_status_id,
                total: order.total ? parseFloat(order.total) : "/",
                paymentMethod: order.payment_method,
                dateAdded: order.date_added ? displayBasicDate(order.date_added) : "/",
                dateModified: order.date_modified ? displayBasicDate(order.date_modified) : "/",
                actions: order.order_id,
              })),
            }}
            nextPageUrl={orders.next}
            previousPageUrl={orders.previous}
            countPagination={orders.count}
            searchKeyObjectPagination={searchKeyObjectPagination}
          />
        )}
      </Card>
    </MDBox>
  );
}
