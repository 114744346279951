import { useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SignInCover from "layouts/authentication/sign-in/cover";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
// Otis Admin PRO React example components
import Sidenav from "elements/Sidenav";
// import Configurator from "examples/Configurator";

// Otis Admin PRO React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Otis Admin PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
// must delete
// import rtlPlugin from "stylis-plugin-rtl";

// import createCache from "@emotion/cache";

// Otis Admin PRO React routes
import routes from "routes";

// Otis Admin PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import shaghlatyBrand from "assets/images/shaghlaty.png";
import brandDark from "assets/images/logo-ct-dark.png";

import CreateSection from "layouts/dashboards/home-section/create-section";
import EditSection from "layouts/dashboards/home-section/edit-section";

import Analytics from "layouts/dashboards/analytics";

// orders

import CategoryEdit from "layouts/catalog/categories/category-edit";
import CategoryDetails from "layouts/catalog/categories/category-details";
import CategoryCreate from "layouts/catalog/categories/category-create";

import ProductEdit from "layouts/catalog/products/product-edit";
import ProductDetails from "layouts/catalog/products/product-details";
import NewProduct from "layouts/catalog/products/new-product";
import ProductImages from "layouts/catalog/products/product-images";
import ProductAttributeGroupCreate from "layouts/catalog/products/attributesGroups/attributes-groups-create";
import ProductAttributeGroupEdit from "layouts/catalog/products/attributesGroups/attributes-groups-edit";

import CouponCreate from "layouts/ecommerce/general-coupons/coupon-create";

import SendNotification from "layouts/dashboards/notification/send-notification";

import { useContext } from "react";

import { UserContext } from "context/user";

import { NotificationsContext } from "context/notifications";

import { useNavigate } from "react-router-dom";
import BrandEdit from "layouts/ecommerce/brands/brand-edit";

import CouponEdit from "layouts/ecommerce/general-coupons/coupon-edit";
import BannerEdit from "layouts/design/banners/banner-edit";
import BannerCreate from "layouts/design/banners/banner-create";

import OrderDetails from "layouts/sales/orders/order-details";
import OrderEdit from "layouts/sales/orders/order-edit";

import PointsCouponEdit from "layouts/ecommerce/points-coupons/coupon-edit";

import UserDetails from "layouts/users/user-details";
import CreateUser from "layouts/users/create-user";

import NoteEdit from "layouts/for-app/notes/note-edit";
import AttributeCreate from "layouts/catalog/products/attributes/attributes-create";
import PointsCouponCreate from "layouts/ecommerce/points-coupons/coupon-create";

import BrandCreate from "layouts/ecommerce/brands/brand-create";

import DeliveryCostUpdate from "layouts/sales/delivery-costs/delivery-cost-update";

import RankCreate from "layouts/users/user-ranking/create-rank";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const navigate = useNavigate();
  // user context access
  const { user, setUser } = useContext(UserContext);

  // Set user from local storge if existed
  useEffect(() => {
    // check local storge if uesr not exist in user context
    if (!user) {
      navigate("/");
    }
  }, [user]);

  // use notifications context to show alert meessages(snackbar component acrosss all the app)
  const { successMSG, errorMSG, clearErrorMSG, clearSuccessMSG } = useContext(NotificationsContext);
  const notifyError = (message) =>
    toast.warning(message, {
      toastId: message,
      onClose: clearErrorMSG,
      style: {
        color: "black",
        width: 300,
        height: 20,
        fontSize: 14,
      },
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      toastId: message,
      onClose: clearSuccessMSG,
      style: {
        width: 300,
        height: 20,
        fontSize: 14,
      },
    });

  useEffect(() => {
    if (errorMSG) {
      notifyError(errorMSG);
    }
    if (successMSG) {
      notifySuccess(successMSG);
    }
  }, [errorMSG, successMSG]);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={true}
        closeOnClick
        theme="colored"
      />

      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brand={shaghlatyBrand}
              routes={routes}
              // onMouseEnter={handleOnMouseEnter}
              // onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {/* {layout === "vr" && <Configurator />} */}
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<SignInCover />}></Route>
          <Route path="*" element={<Navigate to="/home" />} />

          <Route path="/home" element={<Analytics />} />

          <Route path="/dashboards/home-sections/create" element={<CreateSection />} />
          <Route path="/dashboards/home-sections/edit/:id" element={<EditSection />} />

          <Route path="/ecommerce/brands/edit/:id" element={<BrandEdit />} />

          <Route path="/catalog/categories/details/:id" element={<CategoryDetails />} />
          <Route path="/catalog/categories/edit/:id" element={<CategoryEdit />} />
          <Route path="/catalog/categories/create" element={<CategoryCreate />} />

          <Route path="/catalog/products/details/:id" element={<ProductDetails />} />
          <Route path="/catalog/products/edit/:id" element={<ProductEdit />} />
          <Route path="/catalog/products/create" element={<NewProduct />} />
          <Route
            path="/catalog/products/attributes-group/create"
            element={<ProductAttributeGroupCreate />}
          />
          <Route
            path="/catalog/products/attributes-group/edit/:id"
            element={<ProductAttributeGroupEdit />}
          />
          <Route path="/catalog/products/attributes/create" element={<AttributeCreate />} />
          <Route path="/catalog/products/images/:id" element={<ProductImages />} />

          <Route path="/design/banners/edit/:id" element={<BannerEdit />} />
          <Route path="/design/banners/create" element={<BannerCreate />} />

          <Route path="/sales/orders/details/:id" element={<OrderDetails />} />
          <Route path="/sales/orders/edit/:id" element={<OrderEdit />} />

          <Route path="/ecommerce/points-coupons/edit/:id" element={<PointsCouponEdit />} />

          <Route path="/ecommerce/brands/create" element={<BrandCreate />} />
          <Route path="/ecommerce/coupons/create" element={<CouponCreate />} />
          <Route path="/ecommerce/points-coupons/create" element={<PointsCouponCreate />} />

          <Route path="/sales/delivery-cost/update" element={<DeliveryCostUpdate />} />

          <Route path="/ecommerce/coupons/edit/:id" element={<CouponEdit />} />

          <Route path="/users/details/:id" element={<UserDetails />} />
          <Route path="/users/create" element={<CreateUser />} />

          <Route path="/notes/edit/:id" element={<NoteEdit />} />

          <Route path="/dashboards/notifications/send" element={<SendNotification />} />

          <Route path="ranks/create" element={<RankCreate />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
