import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

export const deliveryCostsHeader = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Zone",
    accessor: "zone",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Cost",
    accessor: "cost",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Special Cost",
    accessor: "special_cost",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Special Cost Total Order",
    accessor: "special_cost_total_order",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "End Date",
    accessor: "end_date",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
];
