import React, { createContext, useReducer } from "react";

const SET_NOTES_ACTIONS = {
  SET_NOTES: "SET_NOTES",
  CLEAR_NOTES: "CLEAR_NOTES",
};

const initialState = {
  notes: null,
};

const notesReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTES_ACTIONS.SET_NOTES:
      return { ...state, notes: payload };
    case SET_NOTES_ACTIONS.CLEAR_NOTES:
      return initialState;
    default:
      return state;
  }
};

const NotesContext = createContext();

const NotesContextProvider = ({ children }) => {
  const [{ notes }, dispatch] = useReducer(notesReducer, initialState);

  const setNotes = (notes) => {
    dispatch({ type: SET_NOTES_ACTIONS.SET_NOTES, payload: notes });
  };

  const clearNotes = () => {
    dispatch({ type: SET_NOTES_ACTIONS.CLEAR_NOTES });
  };

  const value = { notes, setNotes, clearNotes };
  return <NotesContext.Provider value={value}>{children}</NotesContext.Provider>;
};

export { NotesContext, NotesContextProvider };
