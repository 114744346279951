import React, { useEffect, useState, useContext } from "react";
import { GetHistoryOfCoupon } from "utils/apis/history";
import { NotificationsContext } from "context/notifications";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ChangeBox from "elements/ChangeBox";

export default function CouponHistory({ couponId }) {
  const [couponHistoryObj, setCouponHistoryObj] = useState();
  const { setErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchProductHistory = async () => {
      const result = await GetHistoryOfCoupon(couponId);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const justUpdate = result.data?.results.filter((change) => change.action == 1);
          return setCouponHistoryObj(justUpdate);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };
    fetchProductHistory();
  }, [couponId]);

  console.log(couponHistoryObj);

  return (
    <div>
      <h2>Changes Preview</h2>
      <MDBox>
        <ChangeBox historyObj={couponHistoryObj} />
      </MDBox>
    </div>
  );
}
