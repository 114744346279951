import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MDButton from "components/MDButton";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDTypography from "components/MDTypography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";

import CustomerMembershipComponent from "layouts/users/customer-membership/CustomerMembershipComponent";
import { GetRewardPointsHistory } from "utils/apis/history";
import { EditCustomersMembershipCurrentRewardPoints } from "utils/apis/users/users";

import { Card } from "@mui/material";

import { useContext } from "react";

import { displayBasicDate } from "utils/methods";

import StarIcon from "@mui/icons-material/Star";
import MDBox from "components/MDBox";
import { UsersMContext } from "context/customersMembership";
import UserRank from "./UserRank";

import { NotificationsContext } from "context/notifications";

export default function RewardPoints({ parentState, upadteParentState, setReRenderUserData }) {
  const { activeCustomerMembershipId } = useContext(UsersMContext);
  const [pointsHistoryObj, setPointsHistoryObj] = useState(null);
  const [newPointsValue, setNewPointsValue] = useState(null);
  const [reRender, setReRender] = useState(false);

  const { setErrorMSG, setSuccessMSG } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      const result = await GetRewardPointsHistory(activeCustomerMembershipId);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const justUpdate = result.data?.results.filter((change) => change.action == 1);
          const justWithCurrentRewardPointsChanges = justUpdate.filter(
            (change) => "current_reward_points" in JSON.parse(change.changes)
          );
          return setPointsHistoryObj(justWithCurrentRewardPointsChanges);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };
    if (activeCustomerMembershipId) fetchOrderHistory();
  }, [activeCustomerMembershipId, reRender]);

  const handleChangePointsClick = async () => {
    const finalPoints = Math.ceil(Number(parentState?.user_points) + Number(newPointsValue));

    console.log(finalPoints);

    const result = await EditCustomersMembershipCurrentRewardPoints(
      activeCustomerMembershipId,
      parentState?.id,
      finalPoints
    );

    if (!(result instanceof Error)) {
      // back end validation errors
      if (!result.data.error) {
        setReRenderUserData((prev) => !prev);
        setSuccessMSG("points updated successfuly");

        // navigate("/ecommerce/orders");
      } else {
        setErrorMSG(result.data.error);
      }
    } else {
      console.log(result);
      return setErrorMSG(result);
    }

    setReRender((prev) => !prev);
    setNewPointsValue(null);
  };

  return (
    <MDBox>
      <Stack spacing={3}>
        <CustomerMembershipComponent customer_id={parentState?.id} />
        {/* <Stack direction="row" justifyContent="flex-start" rowSpacing={2} alignItems="center">
        <Card sx={{ p: 3 }}></Card>
      </Stack> */}
        <MDTypography>Reward Points from orders</MDTypography>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ paddingInlineEnd: 2 }}>
            <TableContainer component={Paper} sx={{ boxShadow: 1, py: 2 }}>
              <Table
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                aria-label="user details table"
              >
                <TableRow>
                  <TableCell align="left">Date Added</TableCell>
                  <TableCell align="left">Order</TableCell>
                  <TableCell align="left">Points</TableCell>
                </TableRow>

                <TableBody>
                  {pointsHistoryObj &&
                    pointsHistoryObj.map((single_log) => (
                      <TableRow>
                        <TableCell align="left">
                          {displayBasicDate(single_log?.timestamp)}
                        </TableCell>
                        <TableCell align="left">----</TableCell>
                        <TableCell align="left">
                          {JSON.parse(single_log?.changes).current_reward_points?.[0]} to :{" "}
                          {JSON.parse(single_log?.changes).current_reward_points?.[1]}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <UserRank state={parentState} />
          </Grid>
        </Grid>

        {/* <MDTypography>Reward Points from admins</MDTypography>
        <TableContainer component={Paper} sx={{ boxShadow: 1, py: 2 }}>
          <Table
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            aria-label="user details table"
          >
            <TableRow>
              <TableCell align="left">Date Added</TableCell>
              <TableCell align="left">Descripttion</TableCell>
              <TableCell align="left">Points</TableCell>
            </TableRow>

            <TableBody>
              <TableRow>
                <TableCell align="left">2024/2/2</TableCell>
                <TableCell align="left"> 72</TableCell>
                <TableCell align="left">1200</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer> */}

        <Divider />

        <MDTypography>Add Rewards Points</MDTypography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item sx={12} md={6} lg={4}>
            <MDInput
              value={newPointsValue}
              name="points"
              label="points"
              fullWidth
              onChange={(e) => {
                setNewPointsValue(e.target.value);
              }}
            />
          </Grid>
          <Grid item sx={12} md={6} lg={4}>
            <MDInput
              value=""
              name="description"
              label="description"
              fullWidth
              // onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end">
          <MDButton variant="gradient" color="info" onClick={handleChangePointsClick}>
            Add Points
          </MDButton>
        </Stack>
      </Stack>
    </MDBox>
  );
}
