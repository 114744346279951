import axiosInstance from "utils/axiosInstance";
import qs from "qs";

const ordersRoute = "orders_admin/";

export const FetchAdminOrders = async (user, page = ordersRoute) => {
  try {
    const response = await axiosInstance.get(page, {
      headers: {
        Authorization: `Token ${user.token}`,
      },
    });

    return response;
    // ...
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const FetchAdminOrders1 = async (searchKeyObject = {}, page = ordersRoute) => {
  try {
    const response = await axiosInstance.get(page, {
      params: { ...searchKeyObject },
      paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    });

    return response;
    // ...
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const FetchAdminOrderDetails = async (user, orderId) => {
  const formData = new FormData();
  formData.append("id", orderId);
  try {
    const response = await axiosInstance.post(`get_order_details/`, formData, {
      headers: {
        Authorization: `Token ${user.token}`,
      },
    });

    return response;
    // ...
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const DeleteOrder = async (orderId) => {
  try {
    const response = await axiosInstance.delete(`${ordersRoute}${orderId}/`);
    return response;
  } catch (error) {
    return error;
  }
};

export const EditAdminOrder = async (id, newOrder) => {
  // const formData = new FormData();
  // formData.append("name", newBrand.name);
  // formData.append("image", newBrand.image);
  console.log(newOrder);
  try {
    const response = await axiosInstance.put(`${ordersRoute}${id}/`, newOrder, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("response", response);

    return response;
    // ...
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const EditAdminOrderStatus = async (id, new_order_status_id) => {
  try {
    const response = await axiosInstance.patch(`${ordersRoute}${id}/`, {
      order_status_id: new_order_status_id,
    });

    console.log("response", response);

    return response;
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const EditAdminOrderStatusAndComment = async (
  id,
  new_order_status_id,
  new_comment,
  customer_notified_arr = ""
) => {
  // get previos array
  const arrayOfUserNotifiedOrNot =
    customer_notified_arr && customer_notified_arr != "" ? JSON.parse(customer_notified_arr) : "";

  if (arrayOfUserNotifiedOrNot?.length) {
    arrayOfUserNotifiedOrNot.unshift(0);
  }
  try {
    const response = await axiosInstance.patch(`${ordersRoute}${id}/`, {
      order_status_id: new_order_status_id,
      comment: new_comment || null,
      customer_notified_id: JSON.stringify(arrayOfUserNotifiedOrNot),
    });

    console.log("response", response);

    return response;
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const EditCustomerNotifiedIdStringArray = async (id, new_customer_notified_arr) => {
  try {
    const response = await axiosInstance.patch(`${ordersRoute}${id}/`, {
      customer_notified_id: JSON.stringify(new_customer_notified_arr),
    });

    console.log("response", response);

    return response;
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const EditAdminOrderComment = async (id, new_comment) => {
  try {
    const response = await axiosInstance.patch(`${ordersRoute}${id}/`, {
      comment: new_comment,
    });

    console.log("response", response);

    return response;
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const EditAdminOrderProducts = async (id, newProductsArray) => {
  try {
    const response = await axiosInstance.patch(`${ordersRoute}${id}/`, {
      order_products: newProductsArray,
    });

    console.log("response", response);

    return response;
  } catch (error) {
    // Handle the error
    return error;
  }
};

export const FetchAdminOrderById = async (orderId) => {
  try {
    const response = await axiosInstance.get(`${ordersRoute}${orderId}`);

    return response;
    // ...
  } catch (error) {
    // Handle the error
    return error;
  }
};
