import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";

export default function ProductAttribute({ product_attributes }) {
  return (
    <>
      {product_attributes?.length && (
        <>
          <MDBox mt={2} mb={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Product Attributes
            </MDTypography>
          </MDBox>
          <Grid container rowSpacing={2}>
            {product_attributes.map((product_attribute) => (
              <Grid item sm={12} lg={6}>
                <MDTypography variant="h6" fontWeight="medium" color="info">
                  {product_attribute.attributes_group}
                </MDTypography>
                {product_attribute?.attributes &&
                  product_attribute?.attributes?.length &&
                  product_attribute?.attributes.map((attribute) => (
                    <MDTypography ml={0.5}>
                      {attribute.key} : {attribute.value}
                    </MDTypography>
                  ))}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
