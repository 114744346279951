import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { Autocomplete, TextField } from "@mui/material";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// get parents categories

import { FetchAdminCategories, PostAdminCategory } from "utils/apis/catalog/categories";
import InputFileAndPreview from "elements/InputFileAndPreview";
import { CategoriesContext } from "context/categories";

function CategoryCreate() {
  // access user context
  const { user } = useContext(UserContext);

  const { clearCategories } = useContext(CategoriesContext);

  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // parent categories state

  const [parentsCategories, setParentsCategories] = useState(null);
  const [categorySearchKey, setCategorySearchKey] = useState(null);

  // use navigate hook

  const navigate = useNavigate();

  const defaultFormFields = {
    nameAr: "",
    nameEn: "",
    parent_id: 0,
    color: "#000000",
    transparency: null,
    image: null,
    sort_order: "",
    status: 1,
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { nameAr, nameEn, color, image, parent_id, transparency, sort_order, status } = formFields;

  const [transperancyError, setTransparencyError] = useState("");

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // handle select change
  const handleParentCategoryChange = (_, value) => {
    if (value) {
      setFormFields({
        ...formFields,
        parent_id: value.parent_id,
      });
    } else {
      setFormFields({
        ...formFields,
        parent_id: 0,
      });
    }
  };

  const handleCategorySearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 3) {
      setCategorySearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setCategorySearchKey(null);
    }
  };

  // set image state
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFormFields({
      ...formFields,
      image: file,
    });
  };

  // handle transperncy change

  const handleTransparencyChange = (event) => {
    const transparencyValue = parseInt(event.target.value);

    if (transparencyValue) {
      // not acceptable value
      if (transparencyValue > 100 || transparencyValue < 0) {
        setFormFields({
          ...formFields,
          transparency: null, // back to default value
        });

        // show error msg
        return setTransparencyError("value should be between 0-100");
      } else {
        // ** accepted value **

        // reset the error msg
        setTransparencyError("");

        // change the state

        return setFormFields({
          ...formFields,
          transparency: transparencyValue,
        });
      }
    } else {
      // keep the default value
      setFormFields({
        ...formFields,
        transparency: null, // back to defualt value
      });
    }
  };

  // handle create category submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate name ar, name en, color, image
    // parent id default 0 if not selected (means this is a parent category)
    if (!nameAr || !nameEn || !color || !transparency) {
      return setErrorMSG("Please fill all the fields");
    }

    const categoryBody = {
      image,
      parent_id,
      category_transparency: transparency,
      category_color: color,
      description: [
        {
          name: nameEn,
          description: "",
          language_id: 1,
        },
        {
          name: nameAr,
          description: "",
          language_id: 2,
        },
      ],
      sort_order,
      status,
    };

    const result = await PostAdminCategory(categoryBody);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Category created successfuly");

        clearCategories();
        // navigate to brands list page
        return navigate("/catalog/categories");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  const handleEnableOrDisableChange = (event) => {
    if (event.target.value == "enable") {
      setFormFields((prev) => {
        return { ...prev, status: 1 };
      });
    } else if (event.target.value == "disable") {
      setFormFields((prev) => {
        return { ...prev, status: 0 };
      });
    }
  };

  // get parents categories an case if user want to create a child one to assign it
  useEffect(() => {
    const getParentsCategories = async () => {
      let searchKeyObject;
      if (!categorySearchKey) {
        searchKeyObject = {};
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            name: categorySearchKey,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
      }

      const result = await FetchAdminCategories(searchKeyObject);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const { results } = result.data;
          return setParentsCategories(results);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    getParentsCategories();
  }, [categorySearchKey]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center" mb={3}>
              <MDTypography variant="h3" fontWeight="bold">
                Create New Category
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New Category
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDInput
                      name="nameEn"
                      fullWidth
                      label="Category Name (English)"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="nameAr"
                      fullWidth
                      label="Category Name (Arabic)"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>

                  <MDBox>
                    <Autocomplete
                      sx={{ mb: 2 }}
                      onChange={handleParentCategoryChange}
                      options={
                        parentsCategories
                          ? parentsCategories.map((parentCategory) => ({
                              label: parentCategory.description[0]?.name || "no name",
                              parent_id: parentCategory?.category_id || "no id",
                            }))
                          : []
                      }
                      // isOptionEqualToValue={(option) => option.parent_id === option.parent_id}
                      onInputChange={handleCategorySearch}
                      renderInput={(params) => (
                        <TextField {...params} label="Choose Parent Category" />
                      )}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="color"
                      fullWidth
                      label="Color code #HEXA"
                      type="color"
                      onChange={handleChange}
                      value={color}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="transparency"
                      fullWidth
                      error={transperancyError}
                      label="Transperancy (%)"
                      onChange={handleTransparencyChange}
                      type="number"
                      helperText={transperancyError}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="sort_order"
                      fullWidth
                      label="sort_order"
                      onChange={handleChange}
                      type="number"
                      value={sort_order}
                    />
                  </MDBox>
                  <MDBox mb={2} px={1}>
                    <FormControl>
                      <MDTypography>please be careful with that</MDTypography>
                      <RadioGroup
                        row
                        aria-labelledby="enable-or-disable-category"
                        name="enable-or-disable-category-group"
                        onChange={handleEnableOrDisableChange}
                      >
                        <FormControlLabel
                          value="enable"
                          control={<Radio checked={status} />}
                          label="Enable"
                        />
                        <FormControlLabel
                          value="disable"
                          control={<Radio checked={!status} />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput type="file" onChange={handleImageUpload} />
                </MDBox> */}
                <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                  <InputFileAndPreview handleImageUpload={handleImageUpload} file={image} />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CategoryCreate;
