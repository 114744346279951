import ProductImageCell from "../components/ProductImageCell";
import ActionsCell from "../components/ActionsCell/index";
import IdCell from "../components/IdCell";
import DefaultCell from "components/TableCells/DefaultCell/";

export const productsHeaders = [
  {
    Header: "id",
    accessor: "id",
    Cell: ({ value }) => (
      <IdCell
        id={value?.id.toString()}
        arrayOfSelectedProducts={value?.arrayOfSelectedProducts}
        setArrayOfSelectedProducts={value?.setArrayOfSelectedProducts}
      />
    ),
  },
  ,
  {
    Header: "Product",
    accessor: "product",
    Cell: ({ value }) => <ProductImageCell image={value.toString()} />,
  },
  {
    Header: "Model",
    accessor: "model",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Quantity(Available)",
    accessor: "quantity_avilable",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value == 0) {
        status = <DefaultCell value={"NEW"} />;
      } else if (value == 1) {
        status = <DefaultCell value={"PROMOTED"} />;
      } else if (value == 2) {
        status = <DefaultCell value={"FEATURED"} />;
      } else if (value == 3) {
        status = <DefaultCell value={"DISCOUNT"} />;
      }

      return status;
    },
  },
  {
    Header: "Date Added",
    accessor: "date_added",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Date Modified",
    accessor: "date_modified",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => (
      <ActionsCell
        id={value?.id}
        product={value?.product}
        setRerenderProducts={value?.setRerenderProducts}
      />
    ),
  },
];
