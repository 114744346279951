import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";

import { Autocomplete, TextField } from "@mui/material";

import { EditHomeSection, FetchHomeSectionById } from "utils/apis/home_section/home";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";

import { NotificationsContext } from "context/notifications";

import { useState, useEffect, useContext, useMemo } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import InputFileAndPreview from "elements/InputFileAndPreview";

import CategoryAutocomplete from "elements/CategoryAutocomplete";
import BrandAutocomplete from "elements/BrandAutocomplete";
import ProductStatusAutoComplete from "elements/ProductStatusAutoComplete";

import SectionTypeComponent from "../SectionTypeComponent";
import { convertProductStatusStringToId } from "utils/methods";

const defaultFormFields = {
  titleEn: "",
  titleAr: "",
  sub_titleEn: "",
  sub_titleAr: "",
  filter_id: "",
  order_id: "",
  section_products_limit: "",
  sectionType: 1,
  section_background: null,
};
function EditSection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);
  const [selectedFilterIdObj, setSelectedFilterIdObj] = useState(null);
  const [loadingOrNot, setLoadingOrNot] = useState(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const {
    titleEn,
    titleAr,
    sub_titleEn,
    sub_titleAr,
    filter_id,
    order_id,
    section_products_limit,
    sectionType,
    section_background,
  } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFormFields({
      ...formFields,
      section_background: file,
    });
  };

  // handle create section submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate name ar, name en, color, image
    // parent id default 0 if not selected (means this is a parent category)
    if (!titleEn || !sectionType) {
      return setErrorMSG("Please fill all the fields");
    }

    let sectionProductLimit;
    let selectedFilterId;
    if (sectionType == 1 || sectionType == 4 || sectionType == 5) {
      selectedFilterId = selectedFilterIdObj?.category_id;
      if (sectionType == 1) sectionProductLimit = 6;
      if (sectionType == 4) sectionProductLimit = 4;
      if (sectionType == 5) sectionProductLimit = 8;
    } else if (sectionType == 2) {
      selectedFilterId = convertProductStatusStringToId(selectedFilterIdObj);
      sectionProductLimit = 6;
    } else if (sectionType == 3) {
      selectedFilterId = selectedFilterIdObj?.manufacturer_id;
      sectionProductLimit = 6;
    }

    if (selectedFilterId == null && selectedFilterId == undefined) {
      return setErrorMSG("Please fill filter id");
    }

    const sectionBody = {
      section_background,
      type: sectionType,
      section_products_limit,
      order_id,
      filter_id: selectedFilterId,
      title: [
        {
          title: titleEn,
          sub_title: sub_titleEn,
          language_id: 1,
        },
        {
          title: titleAr,
          sub_title: sub_titleAr,
          language_id: 2,
        },
      ],
    };

    const result = await EditHomeSection(id, sectionBody);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("section edited successfuly");
        // navigate to brands list page
        return navigate("/dashboards/home-section");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  // Fetch the home sections
  useEffect(() => {
    if (id) {
      const getsectionById = async () => {
        setLoadingOrNot(true);
        const result = await FetchHomeSectionById(id);

        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            // set state of category
            const {
              section_title,
              filter_id,
              order_id,
              section_products_limit,
              type,
              section_background,
            } = result.data;
            setFormFields({
              titleEn: section_title?.[1].title,
              titleAr: section_title?.[0].title,
              sub_titleEn: section_title?.[1].sub_title,
              sub_titleAr: section_title?.[0].sub_title,
              filter_id,
              order_id,
              section_products_limit,
              sectionType: type,
              section_background: section_background,
            });
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };

      getsectionById();
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={8}>
              <MDBox textAlign="center" mb={3}>
                <MDTypography variant="h3" fontWeight="bold">
                  Edit Section
                </MDTypography>
              </MDBox>
              <Card>
                <MDBox p={3}>
                  <MDBox mb={2}>
                    <MDTypography variant="h6" fontWeight="bold">
                      edit section # {id}
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDBox mb={2}>
                      <MDInput
                        name="titleEn"
                        value={titleEn}
                        fullWidth
                        label="section title in english"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>
                    {/* <MDBox mb={2}>
                      <MDInput
                        name="titleAr"
                        value={titleAr}
                        fullWidth
                        label="section title in arabic"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox> */}
                    <MDBox mb={2}>
                      <MDInput
                        name="sub_titleEn"
                        value={sub_titleEn}
                        fullWidth
                        label="section sub title in english"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>
                    {/* <MDBox mb={2}>
                      <MDInput
                        name="sub_titleAr"
                        value={sub_titleAr}
                        fullWidth
                        label="section sub title in arabic"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox> */}
                    <MDBox mb={2}>
                      <MDInput
                        name="order_id"
                        value={order_id}
                        fullWidth
                        label="order id"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>
                    {/* <MDBox mb={2}>
                      <MDInput
                        name="section_products_limit"
                        value={section_products_limit}
                        fullWidth
                        label="products limit"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox> */}
                    <MDBox>
                      {/* <Autocomplete
                        sx={{ mb: 2 }}
                        value={sectionTypeOptionsValue}
                        onChange={handleSectionTypeChange}
                        options={sectionTypeOptions}
                        //   isOptionEqualToValue={(option) => option.parent_id === option.parent_id}
                        renderInput={(params) => (
                          <TextField {...params} label="Choose section type" />
                        )}
                      /> */}
                      <SectionTypeComponent formFields={formFields} setFormFields={setFormFields} />
                    </MDBox>
                    {/* <MDBox mb={2}>
                      <MDInput
                        name="filter_id"
                        value={filter_id}
                        fullWidth
                        label="filter id"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox> */}
                    <MDBox mb={2}>
                      {sectionType == 1 && (
                        <CategoryAutocomplete
                          selectedCategory={selectedFilterIdObj}
                          setSelectedCategory={setSelectedFilterIdObj}
                          makeSelectByThisCategoryId={sectionType == 1 ? filter_id : null}
                        />
                      )}
                      {sectionType == 4 && (
                        <CategoryAutocomplete
                          selectedCategory={selectedFilterIdObj}
                          setSelectedCategory={setSelectedFilterIdObj}
                          makeSelectByThisCategoryId={sectionType == 4 ? filter_id : null}
                        />
                      )}
                      {sectionType == 5 && (
                        <CategoryAutocomplete
                          selectedCategory={selectedFilterIdObj}
                          setSelectedCategory={setSelectedFilterIdObj}
                          makeSelectByThisCategoryId={sectionType == 5 ? filter_id : null}
                        />
                      )}
                      {sectionType == 2 && (
                        <ProductStatusAutoComplete
                          productState={selectedFilterIdObj}
                          setProductState={setSelectedFilterIdObj}
                          makeSelectByThisStatusId={sectionType == 2 ? filter_id : null}
                        />
                      )}
                      {sectionType == 3 && (
                        <BrandAutocomplete
                          selectedBrand={selectedFilterIdObj}
                          setSelectedBrand={setSelectedFilterIdObj}
                          makeSelectByThisBrandId={sectionType == 3 ? filter_id : null}
                        />
                      )}
                    </MDBox>
                    {/* <MDBox mb={2}>
                      <MDInput type="file" onChange={handleImageUpload} />
                    </MDBox> */}
                    <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                      <InputFileAndPreview
                        handleImageUpload={handleImageUpload}
                        file={formFields?.section_background}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                      edit
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditSection;
