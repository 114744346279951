import React, { createContext, useReducer } from "react";

const SET_PRODUCTS_ATTRIBUTES_ACTIONS = {
  SET_PRODUCTS_ATTRIBUTES: "SET_PRODUCTS_ATTRIBUTES",
  CLEAR_PRODUCTS_ATTRIBUTES: "CLEAR_PRODUCTS_ATTRIBUTES",
};

// Initial state of the products attributes  context
const initialState = {
  productsAttributes: null,
};

// Products attributes  context reducer function
const productsAttributesReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PRODUCTS_ATTRIBUTES_ACTIONS.SET_PRODUCTS_ATTRIBUTES:
      return { ...state, productsAttributes: payload };
    case SET_PRODUCTS_ATTRIBUTES_ACTIONS.CLEAR_PRODUCTS_ATTRIBUTES:
      return initialState;
    default:
      return state;
  }
};

// Create the products attrs  context
const ProductsAttributesContext = createContext();

// products attrs  context provider component
const ProductsAttributesContextProvider = ({ children }) => {
  const [{ productsAttributes }, dispatch] = useReducer(productsAttributesReducer, initialState);

  // Action to set the products attrs  context
  const setProductsAttributes = (productsAttributes) => {
    dispatch({
      type: SET_PRODUCTS_ATTRIBUTES_ACTIONS.SET_PRODUCTS_ATTRIBUTES,
      payload: productsAttributes,
    });
  };

  // Action to clear the products attrs  context

  const clearProductsAttributes = () => {
    dispatch({ type: SET_PRODUCTS_ATTRIBUTES_ACTIONS.CLEAR_PRODUCTS_ATTRIBUTES });
  };

  const value = {
    productsAttributes,
    setProductsAttributes,
    clearProductsAttributes,
  };
  return (
    <ProductsAttributesContext.Provider value={value}>
      {children}
    </ProductsAttributesContext.Provider>
  );
};

export { ProductsAttributesContext, ProductsAttributesContextProvider };
