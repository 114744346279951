import MDBox from "components/MDBox";

import { DeleteAdminPointsCoupon } from "utils/apis/ecommerce/pointsCoupons";
import { useContext } from "react";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";

import { PointsCouponsContext } from "context/pointsCoupons";
import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

const ActionsCell = ({ id }) => {
  // ** id is the points coupon id **

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  // Accsss points coupon context to change the state after delete
  const { pointsCoupons, setPointsCoupons } = useContext(PointsCouponsContext);

  // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  // Delete Points Coupons Action
  const handleDeletePointsCoupon = async () => {
    const result = await DeleteAdminPointsCoupon(user, id);

    if (!(result instanceof Error)) {
      if (result.status === 204) {
        // delete it from ui
        const { count, next, previous, data } = pointsCoupons;
        const newPointsCoupons = data?.filter((pointCoupon) => pointCoupon.id != id);

        setPointsCoupons({
          count,
          next,
          previous,
          data: newPointsCoupons,
        });

        // show deleted alert
        return setSuccessMSG("Coupon Deleted Successfuly");
      }

      if (result.data.error) {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <MDBox>
      <Icon
        color="error"
        fontSize="medium"
        onClick={handleDeletePointsCoupon}
        sx={{ mx: 2, cursor: "pointer" }}
      >
        delete
      </Icon>
      <Link to={`/ecommerce/points-coupons/edit/${id}`}>
        <Icon color="info" fontSize="medium">
          edit
        </Icon>
      </Link>
    </MDBox>
  );
};

export default ActionsCell;
