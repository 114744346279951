import React from "react";
import DataTable from "./DataTable";
import { generalCouponsHeaders } from "layouts/ecommerce/general-coupons/coupons-list/data/dataTableData";
import { displayBasicDate } from "utils/methods";
export default function CouponsList({ coupons }) {
  return (
    <div>
      {coupons?.length > 0 && (
        <DataTable
          table={{
            columns: generalCouponsHeaders,
            rows: coupons.map((coupon) => ({
              id: coupon.coupon_id,
              name: coupon.name,
              code: coupon.code,
              type: coupon.type,
              discount: coupon.discount,
              total_max: coupon.total_max,
              status: coupon.status,
              date_added: coupon.date_added ? displayBasicDate(coupon.date_added) : "/",
              date_start: coupon.date_start ? displayBasicDate(coupon.date_start) : "/",
              date_end: coupon.date_end ? displayBasicDate(coupon.date_end) : "/",

              actions: coupon.coupon_id,
            })),
          }}
          nextPageUrl={null}
          previousPageUrl={null}
          countPagination={coupons?.length}
          searchKeyObjectPagination={null}
        />
      )}
    </div>
  );
}
