import React, { useEffect, useState, useContext } from "react";
import { GetHistoryOfProduct } from "utils/apis/history";
import { NotificationsContext } from "context/notifications";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ChangeBox from "elements/ChangeBox";

export default function ProductHistory({ productId }) {
  const [productHistoryObj, setProductHistoryObj] = useState();
  const { setErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchProductHistory = async () => {
      const result = await GetHistoryOfProduct(productId);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const justUpdate = result.data?.results.filter((change) => change.action == 1);
          return setProductHistoryObj(justUpdate);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };
    fetchProductHistory();
  }, [productId]);

  console.log(productHistoryObj);

  return (
    <div>
      <h2>Changes Preview</h2>
      <MDBox>
        <ChangeBox historyObj={productHistoryObj} />
        {/* {productHistoryObj && (
          <>
            {productHistoryObj.map((single_change) => (
              <Card sx={{ p: 5 }}>
                <h3>In date : {single_change.timestamp}</h3>
                <List>
                  {Object.entries(JSON.parse(single_change?.changes)).map(
                    ([key, [oldValue, newValue]]) => (
                      <ListItemButton key={key}>
                        <ListItemIcon>
                          <PublishedWithChangesIcon />
                        </ListItemIcon>
                        <strong>{key}: </strong> {oldValue} {"-->"} {newValue}
                      </ListItemButton>
                    )
                  )}
                </List>
              </Card>
            ))}
          </>
        )} */}
      </MDBox>
    </div>
  );
}
