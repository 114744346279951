// @mui material components

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { FetchAdminOrderDetails } from "utils/apis/sales/orders";

import { useState, useContext } from "react";

import { UserContext } from "context/user";

import { NotificationsContext } from "context/notifications";

import OrderDetailsCard from "./components/OrderDetailsCard";

import { OrdersContext } from "context/orders";

import { FetchAdminOrderById } from "utils/apis/sales/orders";

import "app.css";

function OrderDetails() {
  const { orders, setOrders } = useContext(OrdersContext);

  const params = useParams();

  const { id } = params;

  const { user } = useContext(UserContext);
  const { setErrorMSG } = useContext(NotificationsContext);

  const [orderProducts, setOrderProducts] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderProductUpdated, setOrderProductUpdated] = useState(false);
  const [reRenderOrderDetails, setReRenderOrderDetails] = useState(false);

  //Fetch the order details

  useEffect(() => {
    // console.log(orderTabelDetails);

    const fetchOrderProductsDetails = async () => {
      const result = await FetchAdminOrderDetails(user, id);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          return setOrderProducts(result.data);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    fetchOrderProductsDetails();
  }, [orderProductUpdated]);

  useEffect(() => {
    // console.log(orderTabelDetails);

    const fetchOrderDetails = async () => {
      const result = await FetchAdminOrderById(id);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          return setOrderDetails(result.data);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    fetchOrderDetails();
  }, [reRenderOrderDetails]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3} className="before-footer">
        <OrderDetailsCard
          orderProducts={orderProducts}
          orderDetails={orderDetails}
          setOrderProductUpdated={setOrderProductUpdated}
          setReRenderOrderDetails={setReRenderOrderDetails}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetails;
