// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import OrdersListComponent from "./OrdersListComponent";

function OrdersList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <OrdersListComponent />
      <Footer />
    </DashboardLayout>
  );
}

export default OrdersList;
