import { useMemo } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Dropzone from "components/MUIDropzone";
function Media(props) {
  const { localState, setParentState } = props;

  // initial files from the state
  const initFiles = localState.images ? localState.images : [];

  const handleFilesChange = (files) => {
    // Handle the files here
    setParentState((previousState) => {
      return {
        ...previousState,
        step2: {
          images: files,
        },
      };
    });
  };
  return (
    <MDBox>
      <MDTypography variant="h5">Media</MDTypography>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Product Image
          </MDTypography>
        </MDBox>
        <MDBox>
          {useMemo(
            () => (
              <Dropzone onFilesChange={handleFilesChange} initFiles={initFiles} />
            ),
            []
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Media;
