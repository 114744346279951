import MDBox from "components/MDBox";

import { DeleteAdminBanner } from "utils/apis/design/banners";
import { useContext, useState } from "react";

import YesOrNoDialog from "elements/YesOrNoDialog";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Stack from "@mui/material/Stack";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";
import { BannersContext } from "context/banners";
import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

const ActionsCell = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  // Accsss banners to change the state after delete
  const { banners, setBanners } = useContext(BannersContext);

  // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  // Delete Banner Action
  const handleDeleteBanner = async () => {
    const result = await DeleteAdminBanner(id);

    // this is a valid response
    if (!(result instanceof Error)) {
      if (result.status === 204) {
        // banner deleted successfuly

        // remove it from the UI
        const { count, next, previous, data } = banners;
        const newBanners = data.filter((banner) => banner.banner_image_id != id);

        setBanners({
          count,
          next,
          previous,
          data: newBanners,
        });

        // show deleted alert
        setSuccessMSG("Banner Deleted Successfuly");
      } else {
        // this is backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // this is server error or other erro that could happen
      return setErrorMSG(result.message);
    }
  };

  return (
    // <MDBox>
    //   <Icon
    //     color="error"
    //     fontSize="medium"
    //     onClick={handleDeleteBanner}
    //     sx={{ mx: 2, cursor: "pointer" }}
    //   >
    //     delete
    //   </Icon>
    //   <Link to={`/ecommerce/banners/edit/${id}`}>
    //     <Icon color="info" fontSize="medium">
    //       edit
    //     </Icon>
    //   </Link>
    // </MDBox>
    <Stack direction="row" spacing={0.8}>
      <Link to={`/design/banners/edit/${id}`}>
        <EditIcon color="info" fontSize="medium" />
      </Link>

      <HighlightOffIcon
        color="primary"
        fontSize="medium"
        sx={{ cursor: "pointer" }}
        onClick={handleClickOpen}
      />

      <YesOrNoDialog
        heading="Are you sure you want to delete this banner !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteBanner}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
    </Stack>
  );
};

export default ActionsCell;
