import React, { useEffect } from "react";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { convertProductStatusIdToString } from "utils/methods";

export default function ProductStatusAutoComplete({
  productState,
  setProductState,
  makeSelectByThisStatusId = null,
}) {
  const handleChange = (_, value, reason) => {
    if (reason === "selectOption") {
      if (value) {
        setProductState(value);
      }
    }

    // if event change was  "clear" for the autocomplete component =>  clear the state
    if (reason === "clear") {
      setProductState(null);
    }
  };

  useEffect(() => {
    if (makeSelectByThisStatusId) {
      setProductState(convertProductStatusIdToString(makeSelectByThisStatusId));
    }
  }, [makeSelectByThisStatusId]);

  return (
    <Autocomplete
      value={productState ? productState : null}
      onChange={handleChange}
      options={["FEATURED", "PROMOTED", "NEW", "DISCOUNT"]}
      renderInput={(params) => <MDInput label="Product Status" {...params} variant="standard" />}
    />
  );
}
