import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { Autocomplete, TextField } from "@mui/material";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";
import { FetchAdminProducts } from "utils/apis/catalog/products/products";
import { GetAndSearchAdminBrands } from "utils/apis/ecommerce/brands";
import { FetchAdminCategories } from "utils/apis/catalog/categories";

// get parents categories

import { SendNotificationRequest } from "utils/apis/notifications";

function SendNotification() {
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [productSearchKey, setProductSearchKey] = useState();

  const [brands, setBrands] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [brandsSearchKey, setBrandsSearchKey] = useState(null);

  const [categories, setCategories] = useState();
  const [selectedcategory, setSelectedcategory] = useState();
  const [categorySearchKey, setCategorySearchKey] = useState(null);

  const [notificationType, setNotificationType] = useState("brand");

  // parent categories state

  const [parentsCategories, setParentsCategories] = useState(null);

  // use navigate hook

  const navigate = useNavigate();

  const defaultFormFields = {
    title: "",
    body: "",
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { title, body } = formFields;

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // handle create category submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();

    if (!title || !body) {
      return setErrorMSG("Please fill all the fields");
    }

    const dataForsend = {
      title,
      body,
      productId: selectedProduct,
      brandId: selectedBrand,
      categoryId: selectedcategory,
    };

    const result = await SendNotificationRequest(dataForsend);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("the notification is send successfuly");
        // navigate to brands list page
        return navigate("/dashboards/notifications");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  const handleSelectProductChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedProduct(value.product_id);
    } else {
      setSelectedProduct(null);
    }

    if (reason === "clear" && !productSearchKey) {
      setSelectedProduct(null);
    }

    if (reason === "clear" && productSearchKey) {
      setSelectedProduct(null);

      setProductSearchKey(null);
    }
  };

  const handleSelectBrandChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedBrand(value.manufacturer_id);
    } else {
      setSelectedBrand(null);
    }

    if (reason === "clear" && !brandsSearchKey) {
      setSelectedBrand(null);
    }

    if (reason === "clear" && brandsSearchKey) {
      setSelectedBrand(null);

      setBrandsSearchKey(null);
    }
  };

  const handleSelectCategoryChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedcategory(value.category_id);
    } else {
      setSelectedcategory(null);
    }

    if (reason === "clear" && !brandsSearchKey) {
      setSelectedcategory(null);
    }

    if (reason === "clear" && brandsSearchKey) {
      setSelectedcategory(null);

      setCategorySearchKey(null);
    }
  };

  const handleProductSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setProductSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setProductSearchKey(null);
    }
  };

  const handleBrandSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setBrandsSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setBrandsSearchKey(null);
    }
  };

  const handleCategorySearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setCategorySearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setCategorySearchKey(null);
    }
  };

  const handleNotificationTypeChange = (event) => {
    setNotificationType(event.target.value);
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        let searchKeyObject;
        if (!productSearchKey) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              model: productSearchKey,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }
        const result = await FetchAdminProducts(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          const productsItems = results?.map((product) => ({
            label: product.model,
            product_id: product.product_id,
          }));

          setProducts(productsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };
    getProducts();
  }, [productSearchKey]);

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchBrands = async () => {
      try {
        const result = await GetAndSearchAdminBrands({ search: brandsSearchKey });

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha brands as auto complete item
          const brandsItems = results?.map((brand) => ({
            label: brand.name,
            manufacturer_id: brand.manufacturer_id,
          }));

          // set the brands options state
          setBrands(brandsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchBrands();
  }, [brandsSearchKey]);

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchCategories = async () => {
      try {
        let searchKeyObject;
        if (!categorySearchKey) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              name: categorySearchKey,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }
        const result = await FetchAdminCategories(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha categories as auto complete item
          const categoriesItems = results?.map((category) => ({
            label: category.description[0].name,
            category_id: category.category_id,
          }));

          // set the categories options state
          setCategories(categoriesItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchCategories();
  }, [categorySearchKey]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center" mb={3}>
              <MDTypography variant="h3" fontWeight="bold">
                Send Notification
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    send new notification
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDInput
                      name="title"
                      fullWidth
                      label="title"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="body"
                      fullWidth
                      label="body of notification"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControl>
                      {/* <FormLabel id="notification-for">Notification For</FormLabel> */}
                      <MDTypography>Notification For</MDTypography>
                      <RadioGroup
                        row
                        aria-labelledby="notification-for"
                        name="notification-for-group"
                        onChange={handleNotificationTypeChange}
                      >
                        <FormControlLabel
                          value="brand"
                          control={<Radio checked={notificationType === "brand"} />}
                          label="Brand"
                        />
                        <FormControlLabel
                          value="product"
                          control={<Radio checked={notificationType === "product"} />}
                          label="Product"
                        />
                        <FormControlLabel
                          value="category"
                          control={<Radio checked={notificationType === "category"} />}
                          label="Category"
                        />
                      </RadioGroup>
                    </FormControl>
                    {notificationType == "product" && (
                      <Autocomplete
                        // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
                        value={selectedProduct ? selectedProduct : null}
                        onChange={handleSelectProductChange}
                        onInputChange={handleProductSearch}
                        options={products ? products : []}
                        renderInput={(params) => (
                          <MDInput label="Select Product" {...params} variant="standard" />
                        )}
                      />
                    )}
                    {notificationType == "brand" && (
                      <Autocomplete
                        // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
                        value={selectedBrand ? selectedBrand : null}
                        onChange={handleSelectBrandChange}
                        onInputChange={handleBrandSearch}
                        options={brands ? brands : []}
                        renderInput={(params) => (
                          <MDInput label="Select Brand" {...params} variant="standard" />
                        )}
                      />
                    )}
                    {notificationType == "category" && (
                      <Autocomplete
                        // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
                        value={selectedcategory ? selectedcategory : null}
                        onChange={handleSelectCategoryChange}
                        onInputChange={handleCategorySearch}
                        options={categories ? categories : []}
                        renderInput={(params) => (
                          <MDInput label="Select Category" {...params} variant="standard" />
                        )}
                      />
                    )}
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    send the notification
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SendNotification;
