import CategoryImageCell from "../../categories-list/components/CategoryImageCell/";
import ActionsCell from "../../categories-list/components/ActionsCell";
import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

export const subCategoriesListHeader = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Category Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Image",
    accessor: "image",
    Cell: ({ value }) => <CategoryImageCell image={value} />,
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
