import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

const ActionsCell = ({ id }) => {
  const navigate = useNavigate();
  const goToDetails = (orderId) => {
    navigate(`/sales/orders/order-details/${orderId}`);
  };

  return (
    <MDBox>
      <Link to="/">
        <Icon color="secondary" title="preview" fontSize="medium">
          preview
        </Icon>
      </Link>
    </MDBox>
  );
};

export default ActionsCell;
