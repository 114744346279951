// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

// Data table headers
import { brandsHeaders } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils
import { BrandsContext } from "context/brands";
import { UserContext } from "context/user";
import { useContext, useEffect } from "react";
import { GetAndSearchAdminBrands } from "utils/apis/ecommerce/brands";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";

//  brands context
function BrandsList() {
  // Brands State
  const { brands, setBrands } = useContext(BrandsContext);
  const [searchKey, setSearchKey] = useState(null);
  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});
  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the brands
  useEffect(() => {
    const fetchBrands = async () => {
      let searchKeyObject;
      if (!searchKey) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            search: searchKey,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
        setSearchKeyObjectPagination(searchKeyObject);
      }
      const result = await GetAndSearchAdminBrands(searchKeyObject);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const { count, next, previous, results } = result.data;
          return setBrands({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    fetchBrands();
  }, [searchKey]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/ecommerce/brands/create"}>
            <MDButton variant="gradient" color="info">
              new Brand
            </MDButton>
          </Link>

          <MDBox display="flex" justifyContent="center" alignItems="center" gap={2}>
            {/* <MDButton variant={menu ? "contained" : "outlined"} color="dark">
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton> */}
            {/* {renderMenu} */}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
            <MDInput
              label="searching for Brand"
              variant="outlined"
              onBlur={(e) => {
                setSearchKey(e.target.value);
              }}
              onKeyDown={(e) => handleKeyPress(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
        </MDBox>
        <Card>
          {!brands?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {brands?.data && (
            <DataTable
              table={{
                columns: brandsHeaders,
                rows: brands.data.map((brand) => ({
                  id: brand.manufacturer_id,
                  name: brand.name,
                  image: brand.image,
                  sortOrder: brand.sort_order,
                  enabled: brand.enabled,
                  actions: brand.manufacturer_id,
                })),
              }}
              nextPageUrl={brands.next}
              previousPageUrl={brands.previous}
              countPagination={brands.count}
              searchKeyObjectPagination={searchKeyObjectPagination}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BrandsList;
