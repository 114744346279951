import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Post brand method

import { GetAdminBannerById, EditAdminBanner } from "utils/apis/design/banners";

import { useParams, useNavigate } from "react-router-dom";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";

import CategoryAutocomplete from "elements/CategoryAutocomplete";
import BrandAutocomplete from "elements/BrandAutocomplete";
import ProductAutocomplete from "elements/ProductAutocomplete";

import InputFileAndPreview from "elements/InputFileAndPreview";

function BannerEdit() {
  const { id } = useParams();

  // old banner

  const [banner, setBanner] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  const [selectedBannerTypeIdObj, setSelectedBannerTypeIdObj] = useState(null);

  const [originalBannerType, setOriginalBannerType] = useState(null);

  useEffect(() => {
    if (id) {
      const getBannerById = async () => {
        setLoadingOrNot(true);
        const result = await GetAdminBannerById(id);
        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            // set state of banner
            const { title, language_id, image, banner_type, banner_type_id, sort_order } =
              result.data;
            setBanner({
              title,
              sort_order,
              language_id,
              image,
              banner_type,
              banner_type_id,
            });
            setOriginalBannerType(banner_type);
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };

      getBannerById();
    }
  }, [id]);

  // use navigate hook

  const navigate = useNavigate();

  // set banner title state
  const handleBannerTitleChange = (event) => {
    // update the banner title
    setBanner({
      ...banner,
      title: event.target.value,
    });
  };

  // handle change handler (general)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setBanner({ ...banner, [name]: value });
  };

  // set image state
  const handleImageUpload = (event) => {
    // update the banner image
    const file = event.target.files[0];
    setBanner({
      ...banner,
      image: file,
    });
  };

  // handle update brand submit button
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset validation messages
    clearErrorMSG();

    // Validate brand name and image
    if (!banner.title || !banner.image) {
      return setErrorMSG("Please fill all the fields");
    }

    let newBanner = banner;
    console.log(banner);
    let bannerTypeId = newBanner?.banner_type_id;
    console.log(bannerTypeId);
    console.log(selectedBannerTypeIdObj);

    if (newBanner?.banner_type == "brand" && selectedBannerTypeIdObj) {
      console.log("brand");
      bannerTypeId = selectedBannerTypeIdObj?.manufacturer_id;
    } else if (newBanner?.banner_type == "category" && selectedBannerTypeIdObj) {
      console.log("category");
      bannerTypeId = selectedBannerTypeIdObj?.category_id;
    } else if (newBanner?.banner_type == "product" && selectedBannerTypeIdObj) {
      console.log("product");
      bannerTypeId = selectedBannerTypeIdObj?.product_id;
    }

    if (newBanner.banner_type && !bannerTypeId) {
      return setErrorMSG("Please fill banner_type_id not only banner type");
    }

    const result = await EditAdminBanner(id, { ...newBanner, banner_type_id: bannerTypeId });

    if (!(result instanceof Error)) {
      // back end validation errors
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Banner updated successfuly");
        // navigate to banners list page
        navigate("/design/banners");
      } else {
        setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.response.data.error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={8}>
              <MDBox mt={6} mb={2} textAlign="center">
                <MDTypography variant="h3" fontWeight="bold">
                  Edit Banner
                </MDTypography>
              </MDBox>
              <Card>
                {banner ? (
                  <MDBox p={3}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Edit Banner #{id}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        fullWidth
                        label="Title"
                        name="title"
                        onChange={handleChange}
                        type="text"
                        value={banner.title}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        name="sort_order"
                        fullWidth
                        label="sort_order"
                        value={banner.sort_order}
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl fullWidth>
                        <InputLabel id="banner_type_id_label_edit">
                          Banner Type (where to go)
                        </InputLabel>
                        <Select
                          value={banner?.banner_type}
                          name="banner_type"
                          onChange={handleChange}
                          labelId="banner_type_id_label_edit"
                          label="banner type"
                          id="banner-type-id"
                          fullWidth
                          sx={{
                            minWidth: 120,
                            lineHeight: "3rem",
                          }}
                        >
                          <MenuItem value="brand">brand</MenuItem>
                          <MenuItem value="category">category</MenuItem>
                          <MenuItem value="product">product</MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox mb={2}>
                      {banner?.banner_type == "brand" && (
                        <BrandAutocomplete
                          selectedBrand={selectedBannerTypeIdObj}
                          setSelectedBrand={setSelectedBannerTypeIdObj}
                          makeSelectByThisBrandId={
                            originalBannerType == "brand" ? banner.banner_type_id : null
                          }
                        />
                      )}

                      {banner?.banner_type == "category" && (
                        <CategoryAutocomplete
                          selectedCategory={selectedBannerTypeIdObj}
                          setSelectedCategory={setSelectedBannerTypeIdObj}
                          makeSelectByThisCategoryId={
                            originalBannerType == "category" ? banner.banner_type_id : null
                          }
                        />
                      )}
                      {banner?.banner_type == "product" && (
                        <ProductAutocomplete
                          selectedProduct={selectedBannerTypeIdObj}
                          setSelectedProduct={setSelectedBannerTypeIdObj}
                          makeSelectByThisProductId={
                            originalBannerType == "product" ? banner.banner_type_id : null
                          }
                        />
                      )}
                    </MDBox>
                    {/* <MDBox mb={2}>
                      <MDInput type="file" onChange={handleImageUpload} />
                    </MDBox> */}
                    <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                      <InputFileAndPreview
                        handleImageUpload={handleImageUpload}
                        file={banner.image}
                      />
                    </MDBox>
                    <MDBox mb={2} sx={{ width: "400px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="language_id_label">Language</InputLabel>
                        <Select
                          value={banner.language_id}
                          name="language_id"
                          onChange={handleChange}
                          labelId="language-id"
                          label="Language"
                          id="language_id"
                          fullWidth
                          sx={{
                            minWidth: 120,
                            lineHeight: "3rem",
                          }}
                        >
                          <MenuItem value={1}>English</MenuItem>
                          <MenuItem value={2}>Arabic</MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox component="img" alt="Not Found" src={NotFound404} />
                  </MDBox>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default BannerEdit;
