import React from "react";
import { Card } from "@mui/material";
import Stack from "@mui/material/Stack";
import StarIcon from "@mui/icons-material/Star";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function UserRank({ state }) {
  return (
    <Card sx={{ p: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        rowSpacing={2}
        alignItems="center"
        sx={{ borderBottom: 2, borderColor: "divider", mb: 1, py: 2 }}
      >
        <MDBox>
          <StarIcon />
          <MDTypography>{state?.user_rank?.rank_name?.[0]?.rank_name}</MDTypography>
        </MDBox>
        <MDBox>
          {state?.user_rank?.min_points}pt - {state?.user_rank?.max_points}pt
        </MDBox>
      </Stack>
      <Stack direction="row" justifyContent="center">
        current points : {state?.user_points}
      </Stack>
    </Card>
  );
}
