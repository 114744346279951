import ActionsCell from "../components/ActionsCell";
import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

export const productsAttributesHeader = [
  { Header: "Group Id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },

  {
    Header: "Group Name English",
    accessor: "nameEnglish",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Group Name Arabic",
    accessor: "nameArabic",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
