// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { generalCouponsHeaders } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils
import { CouponsContext } from "context/coupons";
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { FetchAdminCoupons } from "utils/apis/ecommerce/coupons";
import { Link } from "react-router-dom";

import { displayBasicDate } from "utils/methods";

import CircularProgress from "@mui/material/CircularProgress";
import MDInput from "components/MDInput";

function CouponsList() {
  // Coupons State
  const { coupons, setCoupons } = useContext(CouponsContext);
  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});
  // it is customer_id and when it is 0 the value results from api will be onlyAdminCoupons
  const [onlyAdminCoupons, setOnlyAdminCoupons] = useState(0);

  const [getNewResults, setGetNewResults] = useState(0);

  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the Coupons
  useEffect(() => {
    const fetchCoupons = async () => {
      let searchKeyObject;
      if (onlyAdminCoupons === null || onlyAdminCoupons === undefined) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            for_customer_id: onlyAdminCoupons,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
        setSearchKeyObjectPagination(searchKeyObject);
      }
      const result = await FetchAdminCoupons(searchKeyObject);
      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const { count, next, previous, results } = result.data;
          return setCoupons({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    fetchCoupons();
  }, [onlyAdminCoupons, getNewResults]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      setGetNewResults(Math.random());
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/ecommerce/coupons/create"}>
            <MDButton variant="gradient" color="info">
              New Coupon
            </MDButton>
          </Link>
          <MDBox display="flex">
            <MDInput
              label="customer id"
              variant="outlined"
              onBlur={(e) => {
                setOnlyAdminCoupons(e.target.value);
              }}
              onKeyDown={(e) => handleKeyPress(e)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
        </MDBox>
        <Card>
          {!coupons?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {coupons?.data && (
            <DataTable
              table={{
                columns: generalCouponsHeaders,
                rows: coupons.data.map((coupon) => ({
                  id: coupon.coupon_id,
                  name: coupon.name,
                  code: coupon.code,
                  type: coupon.type,
                  discount: coupon.discount,
                  total_max: coupon.total_max,
                  status: coupon.status,
                  date_added: coupon.date_added ? displayBasicDate(coupon.date_added) : "/",
                  date_start: coupon.date_start ? displayBasicDate(coupon.date_start) : "/",
                  date_end: coupon.date_end ? displayBasicDate(coupon.date_end) : "/",

                  actions: coupon.coupon_id,
                })),
              }}
              nextPageUrl={coupons.next}
              previousPageUrl={coupons.previous}
              countPagination={coupons.count}
              searchKeyObjectPagination={searchKeyObjectPagination}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CouponsList;
