import { useContext } from "react";
import { useEffect, useState } from "react";

import MDInput from "components/MDInput";
import { Autocomplete, TextField } from "@mui/material";

import { FetchAdminProducts, FetchAdminProductById } from "utils/apis/catalog/products/products";
import { NotificationsContext } from "context/notifications";

export default function ProductAutocomplete({
  selectedProduct,
  setSelectedProduct,
  makeSelectByThisProductId = null,
}) {
  const { setErrorMSG } = useContext(NotificationsContext);
  const [products, setProducts] = useState([]);
  //   const [selectedProduct, setSelectedProduct] = useState();
  const [productSearchKey, setProductSearchKey] = useState();

  const handleSelectProductChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedProduct(value);
    } else {
      setSelectedProduct(null);
    }

    if (reason === "clear" && !productSearchKey) {
      setSelectedProduct(null);
    }

    if (reason === "clear" && productSearchKey) {
      setSelectedProduct(null);

      setProductSearchKey(null);
    }
  };

  const handleProductSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setProductSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setProductSearchKey(null);
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        let searchKeyObject;
        if (!productSearchKey) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              model: productSearchKey,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }
        const result = await FetchAdminProducts(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          const productsItems = results?.map((product) => ({
            label: product.model,
            product_id: product.product_id,
          }));

          setProducts(productsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };
    getProducts();
  }, [productSearchKey]);

  useEffect(() => {
    if (makeSelectByThisProductId) {
      const fetchThisProduct = async () => {
        try {
          const productResult = await FetchAdminProductById(makeSelectByThisProductId);
          if (productResult.status === 200 && productResult.data) {
            const { data } = productResult;

            const productOfThisId = {
              label: data?.model,
              product_id: data?.product_id,
            };

            // set the categories options state
            setSelectedProduct(productOfThisId);
          }
        } catch (error) {
          console.log(error);
          setErrorMSG("something went wrong");
        }
      };
      fetchThisProduct();
    }
  }, [makeSelectByThisProductId]);

  return (
    <Autocomplete
      // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
      value={selectedProduct ? selectedProduct : null}
      onChange={handleSelectProductChange}
      onInputChange={handleProductSearch}
      options={products ? products : []}
      renderInput={(params) => <MDInput label="Select Product" {...params} variant="standard" />}
    />
  );
}
