import React, { createContext, useReducer } from "react";

const SET_NOTIFICATIONS_ACTIONS = {
  SET_SUCCESS_MSG: "SET_SUCCESS_MSG",
  SET_ERROR_MSG: "SET_ERROR_MSG",
  CLEAR_SUCCESS_MSG: "CLEAR_SUCCESS_MSG",
  CLEAR_ERROR_MSG: "CLEAR_ERROR_MSG",
};

// Initial state of the notifications context
const initialState = {
  successMSG: null,
  errorMSG: null,
};

// notifications context reducer function
const notificationsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTIFICATIONS_ACTIONS.SET_SUCCESS_MSG:
      return { ...state, successMSG: payload };
    case SET_NOTIFICATIONS_ACTIONS.SET_ERROR_MSG:
      return { ...state, errorMSG: payload };
    case SET_NOTIFICATIONS_ACTIONS.CLEAR_SUCCESS_MSG:
      return { ...state, successMSG: null };
    case SET_NOTIFICATIONS_ACTIONS.CLEAR_ERROR_MSG:
      return { ...state, errorMSG: null };
    default:
      return state;
  }
};

// Create the notifications context
const NotificationsContext = createContext();

// notifications context provider component
const NotificationsContextProvider = ({ children }) => {
  const [{ successMSG, errorMSG }, dispatch] = useReducer(notificationsReducer, initialState);

  // Action to set success msg
  const setSuccessMSG = (message) => {
    dispatch({ type: SET_NOTIFICATIONS_ACTIONS.SET_SUCCESS_MSG, payload: message });
  };

  // Action to clear success msg
  const clearSuccessMSG = () => {
    dispatch({ type: SET_NOTIFICATIONS_ACTIONS.CLEAR_SUCCESS_MSG });
  };

  // Action to set error msg
  const setErrorMSG = (message) => {
    dispatch({ type: SET_NOTIFICATIONS_ACTIONS.SET_ERROR_MSG, payload: message });
  };
  // Action to clear error msg
  const clearErrorMSG = () => {
    dispatch({ type: SET_NOTIFICATIONS_ACTIONS.CLEAR_ERROR_MSG });
  };

  const value = {
    successMSG,
    errorMSG,
    setSuccessMSG,
    setErrorMSG,
    clearErrorMSG,
    clearSuccessMSG,
  };
  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export { NotificationsContext, NotificationsContextProvider };
