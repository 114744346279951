import React, { useEffect, useState, useContext } from "react";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Stack from "@mui/material/Stack";

import { Autocomplete } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { NotificationsContext } from "context/notifications";
import { FetchAdminProducts } from "utils/apis/catalog/products/products";
import { EditAdminOrderProducts } from "utils/apis/sales/orders";
import MDBox from "components/MDBox";
import Paper from "@mui/material/Paper";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function AddModal({
  open,
  handleClose,
  orderProducts,
  setOrderProductUpdated,
  orderId,
}) {
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);
  const [selectedProduct, setSelectedProduct] = useState();
  const [productSearchKey, setProductSearchKey] = useState();
  const [products, setProducts] = useState([]);
  const [localOrderProduct, setLocalOrderProduct] = useState(orderProducts);
  const [quantity, setQuantity] = useState();

  useEffect(() => {
    setLocalOrderProduct(orderProducts);
  }, [orderProducts]);

  const handleSelectProductChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedProduct(value);
    } else {
      setSelectedProduct(null);
    }

    if (reason === "clear" && !productSearchKey) {
      setSelectedProduct(null);
    }

    if (reason === "clear" && productSearchKey) {
      setSelectedProduct(null);

      setProductSearchKey(null);
    }
  };
  const handleProductSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setProductSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
  };
  useEffect(() => {
    const getProducts = async () => {
      try {
        let searchKeyObject;
        if (!productSearchKey) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              model: productSearchKey,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }
        const result = await FetchAdminProducts(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          const productsItems = results?.map((product) => ({
            label: product.model,
            product_id: product.product_id,
          }));

          setProducts(productsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };
    getProducts();
  }, [productSearchKey]);

  const handleDeleteProduct = (model) => {
    setLocalOrderProduct((prev) => {
      return prev.filter((product) => product.model != model);
    });
  };

  const handleAddProduct = () => {
    if (Number(quantity) > 0) {
      setLocalOrderProduct((prev) => [
        ...prev,
        {
          model: selectedProduct.label,
          product_id: selectedProduct.product_id,
          quantity: Number(quantity),
        },
      ]);
      setQuantity(0);
      setSelectedProduct(null);
    }
  };

  const changeProductsOfOrder = async () => {
    const ArrayOfProductsToSend = localOrderProduct.map((product) => {
      return {
        quantity: product.quantity,
        product_id: product.product_id,
        product_option_value_id: "None",
      };
    });
    try {
      const result = await EditAdminOrderProducts(orderId, ArrayOfProductsToSend);
      if (!(result instanceof Error)) {
        // this is from backend (backend-validations),
        if (!result.data.error) {
          // show success notification
          setOrderProductUpdated((prev) => !prev);
          setSuccessMSG("products updated successfully");
          handleClose();
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        console.log(result);
        if (result?.response?.data?.error) return setErrorMSG(result.response.data.error);
        return setErrorMSG(result.message);
      }
    } catch (error) {
      setErrorMSG(error);
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="add-products-dialog">Add Product To Order ::</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ minWidth: "500px !important", marginTop: 2 }}>
          <MDBox>
            <TableContainer component={Paper} sx={{ width: "100%", boxShadow: 1, py: 2 }}>
              <Table
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                aria-label="user details table"
                className="table-with-p-2-2"
              >
                <TableRow>
                  <TableCell align="left">product model</TableCell>
                  <TableCell align="center">qty</TableCell>
                  <TableCell align="center">delete</TableCell>
                </TableRow>
                {localOrderProduct &&
                  localOrderProduct?.map((product) => (
                    <TableBody key={product.product_id}>
                      <TableRow>
                        <TableCell align="left">{product.model}</TableCell>
                        <TableCell align="center">{product.quantity}</TableCell>
                        <TableCell align="center">
                          <HighlightOffIcon
                            color="primary"
                            fontSize="medium"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleDeleteProduct(product.model)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </MDBox>
          <Autocomplete
            // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
            value={selectedProduct ? selectedProduct : null}
            onChange={handleSelectProductChange}
            onInputChange={handleProductSearch}
            options={products ? products : []}
            renderInput={(params) => (
              <MDInput label="Select Product" {...params} variant="standard" />
            )}
          />
          <MDInput
            name="quantity"
            label="quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <MDButton variant="gradient" color="info" onClick={handleAddProduct}>
            Add
          </MDButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose}>close</MDButton>
        <MDButton variant="gradient" color="info" onClick={changeProductsOfOrder}>
          Save Change
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
