// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { deliveryCostsHeader } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { FetchDeliveryCosts } from "utils/apis/sales/deliveryCosts";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import { displayBasicDate } from "utils/methods";

import "app.css";

//  delivery costs context
function DeliveryCostsList() {
  // delivery costs state
  const [deliveryCosts, setDeliveryCosts] = useState(null);
  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the delivery costs
  useEffect(() => {
    const fetchDeliveryCosts = async () => {
      const result = await FetchDeliveryCosts(user);
      if (!(result instanceof Error) && result.data) {
        const { count, next, previous, results } = result.data;
        return setDeliveryCosts({
          count,
          next,
          previous,
          data: results,
        });
      } else {
        setErrorMSG(result.message);
      }
    };

    fetchDeliveryCosts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3} className="before-footer">
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {/* <MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox> */}
          <Link to="/sales/delivery-cost/update">
            <MDButton variant="gradient" color="info">
              delivery cost update
            </MDButton>
          </Link>
        </MDBox>
        <Card sx={{ padding: 6 }}>
          {!deliveryCosts?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {deliveryCosts?.data && (
            <DataTable
              table={{
                columns: deliveryCostsHeader,
                rows: deliveryCosts.data.map((delivery_cost) => ({
                  id: delivery_cost.id,
                  zone: delivery_cost.zone,
                  cost: delivery_cost.cost,
                  special_cost: delivery_cost.special_cost,
                  special_cost_total_order: delivery_cost.special_cost_total_order,
                  start_date: delivery_cost.date_start
                    ? displayBasicDate(delivery_cost.date_start)
                    : "/",
                  end_date: delivery_cost.date_end ? displayBasicDate(delivery_cost.date_end) : "/",
                })),
              }}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeliveryCostsList;
