import React from "react";
import { useState, useEffect, useContext } from "react";

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import MDButton from "components/MDButton";

import { vonageSendMessage } from "utils/apis/messages";

import { GetHistoryOfOrderStatusAndComment } from "utils/apis/history";
import { convertStatusIdToString } from "utils/methods";
import { NotificationsContext } from "context/notifications";

import { EditCustomerNotifiedIdStringArray } from "utils/apis/sales/orders";

// userNotifiedArray it is string like this '[0]' when any value of array is 0 this mean user not notified yet
// for first row in history tabel the value will be 0 by default and the user dont need to be notified by first history
// because it is creation history not updating

const HistoryOfStatus = ({
  orderId,
  reRender,
  messageInfo,
  userNotifiedArray,
  setReRenderOrderDetails,
}) => {
  const { setErrorMSG } = useContext(NotificationsContext);
  const [historyObj, setHistoryObj] = useState();
  const [userNotifiedOrNotArray, setUserNotifiedOrNotArray] = useState();

  useEffect(() => {
    if (userNotifiedArray != "" && userNotifiedArray) {
      try {
        const parsedArray = JSON.parse(userNotifiedArray);
        setUserNotifiedOrNotArray(parsedArray);
      } catch (error) {
        console.error("Error parsing userNotifiedArray:", error);
      }
    }
  }, [userNotifiedArray]);

  console.log("sasasa", userNotifiedOrNotArray);

  const sendMessageFunction = async (index, status) => {
    let messageObjectBody = {
      receiverNumber: messageInfo.customer_number,
      customerId: messageInfo.customerId,
      content: "Your request has been processed, and it will be delivered to you very soon",
      firstChannel: "sms",
      type: "orderStatus",
    };

    if (status == "Completed") {
      messageObjectBody.content =
        "😎 " +
        "تم تأكيد طلبك المرقم" +
        messageInfo.orderId +
        ", من شغلاتي، سنتواصل معك في أقرب وقت";
    } else if (status == "Refunded") {
      messageObjectBody.content =
        "🥺 " +
        "تم إسترداد طلبك المُرقم" +
        messageInfo.orderId +
        ", نأمل أن تعاود التسوق معنا قريبًا";
    } else if (status == "Cancelled Order") {
      messageObjectBody.content =
        "🥹 " + "يؤسفنا إلغاء طلبك المُرقم" + messageInfo.orderId + ", نتمنى خدمتك قريبًا";
    }

    try {
      const response = await vonageSendMessage(messageObjectBody);
      // response.status === 201
      if (response.status === 201 || response.status === 200) {
        if (userNotifiedOrNotArray?.length) {
          const newCustomerNotifiedArr = userNotifiedOrNotArray;
          newCustomerNotifiedArr[index] = 1;
          console.log(newCustomerNotifiedArr);

          const responseOfUpdateCustomerNotifiedArr = await EditCustomerNotifiedIdStringArray(
            orderId,
            newCustomerNotifiedArr
          );

          if (
            responseOfUpdateCustomerNotifiedArr.status === 200 ||
            responseOfUpdateCustomerNotifiedArr.status === 201
          ) {
            setReRenderOrderDetails((prev) => !prev);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchOrderHistory = async () => {
      const result = await GetHistoryOfOrderStatusAndComment(orderId);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const filteredResults = result.data?.results.filter((item) => {
            const changes = JSON.parse(item.changes);
            return changes.hasOwnProperty("order_status_id");
          });
          const sortedResults = filteredResults.sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return dateB - dateA;
          });
          console.log("sorted", sortedResults);
          return setHistoryObj(sortedResults);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };
    fetchOrderHistory();
  }, [reRender]);

  console.log(userNotifiedOrNotArray);

  const userNotifiedOrNotFunction = (index) => {
    // length exist and index exist
    if (userNotifiedOrNotArray?.length && userNotifiedOrNotArray?.length > index) {
      // 0 ---> not notified
      if (userNotifiedOrNotArray[index]) {
        return 1;
      } else return 0;
    }
    //  to diable send message if there is error
    return 1;
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ marginTop: "16px", boxShadow: "none", overflow: "visible", mb: 2 }}
    >
      <Table sx={{ minWidth: 650 }} size="small">
        <TableBody>
          {/* headers */}
          <TableRow>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              status
            </TableCell>

            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              comment
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              is notified
            </TableCell>

            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              send message
            </TableCell>
          </TableRow>

          {historyObj &&
            historyObj.map((single_log, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }}
                      align="left"
                    >
                      {JSON.parse(single_log.changes)?.order_status_id &&
                        convertStatusIdToString(JSON.parse(single_log.changes)?.order_status_id[1])}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }}
                      align="left"
                    >
                      {JSON.parse(single_log.changes)?.comment &&
                        JSON.parse(single_log.changes)?.comment[1]}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }}
                      align="left"
                    >
                      {index == historyObj?.length - 1
                        ? "-----"
                        : userNotifiedOrNotFunction(index)
                        ? "Yes"
                        : "No"}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }}
                      align="left"
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          sendMessageFunction(
                            index,
                            convertStatusIdToString(
                              JSON.parse(single_log.changes)?.order_status_id[1]
                            )
                          );
                        }}
                        disabled={
                          userNotifiedOrNotFunction(index) || index == historyObj?.length - 1
                        }
                      >
                        send notification
                      </MDButton>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoryOfStatus;
