import DataTable from "./DataTable/index";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { formatDateToISO } from "utils/methods";

import Autocomplete from "@mui/material/Autocomplete";
// Data table headers
import { productsHeaders } from "./data/dataTableData";

import { displayBasicDate } from "utils/methods";

// Request Utils
import { ProductsContext } from "context/products/products";
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { FetchAdminProducts, SearchAdminProducts } from "utils/apis/catalog/products/products";
import { GetAndSearchAdminBrands } from "utils/apis/ecommerce/brands";
import { convertProductStatusStringToId } from "utils/methods";

import { Link } from "react-router-dom";

import { NotificationsContext } from "context/notifications";
import CircularProgress from "@mui/material/CircularProgress";

export default function ProductsListComponent({ category_id }) {
  const [arrayOfSelectedProducts, setArrayOfSelectedProducts] = useState([]);
  const [reRenderProducts, setRerenderProducts] = useState(false);
  // Products State
  const { products, setProducts, clearProducts, productsFilters, setProductsFilter } =
    useContext(ProductsContext);
  console.log(productsFilters);

  const [searchTerm, setSearchTerm] = useState(productsFilters?.searchTerm || null);
  const [productId, setProductId] = useState(productsFilters?.productId || null);
  const [availableQuantity, setAvailableQuantity] = useState(
    productsFilters?.availableQuantity || null
  );
  const [price, setPrice] = useState(productsFilters?.price || null);
  const [discountStartDate, setDiscountStartDate] = useState(
    productsFilters?.discountStartDate || null
  );
  const [discountExpiryDate, setDiscountExpiryDate] = useState(
    productsFilters?.discountExpiryDate || null
  );
  const [dateAdded, setDateAdded] = useState(productsFilters?.dateAdded || null);
  const [dateModified, setDateModified] = useState(productsFilters?.dateModified || null);
  const [categoryId, setCategoryId] = useState(category_id || null);
  const [status, setStatus] = useState(productsFilters?.status || "All");

  const [brandsOptions, setBrandsOptions] = useState(null);
  const [brandsSearchKey, setBrandsSearchKey] = useState(null);
  const [selectedBrandForFinalSearch, setSelectedBrandForFinalSearch] = useState(
    productsFilters?.selectedBrandForFinalSearch || null
  );
  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});

  const handleDiscountStartDateChange = (_, value) => {
    setDiscountStartDate(value);
  };
  const handleDiscountExpiryDateChange = (_, value) => {
    setDiscountExpiryDate(value);
  };
  const handleDateAddedChange = (_, value) => {
    setDateAdded(value);
  };
  const handleDateModifiedChange = (_, value) => {
    setDateModified(value);
  };

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // handle brand change
  const handleBrandChange = (_, value) => {
    // Only if there is a value
    setSelectedBrandForFinalSearch(value);
  };

  const handleStatusChange = (_, value) => {
    // Only if there is a value
    setStatus(value);
  };

  // Fetch the products
  useEffect(() => {
    const fetchProducts = async () => {
      let searchKeyObject;

      if (
        !searchTerm &&
        !selectedBrandForFinalSearch &&
        !productId &&
        !availableQuantity &&
        !price &&
        !discountStartDate &&
        !discountExpiryDate &&
        !dateAdded &&
        !dateModified &&
        !categoryId &&
        status == "All"
      ) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            model: searchTerm,
            manufacturer_id:
              selectedBrandForFinalSearch && selectedBrandForFinalSearch.manufacturer_id,
            product_id: productId,
            available_quantity: availableQuantity,
            price: price,
            discount_start_date: discountStartDate ? formatDateToISO(discountStartDate) : null,
            discount_expiry_date: discountExpiryDate ? formatDateToISO(discountExpiryDate) : null,
            date_added: dateAdded ? formatDateToISO(dateAdded) : null,
            date_modified: dateModified ? formatDateToISO(dateModified) : null,
            category_id: categoryId,
            status: status != "All" ? convertProductStatusStringToId(status) : null,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
        setSearchKeyObjectPagination(searchKeyObject);
      }
      const result = await FetchAdminProducts(searchKeyObject);
      // const result = await FetchAdminProducts(searchObj);

      if (!(result instanceof Error)) {
        if (!result.data.error && result.data) {
          const { count, next, previous, results } = result.data;
          return setProducts({
            count,
            next,
            previous,
            data: results,
          });
        } else;
        return setErrorMSG(result.data.error);
      } else {
        return setErrorMSG(result.message);
      }
    };

    fetchProducts();
  }, [
    searchTerm,
    selectedBrandForFinalSearch,
    productId,
    availableQuantity,
    price,
    discountStartDate,
    discountExpiryDate,
    dateAdded,
    dateModified,
    status,
    categoryId,
    reRenderProducts,
  ]);

  const handleBrandsSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 3) {
      setBrandsSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
  };

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchBrands = async () => {
      try {
        const result = await GetAndSearchAdminBrands({ search: brandsSearchKey });

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha brands as auto complete item
          const brandsItems = results?.map((brand) => ({
            label: brand.name,
            manufacturer_id: brand.manufacturer_id,
          }));

          // set the brands options state
          setBrandsOptions(brandsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchBrands();
  }, [brandsSearchKey]);

  useEffect(() => {
    return () => {
      // Cleanup code runs before the component is unmounted
      // clearProducts();

      // take all filters
      //  name , value
      setProductsFilter("searchTerm", searchTerm);
      setProductsFilter("selectedBrandForFinalSearch", selectedBrandForFinalSearch);
      setProductsFilter("productId", productId);
      setProductsFilter("availableQuantity", availableQuantity);
      setProductsFilter("price", price);
      setProductsFilter("discountStartDate", discountStartDate);
      setProductsFilter("discountExpiryDate", discountExpiryDate);
      setProductsFilter("dateAdded", dateAdded);
      setProductsFilter("dateModified", dateModified);
      setProductsFilter("status", status);
      setProductsFilter("categoryId", categoryId);
    };
  }, [
    searchTerm,
    selectedBrandForFinalSearch,
    productId,
    availableQuantity,
    price,
    discountStartDate,
    discountExpiryDate,
    dateAdded,
    dateModified,
    status,
    categoryId,
  ]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <MDBox my={3}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Link to={"/catalog/products/create"} style={{ flexShrink: "0", marginInlineEnd: "2rem" }}>
          <MDButton variant="gradient" color="info">
            New Product
          </MDButton>
        </Link>
        <Grid container spacing={2}>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDInput
              label="model"
              variant="outlined"
              onBlur={(e) => {
                setSearchTerm(e.target.value);
              }}
              defaultValue={searchTerm}
              onKeyDown={(e) => handleKeyPress(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDInput
              label="product id"
              variant="outlined"
              onBlur={(e) => {
                setProductId(e.target.value);
              }}
              defaultValue={productId}
              onKeyDown={(e) => handleKeyPress(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDInput
              name="available quantity"
              label="available quantity"
              type="number"
              fullWidth
              onBlur={(e) => {
                setAvailableQuantity(e.target.value);
              }}
              defaultValue={availableQuantity}
              onKeyDown={(e) => handleKeyPress(e)}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDInput
              name="price"
              label="price"
              type="number"
              fullWidth
              onBlur={(e) => {
                setPrice(e.target.value);
              }}
              defaultValue={price}
              onKeyDown={(e) => handleKeyPress(e)}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <Autocomplete
              // getOptionSelected is used to hide warning about option not equal to value because of
              // {property , id} != {property , id} but option.id === value.manufacturer_id
              isOptionEqualToValue={(option, value) => option.id === value.manufacturer_id}
              value={selectedBrandForFinalSearch ? selectedBrandForFinalSearch.label : null}
              onChange={handleBrandChange}
              onInputChange={handleBrandsSearch}
              options={brandsOptions ? brandsOptions : []}
              renderInput={(params) => (
                <MDInput label="Select Brand" {...params} variant="standard" />
              )}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDDatePicker
              input={{
                id: "discountStartDate",
                name: "discount start date",
                placeholder: "Select discount start date",
                fullWidth: true,
              }}
              defaultValue={discountStartDate}
              name="discountStartDate"
              options={{
                enableTime: false,
                enableSeconds: false,
              }}
              onChange={handleDiscountStartDateChange}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDDatePicker
              input={{
                id: "discountExpiryDate",
                name: "discount expiry date",
                placeholder: "Select discount expiry date",
                fullWidth: true,
              }}
              defaultValue={discountExpiryDate}
              name="discountExpiryDate"
              options={{
                enableTime: false,
                enableSeconds: false,
              }}
              onChange={handleDiscountExpiryDateChange}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDDatePicker
              input={{
                id: "dateAdded",
                name: "date added",
                placeholder: "Select date added",
                fullWidth: true,
              }}
              defaultValue={dateAdded}
              name="dateAdded"
              options={{
                enableTime: false,
                enableSeconds: false,
              }}
              onChange={handleDateAddedChange}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <MDDatePicker
              input={{
                id: "dateModified",
                name: "date modified",
                placeholder: "Select date modified",
                fullWidth: true,
              }}
              defaultValue={dateModified}
              name="dateModified"
              options={{
                enableTime: false,
                enableSeconds: false,
              }}
              onChange={handleDateModifiedChange}
            />
          </Grid>
          <Grid item sx={12} sm={6} md={3} xl={2}>
            <Autocomplete
              value={status ? status : "All"}
              onChange={handleStatusChange}
              options={["FEATURED", "PROMOTED", "NEW", "DISCOUNT", "All"]}
              disableClearable
              renderInput={(params) => (
                <MDInput label="Product Status" {...params} variant="standard" />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Card>
        {!products?.data && (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        )}
        {products?.data && (
          <DataTable
            table={{
              columns: productsHeaders,
              rows: products.data.map((product) => ({
                product: product?.images?.length >= 1 ? product.images[0].image : "",
                id: {
                  id: product.product_id,
                  arrayOfSelectedProducts: arrayOfSelectedProducts,
                  setArrayOfSelectedProducts: setArrayOfSelectedProducts,
                },
                model: product.model,
                quantity_avilable: product.available_quantity,
                price: product.price,
                status: product.status,
                date_added: product.date_added ? displayBasicDate(product.date_added) : "/",
                date_modified: product.date_modified
                  ? displayBasicDate(product.date_modified)
                  : "/",
                actions: {
                  id: product.product_id,
                  product,
                  setRerenderProducts: setRerenderProducts,
                },
              })),
            }}
            nextPageUrl={products.next}
            previousPageUrl={products.previous}
            countPagination={products.count}
            searchKeyObjectPagination={searchKeyObjectPagination}
            arrayOfSelectedProducts={arrayOfSelectedProducts}
            setArrayOfSelectedProducts={setArrayOfSelectedProducts}
            setRerenderProducts={setRerenderProducts}
          />
        )}
      </Card>
    </MDBox>
  );
}
