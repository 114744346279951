import BrandLogoCell from "../components/BrandLogoCell/index";
import ActionsCell from "../components/ActionsCell/index";
import StatusCell from "../components/StatusCell";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";
// Images

export const brandsHeaders = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Brand Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Logo",
    accessor: "image",
    Cell: ({ value }) => <BrandLogoCell image={value} />,
  },

  {
    Header: "Sort Order",
    accessor: "sortOrder",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Enabled",
    accessor: "enabled",
    Cell: ({ value }) => {
      let status;

      if (value) status = <StatusCell color="info" status="enabled" />;
      else status = <StatusCell color="error" status="disabled" />;
      return status;
    },
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
