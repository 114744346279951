import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";

import MDButton from "components/MDButton";

import FormField from "../../FormField";

import { UserContext } from "context/user";
import { FetchAdminAttributes } from "utils/apis/catalog/products/productsAttributes";
import MDBox from "components/MDBox";

const AttributesList = (props) => {
  const { onAttributesChange, defaultValues } = props;

  const [adminAttributes, setAdminAttributes] = useState([]);
  const [attributesSearchTerm, setAttributesSearchTerm] = useState(null);

  const [attributes, setAttributes] = useState([
    { productId: "", attributeId: "", text: "", language_id: 1 },
  ]);

  useEffect(() => {
    let newAttributesMap = defaultValues.map((attr) => {
      return {
        productId: "",
        attributeId: attr?.attribute_id,
        text: attr?.attributes_data?.value,
        language_id: 1,
        key: attr?.attributes_data?.key,
      };
    });

    //     onClick={() => {
    //   this.setState({
    //     persons: [{ name: 'Zulu', surname: 'Z' }, ...this.state.persons.slice(1)]
    //   });
    // }}

    if (defaultValues?.length) {
      setAttributes([...newAttributesMap]);
    }
  }, []);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        let searchKeyObject;
        if (!attributesSearchTerm) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              name: attributesSearchTerm,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }

        const result = await FetchAdminAttributes(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;
          const attributeItems = results?.map((attribute) => ({
            label: attribute.text[0].name,
            id: attribute.attribute_id,
          }));
          setAdminAttributes(attributeItems);
        }
      } catch (error) {
        console.error("Error fetching attribute groups:", error);
      }
    };

    fetchAttributes();
  }, [attributesSearchTerm]);

  const handleAttributesSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 3) {
      setAttributesSearchTerm(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setAttributesSearchTerm(null);
    }
  };

  // handle add attribute
  const handleAddAttribute = () => {
    const updatedAttributes = [
      ...attributes,
      { productId: "", attributeId: "", text: "", language_id: 1 },
    ];
    setAttributes(updatedAttributes);
    // lift the state up
    onAttributesChange(updatedAttributes);
  };

  // handle remove attribute
  const handleRemoveAttribute = (index) => {
    const updatedAttributes = [...attributes];
    updatedAttributes.splice(index, 1);
    setAttributes(updatedAttributes);

    // lift the state up
    onAttributesChange(updatedAttributes);
  };

  // handle attribute values change
  const handleChangeAttribute = (index, field, value) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index][field] = value;
    setAttributes(updatedAttributes);

    // lift the state up
    onAttributesChange(updatedAttributes);
  };

  console.log(attributes);

  return (
    <div>
      <List>
        {attributes.map((attribute, index) => (
          <ListItem key={index}>
            <ListItemText>
              <Grid container spacing={2}>
                {attribute?.key ? (
                  <>
                    <Grid item xs={12} sm={3}>
                      <FormField
                        label="Text"
                        value={attribute?.text}
                        onChange={(e) => handleChangeAttribute(index, "text", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* if this button code is deleted there is unknown error the first index not show key attribute */}
                      <button
                        onClick={() => {
                          console.log(attribute?.key);
                        }}
                        style={{ display: "none" }}
                      >
                        aa
                      </button>

                      {/* the state itself not changed it is
                       array of objects and the object not changed it
                        is just value inside it not address */}

                      <Autocomplete
                        value={attribute?.key}
                        disabled
                        options={adminAttributes}
                        onChange={(e, value) =>
                          handleChangeAttribute(index, "attributeId", value?.id)
                        }
                        onInputChange={handleAttributesSearch}
                        renderInput={(params) => (
                          <FormField label="Select Attribute" {...params} variant="standard" />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={3}>
                      <FormField
                        label="Text"
                        value={attribute.text}
                        onChange={(e) => handleChangeAttribute(index, "text", e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        // value={attribute.key ? attribute.key : null}
                        options={adminAttributes}
                        onChange={(e, value) =>
                          handleChangeAttribute(index, "attributeId", value?.id)
                        }
                        onInputChange={handleAttributesSearch}
                        renderInput={(params) => (
                          <FormField label="Select Attribute" {...params} variant="standard" />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </ListItemText>

            <ListItemSecondaryAction>
              <MDButton
                edge="end"
                color="error"
                onClick={() => handleRemoveAttribute(index)}
                size="small"
                sx={{ mx: 2 }}
              >
                Delete
              </MDButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <MDBox mt={2}>
        <MDButton color="info" onClick={handleAddAttribute}>
          Add
        </MDButton>
      </MDBox>
    </div>
  );
};

export default AttributesList;
