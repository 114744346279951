import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

// User context

import { UserContext } from "context/user";
import { useContext, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Get product attribute group by id

import { GetAdminAttributesGroupById } from "utils/apis/catalog/products/productsAttributesGroups";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";

function ProductAttributeGroupEdit() {
  const { id } = useParams();

  // old group
  const [attributesGroup, setAttributesGroup] = useState("");

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // use navigate hook

  const navigate = useNavigate();

  // handle inputs change
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "nameEn") {
      // change the first object (english).name to the value and keep the previuos state
      setAttributesGroup((prevState) => [{ ...prevState[0], name: value }, ...prevState.slice(1)]);
    } else if (name === "nameAr") {
      setAttributesGroup((prevState) => [
        // change the second object (arabic).name to the value and keep the previuos state
        ...prevState.slice(0, 1),
        { ...prevState[1], name: value },
        ...prevState.slice(2),
      ]);
    }
  };

  // handle create attributes group
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { nameEn, nameAr } = attributesGroup;

    // Reset validation messages
    clearErrorMSG();
    // Validate name a
    if (!nameEn || !nameAr) {
      return setErrorMSG("Please fill all the fields");
    }

    // /// send post attributes group
    const result = await PostAdminProductsAttributesGroup([
      { language_id: 1, name: nameEn },
      { language_id: 2, name: nameAr },
    ]);

    if (result.status === 200) {
      // show success notification
      setSuccessMSG("Group Created successfuly");
      // navigate to delivery costs list page
      navigate("/ecommerce/products/attributes");
    } else {
      setErrorMSG("Something went wrong");
    }
  };

  useEffect(() => {
    if (id) {
      const getAttributesGroupById = async () => {
        setLoadingOrNot(true);

        const result = await GetAdminAttributesGroupById(user, id);

        setLoadingOrNot(false);

        if (!(result instanceof Error)) {
          if (!result.data.error) {
            const { results } = result.data;
            results.length > 0 ? setAttributesGroup(results) : setAttributesGroup("");
            return;
          } else {
            return setErrorMSG(result.data.error);
          }
        } else {
          return setErrorMSG(result.message);
        }
      };

      getAttributesGroupById();
    }
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={8}>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={2}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Edit Attributes Group
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Card>
                {attributesGroup ? (
                  <MDBox p={3}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Edit Attributes Group
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={attributesGroup ? attributesGroup[0]?.name : ""}
                        name="nameEn"
                        sx={{ width: 400 }}
                        label="English Name"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={attributesGroup ? attributesGroup[1]?.name : ""}
                        name="nameAr"
                        sx={{ width: 400 }}
                        label="Arabic Name"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>

                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox component="img" alt="Not Found" src={NotFound404} />
                  </MDBox>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductAttributeGroupEdit;
