import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";

import { FetchHomeSections, DeleteAdminSection } from "utils/apis/home_section/home";
import { NotificationsContext } from "context/notifications";
import { useState, useEffect, useContext } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

import YesOrNoDialog from "elements/YesOrNoDialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

function HomeSection() {
  const navigate = useNavigate();
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [reRenderHomesections, setRerenderHomesections] = useState(false);

  const handleClickOpen = (id) => {
    setActiveId(id);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteSection = async () => {
    const result = await DeleteAdminSection(activeId);
    // this is a valid response
    if (!(result instanceof Error)) {
      setSuccessMSG("section Deleted Successfuly");
      handleClose();
      setRerenderHomesections((prev) => !prev);
    } else {
      return setErrorMSG(result.message);
    }
  };

  const [homeSections, setHomeSections] = useState();
  // Fetch the home sections
  useEffect(() => {
    const fetchHomeSections = async () => {
      try {
        const response = await FetchHomeSections();

        if (response.data) {
          setHomeSections(response.data?.results);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchHomeSections();
  }, [reRenderHomesections]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox className="home-section">
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDTypography variant="h3">Home Sections</MDTypography>
          <MDBox display="flex">
            <Link to="/dashboards/home-sections/create">
              <MDButton variant="gradient" color="info">
                Add New Section
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
        {!homeSections ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          homeSections.map((section) => (
            <MDBox
              key={section.id}
              display="flex"
              sx={{
                marginInline: 4,
                marginBlock: 2,
                border: "3px solid black",
                borderRadius: "10px",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              <MDBox sx={{ padding: 2, width: "25%" }} className="home-section-info-container">
                <MDTypography variant="h4" mt={2}>
                  {section.section_title?.[1]?.title}
                </MDTypography>
                <MDTypography variant="h6" sx={{ lineHeight: "1", paddingInlineStart: 0.5 }}>
                  {section.section_title?.[1]?.sub_title}
                </MDTypography>
                <MDTypography variant="subtitle1">id : {section.id}</MDTypography>
                <MDTypography variant="subtitle1">order id : {section.order_id}</MDTypography>
                <MDTypography variant="subtitle1">filter id : {section.filter_id}</MDTypography>
                <MDTypography variant="subtitle1">type : {section.type}</MDTypography>
                <MDTypography variant="subtitle1">
                  limit : {section.section_products_limit}
                </MDTypography>
              </MDBox>
              <MDBox
                sx={{
                  backgroundImage: `url(${section.section_background})`,
                  minHeight: 180,
                  width: "75%",
                  position: "relative",
                }}
              >
                <MDBox sx={{ position: "absolute", top: 8, right: 8 }}>
                  <HighlightOffIcon
                    sx={{ cursor: "pointer" }}
                    color="primary"
                    fontSize="medium"
                    onClick={() => handleClickOpen(section.id)}
                  />
                  <Link to={`/dashboards/home-sections/edit/${section.id}`}>
                    <EditIcon sx={{ cursor: "pointer" }} fontSize="medium" />
                  </Link>
                </MDBox>
              </MDBox>

              {/* <MDBox sx={{ position: "relative" }}>
                <MDBox
                  component="img"
                  src={section.section_background}
                  alt="section image"
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  height="200px"
                  minHeight="7rem"
                  sx={{ objectFit: "cover" }}
                />
                <HighlightOffIcon
                  sx={{ position: "absolute", top: 8, right: 8, cursor: "pointer" }}
                  color="primary"
                  fontSize="large"
                />
              </MDBox> */}
            </MDBox>
          ))
        )}
      </MDBox>
      <YesOrNoDialog
        heading="Are you sure you want to delete this section !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteSection}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default HomeSection;
