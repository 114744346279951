import { useState } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Stack from "@mui/material/Stack";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ProductImages({ images }) {
  const defaultImageToView = images?.length >= 1 ? images[0]?.image : null;
  const [currentImage, setCurrentImage] = useState(defaultImageToView);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  const imagesArr =
    images?.length >= 1
      ? images.map((image) => {
          return {
            src: image.image,
          };
        })
      : false;

  return (
    <MDBox>
      {imagesArr ? (
        <>
          <ImgsViewer
            imgs={imagesArr}
            isOpen={imgsViewer}
            onClose={closeImgsViewer}
            currImg={imgsViewerCurrent}
            onClickPrev={imgsViewerPrev}
            onClickNext={imgsViewerNext}
            backdropCloseable
          />
          <MDBox
            component="img"
            src={currentImage}
            alt="Product Image"
            shadow="lg"
            borderRadius="lg"
            width="100%"
            onClick={openImgsViewer}
          />
          <MDBox mt={2} pt={1}>
            <Stack direction="row" spacing={3}>
              {imagesArr.length &&
                imagesArr.map((image, index) => {
                  // show only 5 images
                  if (index < 5) {
                    return (
                      <MDBox
                        key={index}
                        component="img"
                        id={index}
                        src={image.src}
                        alt="small image 2"
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="5rem"
                        minHeight="5rem"
                        sx={{ cursor: "pointer", objectFit: "cover" }}
                        onClick={handleSetCurrentImage}
                      />
                    );
                  }
                })}
            </Stack>
          </MDBox>
        </>
      ) : (
        <MDTypography>Product has no Images</MDTypography>
      )}
    </MDBox>
  );
}
export default ProductImages;
