import MDBox from "components/MDBox";

import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

const ActionsCell = ({ id }) => {
  // ** id is the category id **

  return (
    <MDBox>
      <Link to={`/catalog/categories/details/${id}`}>
        <Icon color="success" title="preview" fontSize="medium" sx={{ mx: 2, cursor: "pointer" }}>
          preview
        </Icon>
      </Link>
      <Link to={`/catalog/categories/edit/${id}`}>
        <Icon color="info" fontSize="medium">
          edit
        </Icon>
      </Link>
    </MDBox>
  );
};

export default ActionsCell;
