import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";

import {
  GetAdminPointsCouponById,
  EditAdminPointsCoupon,
} from "utils/apis/ecommerce/pointsCoupons";

import { useParams, useNavigate } from "react-router-dom";

function PointsCouponEdit() {
  const { id } = useParams();

  // old points coupon

  const [pointsCouponToUpdate, setPointsCouponToUpdate] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    if (id) {
      const GetPointsCoupon = async () => {
        setLoadingOrNot(true);
        const result = await GetAdminPointsCouponById(user, id);
        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            // set state of coupons
            const { name, points_needed, discount, days_to_expire_after_added } = result.data;
            return setPointsCouponToUpdate({
              name,
              points_needed,
              discount,
              days_to_expire_after_added,
            });
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };

      GetPointsCoupon();
    }
  }, [id]);

  // use navigate hook

  const navigate = useNavigate();

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPointsCouponToUpdate({ ...pointsCouponToUpdate, [name]: value });
  };

  // // handle update points coupon submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, points_needed, discount, days_to_expire_after_added } = pointsCouponToUpdate;

    // Reset validation messages
    clearErrorMSG();

    // Validate name and points needed and discount values
    if (!name || !points_needed || !discount) {
      return setErrorMSG("Please fill all the fields");
    }

    /// send put points coupon request
    const result = await EditAdminPointsCoupon(user, id, {
      name,
      points_needed,
      discount,
      days_to_expire_after_added,
    });

    //  this is not an error
    if (!(result instanceof Error)) {
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Coupon updated successfuly");
        // navigate to points coupons list page
        navigate("/ecommerce/points-coupons");
      } else {
        // backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // response error
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={8}>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Edit Points Coupon
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Card>
                {pointsCouponToUpdate ? (
                  <MDBox p={3}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Edit Points Coupon
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        defaultValue={pointsCouponToUpdate.name}
                        name="name"
                        fullWidth
                        label="Name"
                        onChange={handleChange}
                        type="text"
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        defaultValue={pointsCouponToUpdate.points_needed}
                        name="points_needed"
                        fullWidth
                        label="Points Needed"
                        onChange={handleChange}
                        type="number"
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        defaultValue={pointsCouponToUpdate.discount}
                        name="discount"
                        fullWidth
                        label="Discount"
                        onChange={handleChange}
                        type="number"
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        defaultValue={pointsCouponToUpdate.days_to_expire_after_added}
                        name="days_to_expire_after_added"
                        fullWidth
                        label="Days to Expire (Leave it blank will never expire)"
                        onChange={handleChange}
                        type="number"
                      />
                    </MDBox>

                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox component="img" alt="Not Found" src={NotFound404} />
                  </MDBox>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default PointsCouponEdit;
