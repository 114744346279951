// @mui material components
import { Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import AttributesList from "./AttributesList.js";

function OtherStep(props) {
  const { localState, setParentState } = props;

  // if event change was "select" for the autocomplete component =>  set the state
  const handleChange = (_, value, reason) => {
    if (reason === "selectOption") {
      if (value) {
        setParentState((previouseState) => {
          return {
            ...previouseState,
            otherStep: {
              ...previouseState.otherStep,
              status: value,
            },
          };
        });
      }
    }

    // if event change was  "clear" for the autocomplete component =>  clear the state
    if (reason === "clear") {
      setParentState((previouseState) => {
        return {
          ...previouseState,
          otherStep: {
            ...previouseState.otherStep,
            status: "",
          },
        };
      });
    }
  };

  const handleAttributesChange = (productAttributes) => {
    setParentState((previousState) => {
      const validAttributes = productAttributes.filter(
        (attribute) => attribute.text && attribute.attributeId
      );

      return {
        ...previousState,
        otherStep: {
          ...previousState.otherStep,
          attributes: validAttributes,
        },
      };
    });
  };

  return (
    <MDBox mt={4}>
      <MDTypography variant="h5" fontWeight="bold">
        Status
      </MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              value={localState.status ? localState.status : null}
              onChange={handleChange}
              options={["FEATURED", "PROMOTED", "NEW", "DISCOUNT"]}
              renderInput={(params) => (
                <MDInput label="Product Status" {...params} variant="standard" />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDTypography mt={4} variant="h5" fontWeight="bold">
        Attributes
      </MDTypography>

      <MDBox mt={2}>
        <AttributesList
          onAttributesChange={handleAttributesChange}
          defaultValues={localState.attributes?.length ? localState.attributes : []}
        />
      </MDBox>
    </MDBox>
  );
}

export default OtherStep;
