import MDBox from "components/MDBox";

import { Link } from "react-router-dom";

import { Icon } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";

const ActionsCell = ({ id, user }) => {
  // ** id is the user id **

  return (
    <MDBox>
      <Link to={`/users/details/${id}`}>
        <PreviewIcon color="success" fontSize="medium" />
      </Link>
    </MDBox>
  );
};

export default ActionsCell;
