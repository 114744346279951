import React, { createContext, useReducer } from "react";

const SET_BRANDS_ACTIONS = {
  SET_BRANDS: "SET_BRANDS",
  CLEAR_BRANDS: "CLEAR_BRANDS",
};

// Initial state of the brands context
const initialState = {
  brands: null,
};

// Brands context reducer function
const brandsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_BRANDS_ACTIONS.SET_BRANDS:
      return { ...state, brands: payload };
    case SET_BRANDS_ACTIONS.CLEAR_BRANDS:
      return initialState;
    default:
      return state;
  }
};

// Create the brands context
const BrandsContext = createContext();

// brands context provider component
const BrandsContextProvider = ({ children }) => {
  const [{ brands }, dispatch] = useReducer(brandsReducer, initialState);

  // Action to set the brands context
  const setBrands = (brands) => {
    dispatch({ type: SET_BRANDS_ACTIONS.SET_BRANDS, payload: brands });
  };

  // Action to clear the brands context
  const clearBrands = () => {
    dispatch({ type: SET_BRANDS_ACTIONS.CLEAR_BRANDS });
  };

  const value = { brands, setBrands, clearBrands };
  return <BrandsContext.Provider value={value}>{children}</BrandsContext.Provider>;
};

export { BrandsContext, BrandsContextProvider };
