import axiosInstance from "utils/axiosInstance";

import contentTypes from "utils/contentTypes.json";

const AuditLogsRoute = "audit-logs";

export const GetHistoryOfOrderStatusAndComment = async (orderId, page = AuditLogsRoute) => {
  const objectReprParam = `OcOrder object (${orderId})`;

  const response = await axiosInstance.get(page, {
    params: { resource_type: contentTypes.ocorder, object_repr: objectReprParam },
  });

  return response;
};

export const GetHistoryOfProduct = async (productId, page = AuditLogsRoute) => {
  const objectReprParam = `OcProduct object (${productId})`;

  const response = await axiosInstance.get(page, {
    params: { resource_type: contentTypes.ocproduct, object_repr: objectReprParam },
  });

  return response;
};

export const GetRewardPointsHistory = async (customerMembershipId, page = AuditLogsRoute) => {
  const objectReprParam = `OcCustomerMembership ID: ${customerMembershipId}`;

  const response = await axiosInstance.get(page, {
    params: { resource_type: contentTypes.occustomermembership, object_repr: objectReprParam },
  });

  return response;
};

export const GetHistoryOfCoupon = async (couponId, page = AuditLogsRoute) => {
  const objectReprParam = `OcCoupon object (${couponId})`;

  const response = await axiosInstance.get(page, {
    params: { resource_type: contentTypes.occoupon, object_repr: objectReprParam },
  });

  return response;
};
