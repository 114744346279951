import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DifferenceIcon from "@mui/icons-material/Difference";
import IconButton from "@mui/material/IconButton";
import { PostAdminProduct } from "utils/apis/catalog/products/products";
import { AddProductAttributes } from "utils/apis/catalog/products/productsAttributes";
import { useState } from "react";

const ActionsCell = ({ id, product, setRerenderProducts }) => {
  const [successMSG, setSuccessMSG] = useState("");
  const [errorMSG, setErrorMSG] = useState("");

  const handleDuplicate = async () => {
    const {
      product_id,
      description,
      manufacturer_id,
      // brand_name,
      model,
      price,
      discounted_price,
      available_quantity,
      weight,
      width,
      height,
      length,
      status,
      categories,
      product_attributes,
      discount_start_date,
      discount_expiry_date,
      points,
    } = product;

    const productBody = {
      model,
      available_quantity,
      price,
      discounted_price,
      discount_start_date,
      discount_expiry_date,
      points,
      weight,
      length,
      width,
      height,
      status,
      categories: categories.map((category) => category.category_id),
      manufacturer_id,
      description: [
        {
          name: description?.[0]?.name,
          description: description?.[0]?.description,
          language_id: 1,
        },
        {
          name: description?.[1]?.name,
          description: description?.[1]?.description,
          language_id: 2,
        },
      ],
    };

    const result = await PostAdminProduct(productBody);

    if (result.status === 201) {
      const reformedAttributes = product_attributes.map((attributeWithGroup) => ({
        text: attributeWithGroup?.attributes_data?.value,
        product_id: result?.data?.product_id,
        attribute_id: attributeWithGroup?.attribute_id,
        language_id: 1,
      }));

      const addAttributesResult = await AddProductAttributes(reformedAttributes);

      if (addAttributesResult?.status === 201) {
        setRerenderProducts((prev) => !prev);
        // show success notification
        setSuccessMSG("Product added successfully");
        // navigate to products list page
        // navigate("/catalog/products");
      }
    } else {
      setErrorMSG("Something went wrong");
    }
  };

  return (
    <Stack direction="row" spacing={0.8} alignItems="center">
      <Link to={`/catalog/products/details/${id}`}>
        <PreviewIcon color="success" fontSize="medium" />
      </Link>
      <Link to={`/catalog/products/edit/${id}`}>
        <EditIcon color="info" fontSize="medium" />
      </Link>
      <IconButton
        onClick={handleDuplicate}
        color="warning"
        fontSize="medium"
        sx={{ cursor: "pointer" }}
      >
        <DifferenceIcon />
      </IconButton>
    </Stack>
  );
};

export default ActionsCell;
