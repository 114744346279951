// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { pointsCouponsHeaders } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils
import { PointsCouponsContext } from "context/pointsCoupons";
import { UserContext } from "context/user";
import { useContext, useEffect } from "react";
import { FetchAdminPointsCoupons } from "utils/apis/ecommerce/pointsCoupons";
import { Link } from "react-router-dom";

import { displayBasicDate } from "utils/methods";

import CircularProgress from "@mui/material/CircularProgress";

function PointsCouponsList() {
  // PointsCoupons State
  const { pointsCoupons, setPointsCoupons } = useContext(PointsCouponsContext);
  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the Points Coupons
  useEffect(() => {
    const fetchPointsCoupons = async () => {
      const result = await FetchAdminPointsCoupons(user);
      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const { count, next, previous, results } = result.data;
          return setPointsCoupons({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    fetchPointsCoupons();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/ecommerce/points-coupons/create"}>
            <MDButton variant="gradient" color="info">
              New Points Coupon
            </MDButton>
          </Link>
          <MDBox display="flex">
            {/* <MDButton variant={menu ? "contained" : "outlined"} color="dark">
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton> */}
            {/* {renderMenu} */}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          {!pointsCoupons?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {pointsCoupons?.data && (
            <DataTable
              table={{
                columns: pointsCouponsHeaders,
                rows: pointsCoupons.data.map((pointsCoupon) => ({
                  id: pointsCoupon.id,
                  name: pointsCoupon.name,
                  points_needed: pointsCoupon.points_needed,
                  discount: pointsCoupon.discount,
                  days_to_expire: pointsCoupon.days_to_expire_after_added,
                  created_at: pointsCoupon.created_at
                    ? displayBasicDate(pointsCoupon.created_at)
                    : "/",
                  actions: pointsCoupon.id,
                })),
              }}
              nextPageUrl={pointsCoupons.next}
              previousPageUrl={pointsCoupons.previous}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PointsCouponsList;
