// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// ProductPage page components
import ProductImages from "./components/ProductImages";
import ProductInfo from "./components/ProductInfo";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";

import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { FetchAdminProductById } from "utils/apis/catalog/products/products";

import CircularProgress from "@mui/material/CircularProgress";
import NotFound404 from "assets/images/404.gif";

import ProductAttribute from "./components/ProductAttribute";

function ProductDetails() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  // in case of 404
  const [notfoundMSG, setNotFoundMSG] = useState("");
  const [product, setProduct] = useState(null);
  const [loadingOrNot, setLoadingOrNot] = useState(false);
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);
  const [product_attributes, setProduct_attributes] = useState([]);

  const makeTabelOfProductAttributes = (product_attributes) => {
    if (product_attributes.length) {
      const newArrayOfAttributesGroup = [];
      product_attributes.forEach((attribute) => {
        const checkIfAttributeExist = newArrayOfAttributesGroup.find(
          (newAttrGroup) =>
            attribute.attributes_data.attributes_group_id === newAttrGroup?.attributes_group_id
        );

        if (checkIfAttributeExist) {
          checkIfAttributeExist.attributes = [
            ...checkIfAttributeExist.attributes,
            {
              attribute_id: attribute.attribute_id,
              key: attribute.attributes_data.key,
              value: attribute.attributes_data.value,
            },
          ];
        } else {
          newArrayOfAttributesGroup.push({
            attributes_group: attribute.attributes_data.attributes_group,
            attributes_group_id: attribute.attributes_data.attributes_group_id,
            attributes: [
              {
                attribute_id: attribute.attribute_id,
                key: attribute.attributes_data.key,
                value: attribute.attributes_data.value,
              },
            ],
          });
        }
      });
      console.log(newArrayOfAttributesGroup);
      setProduct_attributes(newArrayOfAttributesGroup);
    }
  };

  console.log(product);

  useEffect(() => {
    if (id) {
      const fetchProduct = async () => {
        setLoadingOrNot(true);
        const result = await FetchAdminProductById(id);
        setLoadingOrNot(false);
        if (!(result instanceof Error)) {
          if (!result.data.error && result.data) {
            makeTabelOfProductAttributes(result.data.product_attributes);
            return setProduct(result.data);
          } else {
            return setErrorMSG(result.data.error);
          }
        } else {
          return setErrorMSG(result.message);
        }
      };

      fetchProduct();
    }
  }, [id]);

  return (
    <>
      <MDBox py={1}>
        {loadingOrNot ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <>
            <Card sx={{ overflow: "visible" }}>
              {product && loadingOrNot === false ? (
                <MDBox p={3}>
                  <MDBox mb={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Product Details
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} xl={5}>
                      <ProductImages images={product.images} />
                    </Grid>
                    <Grid item xs={12} lg={5.5} sx={{ mx: "auto" }}>
                      <ProductInfo product={product} />
                    </Grid>
                  </Grid>
                </MDBox>
              ) : (
                !product && (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDTypography sx={{ textAlign: "center", fontWeight: "bold" }}>
                      {notfoundMSG}
                    </MDTypography>
                    <MDBox component="img" alt="category picture" src={NotFound404} />
                  </MDBox>
                )
              )}
            </Card>
            <Card sx={{ overflow: "visible", marginTop: 3 }}>
              <MDBox p={3}>
                <ProductAttribute product_attributes={product_attributes} />
              </MDBox>
            </Card>
          </>
        )}
      </MDBox>
    </>
  );
}

export default ProductDetails;
