// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState, useContext } from "react";

// Images
import pattern from "assets/images/illustrations/pattern-tree.svg";
import masterCardLogo from "assets/images/logos/mastercard.png";

import YesOrNoDialog from "elements/YesOrNoDialog";
import { DeleteRankRequest } from "utils/apis/users/users-ranking";
import { NotificationsContext } from "context/notifications";

const num1 = "###";
const num2 = "###";
const num3 = "###";
const num4 = "###";

function UserRankCard({ color, rankName, minRange, maxRange, id, setRanks, ranks }) {
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteRank = async () => {
    console.log("in function");
    const result = await DeleteRankRequest(id);
    console.log(result);
    // this is a valid response
    if (!(result instanceof Error)) {
      if (result?.status) {
        const filteredRanks = ranks.filter((rank) => rank?.id != id);
        setRanks(filteredRanks);
        setOpenDialog(false);

        setSuccessMSG("Rank Deleted Successfuly");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };
  return (
    <>
      <Card
        sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
          background: gradients[color]
            ? linearGradient(gradients[color].main, gradients[color].state)
            : linearGradient(gradients.dark.main, gradients.dark.state),
          boxShadow: xl,
          position: "relative",
        })}
      >
        <MDBox
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          opacity={0.2}
          sx={{
            backgroundImage: `url(${pattern})`,
            backgroundSize: "cover",
          }}
        />
        <MDBox position="relative" zIndex={2} p={2}>
          <MDBox
            color="white"
            p={1}
            lineHeight={0}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Icon fontSize="default">wifi</Icon>
            <MDBox p={1} lineHeight={0}>
              <Icon
                fontSize="default"
                sx={{ color: "red", cursor: "pointer" }}
                onClick={handleClickOpen}
              >
                delete
              </Icon>
              <Icon fontSize="default" sx={{ color: "blue", cursor: "pointer" }}>
                edit
              </Icon>
            </MDBox>
          </MDBox>
          <MDTypography variant="h5" color="white" fontWeight="medium" sx={{ mt: 3, mb: 5, pb: 1 }}>
            {num1}&nbsp;&nbsp;&nbsp;{num2}&nbsp;&nbsp;&nbsp;{num3}&nbsp;&nbsp;&nbsp;{num4}
          </MDTypography>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox display="flex" alignItems="center">
              <MDBox mr={3} lineHeight={1}>
                <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                  Rank Name
                </MDTypography>
                <MDTypography
                  variant="h6"
                  color="white"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {rankName}
                </MDTypography>
              </MDBox>
              <MDBox lineHeight={1}>
                <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                  Range
                </MDTypography>
                <MDTypography variant="h6" color="white" fontWeight="medium">
                  {minRange} - {maxRange}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" width="20%">
              <MDBox component="img" src={masterCardLogo} alt="master card" width="60%" mt={1} />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <YesOrNoDialog
        heading="Are you sure you want to delete this rank !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteRank}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
    </>
  );
}

// Setting default values for the props of MasterCard
UserRankCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the MasterCard
UserRankCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  rankName: PropTypes.string.isRequired,
  minRange: PropTypes.string.isRequired,
  maxRange: PropTypes.string.isRequired,
};

export default UserRankCard;
