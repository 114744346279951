import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Post Points Coupon method

import { PostAdminPointsCoupon } from "utils/apis/ecommerce/pointsCoupons";

import "app.css";

function PointsCouponCreate() {
  const defualtFormFields = {
    name: "",
    points_needed: "",
    discount: "",
    days_to_expire_after_added: "",
  };

  const [formFields, setFormFields] = useState(defualtFormFields);

  const { name, points_needed, discount, days_to_expire_after_added } = formFields;

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // // handle create points coupon submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate name and points needed and discount values
    if (!name || !points_needed || !discount) {
      return setErrorMSG("Please fill all the fields");
    }

    /// send post points coupon request
    const result = await PostAdminPointsCoupon(user, {
      name: name ? name : "",
      points_needed,
      discount,
      days_to_expire_after_added,
    });

    //  this is not an error
    if (!(result instanceof Error)) {
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Coupon created successfuly");
        // navigate to points coupons list page
        navigate("/ecommerce/points-coupons");
      } else {
        // backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // repsonse error
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center">
              <MDBox mb={3}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Points Coupon
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New Points Coupon
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    value={name}
                    name="name"
                    fullWidth
                    label="Name"
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    value={points_needed}
                    name="points_needed"
                    fullWidth
                    label="Points Needed"
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    value={discount}
                    name="discount"
                    fullWidth
                    label="Discount"
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    value={days_to_expire_after_added}
                    name="days_to_expire_after_added"
                    fullWidth
                    label="Days to Expire (Leave it blank will never expire)"
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PointsCouponCreate;
