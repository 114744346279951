import React, { useContext, useState } from "react";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Stack from "@mui/material/Stack";
import MDBox from "components/MDBox";

import { ChangePasswordRequest } from "utils/apis/users/users";
import { NotificationsContext } from "context/notifications";
import InputFileAndPreview from "elements/InputFileAndPreview";

import { AddImageToProduct } from "utils/apis/catalog/products/products";

export default function AddImageDialog({ open, handleClose, product_id, setRerender }) {
  const [imageFile, setImageFile] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    // const req = await AddImageToProduct(product_id, file);

    // setImages((pre) => [...pre, URL.createObjectURL(file)]);
    setImageFile(file);
  };

  // const handelUploadImage = async () => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(imageFile);

  //   reader.onload = async () => {
  //     const imageData = reader.result; // This is the base64-encoded string of the image data
  //     const imageDate = { product_id, sort_id: [sortOrder], images: [imageData] };

  //     try {
  //       const req = await AddImageToProduct(imageDate);
  //       setRerender((prev) => !prev);
  //     } catch (error) {}
  //   };

  //   reader.onerror = (error) => {};
  // };

  const handelUploadImage = async () => {
    const imageData = {
      product_id,
      sort_id: [sortOrder],
      images: [imageFile],
    };

    const req = await AddImageToProduct(imageData);
    setRerender((prev) => !prev);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="add-images-dialog">add image to product ::</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ width: "500px !important", marginTop: 2 }}>
          <InputFileAndPreview handleImageUpload={handleFileChange} file={imageFile} />
          <MDBox>
            <MDInput
              fullWidth
              label="sort_order"
              onChange={(e) => setSortOrder(e.target.value)}
              type="number"
              value={sortOrder}
            />
          </MDBox>
        </Stack>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose}>close</MDButton>
        <MDButton variant="gradient" color="info" onClick={handelUploadImage}>
          upload
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
