import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";
import { ProductsAttributesGroupsContext } from "context/products/productsAttributesGroups";

// Post product attribute group
import { PostAdminAttributes } from "utils/apis/catalog/products/productsAttributes";
import { FetchAdminProductsAttributesGroups } from "utils/apis/catalog/products/productsAttributesGroups";

function AttributeCreate() {
  // defualt state of attributes group
  const defualtFormFields = {
    nameEn: "",
    nameAr: "",
    attributeGroupId: "",
  };

  const [attribute, setAttribute] = useState(defualtFormFields);
  const [attributeGroups, setAttributeGroup] = useState(null);

  const handleGroupChange = (_, value, reason) => {
    // if event was selecting from list
    if (reason === "selectOption") {
      setAttribute((prev) => ({ ...prev, attributeGroupId: value.attribute_group_id }));
    } else {
      setAttribute((prev) => ({ ...prev, attributeGroupId: null }));
    }

    // clear the state if the user cleard the input by icon or by removeing text
    // if (no category search key) => that means user selecting from default pages.
    if (reason === "clear") {
      setAttribute((prev) => ({ ...prev, attributeGroupId: null }));
    }
  };

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // handle inputs change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAttribute((prev) => ({ ...prev, [name]: value }));
  };

  // handle create attributes group
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { nameEn, nameAr, attributeGroupId } = attribute;

    // Reset validation messages
    clearErrorMSG();
    // Validate name a
    console.log(nameEn, nameAr, attributeGroupId);
    if (!nameEn || !nameAr || attributeGroupId == null || attributeGroupId == undefined) {
      return setErrorMSG("Please fill all the fields");
    }

    const sendingData = {
      attributes: [
        { language_id: 1, name: nameEn },
        { language_id: 2, name: nameAr },
      ],
      attribute_group_id: attributeGroupId,
    };

    // /// send post attributes group
    const result = await PostAdminAttributes(sendingData);

    if (!(result instanceof Error)) {
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Group Created successfuly");
        // navigate to delivery costs list page
        navigate("/catalog/products/attributes-group");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  useEffect(() => {
    const fetchProductsAttributesGroups = async () => {
      const result = await FetchAdminProductsAttributesGroups();

      if (!(result instanceof Error)) {
        if (!result.data.error && result.data) {
          const { results } = result.data;

          // map tha attribute Groups as auto complete item
          const attributeGroupsItems = results?.map((group) => ({
            label: group.text[0].name,
            attribute_group_id: group.attribute_group_id,
          }));

          setAttributeGroup(attributeGroupsItems);
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    };

    fetchProductsAttributesGroups();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mb={2}>
              <MDTypography variant="h3" fontWeight="bold">
                Create Attributes
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create Attributes
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="nameEn"
                    sx={{ width: 400 }}
                    label="English Name"
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="nameAr"
                    sx={{ width: 400 }}
                    label="Arabic Name"
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={3}>
                  <Autocomplete
                    getOptionSelected={(option, value) =>
                      option.attribute_group_id === value.attribute_group_id
                    }
                    // value={localState.categories.length ? localState.categories[0] : null}
                    options={attributeGroups ? attributeGroups : []}
                    renderInput={(params) => (
                      <MDInput
                        label="select group"
                        {...params}
                        variant="standard"
                        sx={{ width: 400 }}
                      />
                    )}
                    onChange={handleGroupChange}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AttributeCreate;
