import React, { useEffect } from "react";
import { useState } from "react";

import ReactToPrint from "react-to-print";

import { useContext, useRef } from "react";

import { Card, CardHeader, CardContent, Grid, Stack, Box, Typography, Paper } from "@mui/material";

import {
  EditAdminOrderStatus,
  EditAdminOrderComment,
  EditAdminOrderStatusAndComment,
} from "utils/apis/sales/orders";
import { convertStatusIdToString } from "utils/methods";
import { NotificationsContext } from "context/notifications";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import HistoryOfStatus from "./HistoryOfStatus";

import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";

import { OrderItem } from "./OrderItem";
import MDBox from "components/MDBox";
import { displayBasicDate } from "utils/methods";
import AddModal from "./AddModal";
import { Link } from "react-router-dom";

const OrderDetailsCard = (props) => {
  const { orderProducts, orderDetails, setOrderProductUpdated, setReRenderOrderDetails } = props;
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);
  const printRef = useRef();
  const [menu, setMenu] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [orderStatusId, setOrderStatusId] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [orderComment, setOrderComment] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const handleStatusChange = (val) => {
    setOrderStatusId(val);
    setSelectedStatus(convertStatusIdToString(val));

    closeMenu();
  };

  useEffect(() => {
    if (orderDetails) {
      const order_status_id = orderDetails?.order_status_id;
      setOrderStatusId(order_status_id);
      setSelectedStatus(convertStatusIdToString(order_status_id));
    }
  }, [orderDetails]);

  const handleChangeStatusSubmit = async () => {
    // Reset validation messages
    clearErrorMSG();
    console.log(orderDetails?.customer_notified_id, "AS");

    const result = await EditAdminOrderStatusAndComment(
      orderDetails.order_id,
      orderStatusId,
      orderComment,
      orderDetails?.customer_notified_id
    );

    if (!(result instanceof Error)) {
      // back end validation errors
      if (!result.data.error) {
        setSuccessMSG("order status updated successfuly");
        setReRenderOrderDetails((prev) => !prev);

        // navigate("/ecommerce/orders");
      } else {
        setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.response.data.error);
    }

    // const res = await EditAdminOrderComment(orderDetails.order_id, orderComment);
    setReRender((prev) => !prev);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => handleStatusChange(1)}>New Order</MenuItem>
      <MenuItem onClick={() => handleStatusChange(5)}>Completed</MenuItem>
      <MenuItem onClick={() => handleStatusChange(7)}>Cancelled Order</MenuItem>
      <MenuItem onClick={() => handleStatusChange(11)}>Refunded</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          cancel
        </MDTypography>
      </MenuItem>
    </Menu>
  );
  return (
    <>
      {!orderDetails ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "80vh",
          }}
        >
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <>
          <MDBox display="flex" justifyContent="space-between" mb={2}>
            <Link to={`/users/details/${orderDetails?.customer_id}`}>
              <MDButton>Go to this user</MDButton>
            </Link>
            <ReactToPrint
              bodyClass="print-agreement"
              content={() => printRef.current}
              trigger={() => (
                <MDButton variant="gradient" color="info">
                  print order invoice
                </MDButton>
              )}
            />
          </MDBox>

          <Card sx={{ border: "1px solid rgba(0,0,0,.125)", borderRadius: "0", boxShadow: "none" }}>
            <div ref={printRef} className="print-div">
              <CardHeader
                sx={{
                  backgroundColor: "rgba(0,0,0,.03)",
                  padding: "0.7692307692rem 1rem",
                }}
                title={
                  <div
                    style={{
                      fontSize: "0.899rem",
                      color: "#545454",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <ErrorIcon fontSize="small" /> <span> Order (#{orderDetails?.order_id})</span>
                  </div>
                }
              />
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <strong>Invoice</strong>
                        <br />
                        <span>order Id #{orderDetails?.order_id}</span>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <strong>Customer</strong>
                        <br />
                        <span>
                          {orderDetails?.payment_firstname} {orderDetails?.payment_lastname}
                        </span>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <strong>Date Added</strong>
                        <br />
                        <span>
                          {orderDetails?.date_added
                            ? displayBasicDate(orderDetails?.date_added)
                            : "no date"}
                        </span>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <strong>Payment Method</strong>
                        <br />
                        <span>{orderDetails?.payment_method}</span>
                        <br />
                        <strong>Payment Full Name</strong>
                        <br />
                        <span>
                          {orderDetails?.payment_firstname} {orderDetails?.payment_lastname}
                        </span>
                        <br />
                        {/* <strong>Payment City</strong>
                        <br />
                        <span>{orderDetails?.payment_city}</span> */}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <strong>Shipping City</strong>
                        <br />
                        <span>{orderDetails?.shipping_city}</span>
                        <br />
                        <strong>Shipping</strong>
                        <br />
                        <span>
                          {orderDetails?.shipping_address_1} , {orderDetails?.shipping_address_2}
                        </span>
                        <br />
                        <strong>Full Shipping Name</strong>
                        <br />
                        <span>
                          {orderDetails?.shipping_firstname} {orderDetails?.shipping_lastname}
                        </span>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <strong>Total</strong>
                        <br />
                        <span>{orderDetails?.total}</span>
                        <br />
                        <strong>Total After Discount</strong>
                        <br />
                        <span>{orderDetails?.total_after_discount}</span>
                        <br />
                        <strong>Total USD</strong>
                        <br />
                        <span>{orderDetails?.total_usd}</span>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>Payment Address</Typography>

                        <p>
                          <strong>Name :</strong> {orderDetails?.payment_firstname}{" "}
                          {orderDetails?.payment_lastname}
                        </p>

                        <p>
                          <strong>Address 1 :</strong> {orderDetails?.payment_address_1}
                        </p>

                        {/* <p>
                          <strong>Address 2 : </strong> {orderDetails?.payment_address_2}
                        </p> */}

                        <p>
                          <strong>City: </strong> {orderDetails?.payment_city}
                        </p>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#e9ecef",
                          fontSize: "1rem",
                          fontWeight: "300",
                          padding: "0.5385rem 0.7692rem",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>Shipping Address</Typography>

                        <p>
                          <strong>Name :</strong> {orderDetails?.shipping_firstname}{" "}
                          {orderDetails?.shipping_lastname}
                        </p>

                        <p>
                          <strong>Address 1 :</strong> {orderDetails?.shipping_address_1}
                        </p>

                        {/* <p>
                          <strong>Address 2 : </strong> {orderDetails?.shipping_address_2}
                        </p> */}

                        <p>
                          <strong>City: </strong> {orderDetails?.shipping_city}
                        </p>
                        <p>
                          <strong>phone number: </strong> {orderDetails?.customer_number}
                        </p>
                        <p>
                          <strong>alternative number: </strong>{" "}
                          {orderDetails?.shipping_custom_field}
                        </p>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <div class="pagebreak"> </div>
                {/* after this the order item will be displayed */}
                {orderProducts && orderProducts?.length > 0 ? (
                  <OrderItem
                    orderProducts={orderProducts}
                    total={orderDetails?.total}
                    total_usd={orderDetails?.total_usd}
                    coupon={orderDetails?.coupon}
                    total_after_discount={orderDetails?.total_after_discount}
                    delivery_costs={orderDetails?.delivery_costs}
                  />
                ) : (
                  <MDBox my={3} ml={2}>
                    <Typography>No Products In This Order</Typography>
                  </MDBox>
                )}
              </CardContent>
            </div>
          </Card>

          <Card sx={{ my: 2, p: 2 }}>
            <Stack direction="row" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" onClick={handleClickOpenDialog}>
                Change Products
              </MDButton>
            </Stack>
          </Card>

          <HistoryOfStatus
            orderId={orderDetails?.order_id}
            reRender={reRender}
            messageInfo={{
              customer_number: orderDetails?.customer_number,
              customerId: orderDetails?.customer_id,
              orderId: orderDetails?.order_id,
            }}
            userNotifiedArray={orderDetails?.customer_notified_id}
            setReRenderOrderDetails={setReRenderOrderDetails}
          />

          <MDBox component={Paper}>
            <MDInput
              label="Comment"
              variant="standard"
              fullWidth
              placeholder="Comment"
              InputLabelProps={{ shrink: true }}
              sx={{ maxWidth: "550px", my: 2 }}
              value={orderComment}
              onChange={(e) => {
                setOrderComment(e.target.value);
              }}
            />
            <MDBox display="flex">
              <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                {selectedStatus}&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu}
              <MDBox ml={1}>
                <MDButton
                  variant="outlined"
                  color="dark"
                  onClick={() => handleChangeStatusSubmit()}
                >
                  <Icon>rule_settings</Icon>
                  Change Status
                </MDButton>
              </MDBox>
            </MDBox>

            <AddModal
              open={openDialog}
              handleClose={handleCloseDialog}
              orderProducts={
                orderProducts?.length > 0
                  ? orderProducts.map((product) => ({
                      quantity: product.quantity,
                      model: product?.product.model,
                      product_id: product?.product.product_id,
                    }))
                  : []
              }
              setOrderProductUpdated={setOrderProductUpdated}
              orderId={orderDetails?.order_id}
            />
          </MDBox>
        </>
      )}
    </>
  );
};

export default OrderDetailsCard;
