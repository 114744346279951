import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

import ActionsCell from "../component/ActionsCell";
export const usersHeaders = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Phone",
    accessor: "username",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Is Active",
    accessor: "is_active",
    Cell: ({ value }) => <DefaultCell value={value.toString()} />,
  },

  {
    Header: "date joined",
    accessor: "dateJoined",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell user={value} id={value.id} />,
  },
];
