import axios from "axios";

const { create } = axios;

const axiosInstance = create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    common: {
      "Content-Type": "application/json",
    },
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const savedUserString = localStorage.getItem("user");
    const savedUser = JSON.parse(savedUserString);
    const token = savedUser?.token;

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;