import MDBox from "components/MDBox";

import { DeleteAdminProductsAttributesGroup } from "utils/apis/catalog/products/productsAttributesGroups";
import { useContext } from "react";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";
import { ProductsAttributesGroupsContext } from "context/products/productsAttributesGroups";
import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

import YesOrNoDialog from "elements/YesOrNoDialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Stack from "@mui/material/Stack";
import { useState } from "react";

//import BrandEdit from "layouts/catalog/brands/brand-edit";
//import { useNavigate } from "react-router-dom";

const ActionsCell = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  // ** id is the group id **

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  // // Accsss attributes groups to change the state after delete
  const { productsAttributesGroups, setProductsAttributesGroups } = useContext(
    ProductsAttributesGroupsContext
  );

  // // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  // // Delete Attributes group Action
  const handleDeleteProductAttributesGroup = async () => {
    const result = await DeleteAdminProductsAttributesGroup(user, id);

    if (!(result instanceof Error)) {
      if (!result.data.error && result.status === 204) {
        // delete it from ui
        const { count, next, previous, data } = productsAttributesGroups;
        const newGroups = data?.filter((group) => group.attribute_group_id != id);
        setProductsAttributesGroups({
          count,
          next,
          previous,
          data: newGroups,
        });

        // show deleted alert
        setSuccessMSG("Group Deleted Successfuly");
      } else {
        // show error alert
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <>
      <YesOrNoDialog
        heading="Are you sure you want to delete this attributes group !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteProductAttributesGroup}
        openDialog={openDialog}
        closeFunction={handleClose}
      />

      <Stack direction="row" spacing={1}>
        <HighlightOffIcon
          color="primary"
          fontSize="medium"
          onClick={handleClickOpen}
          sx={{ cursor: "pointer" }}
        />

        <Link to={`/catalog/products/attributes-group/edit/${id}`}>
          <Icon color="info" fontSize="medium">
            edit
          </Icon>
        </Link>
      </Stack>
    </>
  );
};

export default ActionsCell;
