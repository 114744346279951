import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import { useEffect, useState, useContext } from "react";
import Card from "@mui/material/Card";

import CircularProgress from "@mui/material/CircularProgress";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { fetchAdminUsersDetails } from "utils/apis/users/users";

import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";

import { useParams, useNavigate } from "react-router-dom";
import { NotificationsContext } from "context/notifications";

import MDButton from "components/MDButton";

import OrdersListComponent from "layouts/sales/orders/orders-list/OrdersListComponent";
import CouponEdit from "./CouponEdit";
import CouponHistory from "./CouponHistory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserDetails() {
  const { id } = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider", mb: 1, py: 2 }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
            <Tab label="coupon edit" {...a11yProps(0)} />
            <Tab label="Orders" {...a11yProps(1)} />
            <Tab label="History" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <MDBox>
          <CustomTabPanel value={value} index={0}>
            <CouponEdit />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <OrdersListComponent by_coupon={id} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <CouponHistory couponId={id} />
          </CustomTabPanel>
        </MDBox>
      </Box>
    </DashboardLayout>
  );
}
