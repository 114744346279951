// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProductEditComponent from "./ProductEditComponent";
import { useState } from "react";
import ProductImages from "./ProductImages";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProductsList() {
  const { id } = useParams();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider", mb: 1, py: 2 }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
            <Tab label="Basic Information" {...a11yProps(0)} />
            <Tab label="Images" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <MDBox>
          <CustomTabPanel value={value} index={0}>
            <ProductEditComponent />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ProductImages product_id={id} />
          </CustomTabPanel>
        </MDBox>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsList;
