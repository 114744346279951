import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";
import { EditAdminOrder } from "utils/apis/sales/orders";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Post brand method

import { useParams, useNavigate } from "react-router-dom";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";
import { OrdersContext } from "context/orders";

function OrderEdit() {
  const { orders, setOrders } = useContext(OrdersContext);
  const { id } = useParams();

  // old order

  const [order, setOrder] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    if (id) {
      const orderTabelDetails = orders?.data?.find((orderData) => orderData.order_id == id);
      setOrder(orderTabelDetails);
    }
  }, [id]);

  // use navigate hook

  const navigate = useNavigate();

  // handle change handler (general)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrder({ ...order, [name]: value });
  };

  const handleDateAddedChange = (_, value) => {
    setOrder((prevOrder) => ({ ...prevOrder, date_added: value }));
  };

  const handleDateModifiedChange = (_, value) => {
    setOrder((prevOrder) => ({ ...prevOrder, date_modified: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset validation messages
    clearErrorMSG();

    const result = await EditAdminOrder(id, order);

    if (!(result instanceof Error)) {
      // back end validation errors
      if (!result.data.error) {
        setSuccessMSG("order updated successfuly");

        navigate("/ecommerce/orders");
      } else {
        setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.response.data.error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={10}>
              <MDBox textAlign="center">
                <MDBox mb={2}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Edit Order
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Card>
                {order ? (
                  <MDBox p={3}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Edit order #{id}
                      </MDTypography>
                    </MDBox>
                    <Grid container spacing={2}>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping firstname"
                          onChange={handleChange}
                          type="text"
                          name="shipping_firstname"
                          value={order.shipping_firstname}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping lastname"
                          onChange={handleChange}
                          type="text"
                          name="shipping_lastname"
                          value={order.shipping_lastname}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping address_1"
                          onChange={handleChange}
                          type="text"
                          name="shipping_address_1"
                          value={order.shipping_address_1}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping address_2"
                          onChange={handleChange}
                          type="text"
                          name="shipping_address_2"
                          value={order.shipping_address_2}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping city"
                          onChange={handleChange}
                          type="text"
                          name="shipping_city"
                          value={order.shipping_city}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping postcode"
                          onChange={handleChange}
                          type="text"
                          name="shipping_postcode"
                          value={order.shipping_postcode}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="shipping custom field"
                          onChange={handleChange}
                          type="text"
                          name="shipping_custom_field"
                          value={order.shipping_custom_field}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment firstname"
                          onChange={handleChange}
                          type="text"
                          name="payment_firstname"
                          value={order.payment_firstname}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment lastname"
                          onChange={handleChange}
                          type="text"
                          name="payment_lastname"
                          value={order.payment_lastname}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment address 1"
                          onChange={handleChange}
                          type="text"
                          name="payment_address_1"
                          value={order.payment_address_1}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment address 2"
                          onChange={handleChange}
                          type="text"
                          name="payment_address_2"
                          value={order.payment_address_2}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment city"
                          onChange={handleChange}
                          type="text"
                          name="payment_city"
                          value={order.payment_city}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment postcode"
                          onChange={handleChange}
                          type="text"
                          name="payment_postcode"
                          value={order.payment_postcode}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment custom field"
                          onChange={handleChange}
                          type="text"
                          name="payment_custom_field"
                          value={order.payment_custom_field}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="payment method"
                          onChange={handleChange}
                          type="text"
                          name="payment_method"
                          value={order.payment_method}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="comment"
                          onChange={handleChange}
                          type="text"
                          name="comment"
                          value={order.comment}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDDatePicker
                          fullWidth
                          input={{
                            id: "date_added",
                            name: "date_added",
                            placeholder: "Select date_added",
                          }}
                          name="date_added"
                          value={order.date_added}
                          options={{
                            enableTime: true,
                            enableSeconds: true,
                          }}
                          onChange={handleDateAddedChange}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDDatePicker
                          fullWidth
                          input={{
                            id: "date_modified",
                            name: "date_modified",
                            placeholder: "Select date_modified",
                          }}
                          name="date_modified"
                          value={order.date_modified}
                          options={{
                            enableTime: true,
                            enableSeconds: true,
                          }}
                          onChange={handleDateModifiedChange}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="num products"
                          onChange={handleChange}
                          type="number"
                          value={order.num_products}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="num products"
                          onChange={handleChange}
                          type="number"
                          value={order.num_products}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <FormControl fullWidth>
                          <InputLabel id="order_status_label_id">order status</InputLabel>
                          <Select
                            value={order.order_status_id}
                            name="order_status_id"
                            onChange={handleChange}
                            labelId="order_status_label_id"
                            label="order status"
                            id="order_status_id"
                            fullWidth
                            sx={{
                              minWidth: 120,
                              lineHeight: "3rem",
                            }}
                          >
                            <MenuItem value={0}>Canceled</MenuItem>
                            <MenuItem value={1}>Paid</MenuItem>
                            <MenuItem value={2}>Refunded</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="total"
                          onChange={handleChange}
                          type="number"
                          value={order.total}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="total after discount"
                          onChange={handleChange}
                          type="number"
                          value={order.total_after_discount}
                        />
                      </Grid>
                      <Grid item md={6} lg={4}>
                        <MDInput
                          fullWidth
                          label="total usd"
                          onChange={handleChange}
                          type="number"
                          value={order.total_usd}
                        />
                      </Grid>
                    </Grid>
                    <MDBox display="flex" justifyContent="flex-end" mt={4}>
                      <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox component="img" alt="Not Found" src={NotFound404} />
                  </MDBox>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default OrderEdit;
