import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
export default function SectionTypeComponent({ formFields, setFormFields }) {
  const sectionTypeOptions = [
    { label: "PRODUCTS_BY_CATEGORY", selectedNum: 1 },
    { label: "PRODUCTS_BY_STATUS", selectedNum: 2 },
    { label: "PRODUCTS_BY_BRANDS", selectedNum: 3 },
    { label: "SUB_CATEGORIES", selectedNum: 4 },
    { label: "SUB_CATEGORY_AND_PRODUCTS", selectedNum: 5 },
  ];

  // handle select change
  const handleSectionTypeChange = (_, value) => {
    if (value) {
      setFormFields((prev) => {
        return {
          ...prev,
          sectionType: value.selectedNum,
        };
      });
    } else {
      setFormFields((prev) => {
        return {
          ...prev,
          sectionType: 1,
        };
      });
    }
  };

  const sectionTypeOptionsValue = useMemo(() => {
    const value = sectionTypeOptions.filter(
      (sectionType) => sectionType?.selectedNum == formFields?.sectionType
    );
    if (value?.length) {
      return value?.[0];
    }
    return null;
  }, [sectionTypeOptions, formFields.sectionType]);

  return (
    <Autocomplete
      sx={{ mb: 2 }}
      onChange={handleSectionTypeChange}
      value={sectionTypeOptionsValue}
      options={sectionTypeOptions}
      //   isOptionEqualToValue={(option) => option.parent_id === option.parent_id}
      renderInput={(params) => <TextField {...params} label="Choose section type" />}
    />
  );
}
