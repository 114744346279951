import { useEffect, useState, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, TextField } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useNavigate, useParams } from "react-router-dom";

// User context

import { UserContext } from "context/user";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// get parents categories, category by id

import {
  FetchAdminCategories,
  FetchAdminCategoryById,
  EditAdminCategory,
} from "utils/apis/catalog/categories";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";

import InputFileAndPreview from "elements/InputFileAndPreview";

function CategoryEdit() {
  const { id } = useParams();

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // set category
  const [categoryToEdit, setCategoryToEdit] = useState("");

  // parents categories state

  const [parentsCategories, setParentsCategories] = useState("");
  const [categorySearchKey, setCategorySearchKey] = useState(null);

  const [parentCategoryValue, setParentCategoryValue] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // use navigate hook

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const getCategoryById = async () => {
        setLoadingOrNot(true);
        const result = await FetchAdminCategoryById(id);

        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            // set state of category
            const {
              parent_id,
              category_color,
              category_transparency,
              description,
              sort_order,
              status,
              image,
            } = result.data;
            setCategoryToEdit({
              parent_id,
              category_color,
              category_transparency,
              description,
              sort_order,
              image,
              status,
            });
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };

      getCategoryById();
    }
  }, [id]);

  // this effect to fetch parents cateogires (options) if there is a cateogry to edit

  useEffect(() => {
    const getParentsCategories = async () => {
      let searchKeyObject;
      if (!categorySearchKey) {
        searchKeyObject = {};
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            name: categorySearchKey,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
      }

      const result = await FetchAdminCategories(searchKeyObject);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const { results } = result.data;
          return setParentsCategories(results);
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    getParentsCategories();
  }, [categorySearchKey]);

  useEffect(() => {
    if (categoryToEdit.parent_id) {
      const getParentCategoryForId = async () => {
        const result = await FetchAdminCategoryById(categoryToEdit.parent_id);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            const categoryParentName = result?.data?.description?.[0]?.name;
            // console.log(result?.data?.description?.[0]?.name);
            return setParentCategoryValue(categoryParentName);
          } else {
            // this is backend validation error
            return setErrorMSG(result.data.error);
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };
      getParentCategoryForId();
    }
  }, [categoryToEdit.parent_id]);

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategoryToEdit({
      ...categoryToEdit,
      [name]: value,
    });
  };

  // handle select change
  const handleParentCategoryChange = (_, value) => {
    if (value) {
      setCategoryToEdit({
        ...categoryToEdit,
        parent_id: value.parent_id,
      });
    }
  };
  // set image state
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setCategoryToEdit({
      ...categoryToEdit,
      image: file,
    });
  };

  const handleEnableOrDisableChange = (event) => {
    if (event.target.value == "enable") {
      setCategoryToEdit((prev) => {
        return { ...prev, status: 1 };
      });
    } else if (event.target.value == "disable") {
      setCategoryToEdit((prev) => {
        return { ...prev, status: 0 };
      });
    }
  };

  // handle name change inside the description object

  const handleNameChange = (event) => {
    const { name, value } = event.target;

    let descriptionToUpdate = categoryToEdit?.description;
    if (name === "nameEn") {
      descriptionToUpdate[0].name = value;
    } else if (name === "nameAr") {
      descriptionToUpdate[1].name = value;
    }

    setCategoryToEdit({
      ...categoryToEdit,
      description: descriptionToUpdate,
    });
  };

  //handle create Edit submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate name ar, name en, image
    // parent id default 0 if not selected (means this is a parent category)

    const { description, image } = categoryToEdit;
    if ((!description[0].name && !description[1].name) || !image) {
      return setErrorMSG("Please fill all the fields");
    }

    // color default (old color) if not selected
    // transparency default (old transparency) if not selected

    const result = await EditAdminCategory(id, categoryToEdit);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Category updated successfuly");
        // navigate to categories list page
        return navigate("/catalog/categories");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  const handleCategorySearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 3) {
      setCategorySearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setCategorySearchKey(null);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1} mb={1}>
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={8}>
              <MDBox textAlign="center" mb={2}>
                <MDTypography variant="h3" fontWeight="bold">
                  Edit Category
                </MDTypography>
              </MDBox>
              <Card>
                {categoryToEdit ? (
                  <MDBox p={3}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Edit Category
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDBox mb={2}>
                        <MDInput
                          defaultValue={categoryToEdit.description[0].name}
                          name="nameEn"
                          fullWidth
                          label="Category Name (English)"
                          onChange={handleNameChange}
                          type="text"
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          defaultValue={categoryToEdit.description[1].name}
                          name="nameAr"
                          fullWidth
                          label="Category Name (Arabic)"
                          onChange={handleNameChange}
                          type="text"
                        />
                      </MDBox>
                      <MDBox sx={{ mb: 2 }}>
                        <Autocomplete
                          value={parentCategoryValue}
                          onChange={handleParentCategoryChange}
                          options={
                            parentsCategories
                              ? parentsCategories.map((parentCategory) => ({
                                  label: parentCategory?.description[0]?.name,
                                  parent_id: parentCategory?.category_id,
                                }))
                              : []
                          }
                          onInputChange={handleCategorySearch}
                          // isOptionEqualToValue={(option) => option.parent_id === option.parent_id}
                          renderInput={(params) => (
                            <TextField {...params} label="Choose Parent Category" />
                          )}
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          defaultValue={categoryToEdit.category_color}
                          name="category_color"
                          fullWidth
                          label="Color code #HEXA"
                          type="color"
                          onChange={handleChange}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          defaultValue={categoryToEdit.category_transparency}
                          name="category_transparency"
                          fullWidth
                          label={"Transparency (%)"}
                          onChange={handleChange}
                          type="text"
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          defaultValue={categoryToEdit.sort_order}
                          name="sort_order"
                          fullWidth
                          label={"sort order"}
                          onChange={handleChange}
                          type="number"
                        />
                      </MDBox>
                      <MDBox mb={2} px={1}>
                        <FormControl>
                          <MDTypography>please be careful with that</MDTypography>
                          <RadioGroup
                            row
                            aria-labelledby="enable-or-disable-category"
                            name="enable-or-disable-category-group"
                            onChange={handleEnableOrDisableChange}
                          >
                            <FormControlLabel
                              value="enable"
                              control={<Radio checked={categoryToEdit?.status} />}
                              label="Enable"
                            />
                            <FormControlLabel
                              value="disable"
                              control={<Radio checked={!categoryToEdit?.status} />}
                              label="Disable"
                            />
                          </RadioGroup>
                        </FormControl>
                      </MDBox>
                    </MDBox>
                    {/* <MDBox mb={2}>{<MDInput type="file" onChange={handleImageUpload} />}</MDBox> */}
                    <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                      <InputFileAndPreview
                        handleImageUpload={handleImageUpload}
                        file={categoryToEdit?.image}
                      />
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox component="img" alt="Not Found" src={NotFound404} />
                  </MDBox>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CategoryEdit;
