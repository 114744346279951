import { useState, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, FormControl, MenuItem, Select } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";

// Notifications Context

import { NotificationsContext } from "context/notifications";

import "app.css";

// Post banner method

import { PostAdminBanner } from "utils/apis/design/banners";
import CategoryAutocomplete from "elements/CategoryAutocomplete";
import BrandAutocomplete from "elements/BrandAutocomplete";
import ProductAutocomplete from "elements/ProductAutocomplete";

import InputFileAndPreview from "elements/InputFileAndPreview";
import { AddRankRequest } from "utils/apis/users/users-ranking";
const defaultFormFields = {
  rankNameEnglish: "",
  rankNameArabic: "",
  min_points: null,
  max_points: null,
};

function RankCreate() {
  const [formFields, setFormFields] = useState(defaultFormFields);

  const { rankNameEnglish, rankNameArabic, min_points, max_points } = formFields;

  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // handle change handler (general)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // handle create rank submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate banner title and image
    if (!rankNameEnglish || !min_points || !max_points) {
      return setErrorMSG("Please fill all the fields");
    }

    const rankName = [
      {
        language_id: 1,
        rank_name: rankNameEnglish,
      },
      {
        language_id: 2,
        rank_name: rankNameArabic,
      },
    ];

    /// send post banner request
    const result = await AddRankRequest({
      rank_name: rankName,
      min_points,
      max_points,
    });

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("rank created successfuly");
        // navigate to brands list page
        return navigate("/ranks");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={2} textAlign="center">
              <MDTypography variant="h3" fontWeight="bold">
                Create New User Rank
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New User Rank
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="rankNameEnglish"
                      fullWidth
                      label="name in english"
                      value={rankNameEnglish}
                      onChange={handleChange}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="rankNameArabic"
                      fullWidth
                      label="name in english"
                      value={rankNameArabic}
                      onChange={handleChange}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="min_points"
                      fullWidth
                      label="min_points"
                      value={min_points}
                      onChange={handleChange}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      name="max_points"
                      fullWidth
                      label="max_points"
                      value={max_points}
                      onChange={handleChange}
                      type="number"
                    />
                  </Grid>
                </Grid>

                <MDBox display="flex" justifyContent="flex-end" mt={2}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RankCreate;
