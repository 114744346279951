// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

function ProductImageCell({ image, name, color }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        <MDAvatar bgColor={color} src={image} size="md" />
      </MDBox>
    </MDBox>
  );
}

// Setting default value for the props of BrandLogoCell
ProductImageCell.defaultProps = {
  image: "",
  color: "dark",
};

// Typechecking props for the BrandLogoCell
ProductImageCell.propTypes = {
  image: PropTypes.string,
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default ProductImageCell;
