import React, { createContext, useReducer } from "react";

const SET_PRODUCTS_ACTIONS = {
  SET_PRODUCTS: "SET_PRODUCTS",
  CLEAR_PRODUCTS: "CLEAR_PRODUCTS",
  FILTER: "SET_FILTERS",
};

// Initial state of the products context
const initialState = {
  products: null,
  productsFilters: {
    searchTerm: null,
    selectedBrandForFinalSearch: null,
    productId: null,
    availableQuantity: null,
    price: null,
    discountStartDate: null,
    discountExpiryDate: null,
    dateAdded: null,
    dateModified: null,
    categoryId: null,
    status: "All",
  },
};

// Products context reducer function
const productsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PRODUCTS_ACTIONS.SET_PRODUCTS:
      return { ...state, products: payload };
    case SET_PRODUCTS_ACTIONS.CLEAR_PRODUCTS:
      return initialState;
    case SET_PRODUCTS_ACTIONS.FILTER:
      return {
        ...state,
        productsFilters: { ...state.productsFilters, [payload.name]: payload.value },
      };
    default:
      return state;
  }
};

// Create the products context
const ProductsContext = createContext();

// products context provider component
const ProductsContextProvider = ({ children }) => {
  const [{ products, productsFilters }, dispatch] = useReducer(productsReducer, initialState);

  // Action to set the products context
  const setProducts = (products) => {
    dispatch({ type: SET_PRODUCTS_ACTIONS.SET_PRODUCTS, payload: products });
  };

  // Action to clear the products context
  const clearProducts = () => {
    dispatch({ type: SET_PRODUCTS_ACTIONS.CLEAR_PRODUCTS });
  };

  const setProductsFilter = (name, value) => {
    const filterObj = { name, value };
    dispatch({ type: SET_PRODUCTS_ACTIONS.FILTER, payload: filterObj });
  };

  const value = { products, setProducts, clearProducts, setProductsFilter, productsFilters };
  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

export { ProductsContext, ProductsContextProvider };
