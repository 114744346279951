import React, { Component } from "react";
import { DropzoneArea } from "mui-file-dropzone";

class Dropzone extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(files) {
    // pass the files to parent component
    if (files.length) {
      this.props.onFilesChange(files);
    } else {
      this.props.onFilesChange("");
    }
  }

  render() {
    return (
      <DropzoneArea
        initialFiles={this.props.initFiles.map((image) =>
          // if image is a file, ( new product ) return array of files,else return arrau of urls
          // (image instanceof File) ? image : image.image  ** make sure to keep keep the () as this line
          image instanceof File ? image : image.image
        )}
        useChipsForPreview={true}
        filesLimit={100}
        acceptedFiles={["image/*"]}
        dropzoneText={"Drag and drop images"}
        showAlerts={false}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

export default Dropzone;
