// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Stack } from "@mui/material";
import MDBadge from "components/MDBadge";
import { DeleteAdminProduct } from "utils/apis/catalog/products/products";
import { NotificationsContext } from "context/notifications";
import { UserContext } from "context/user";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { displayBasicDate } from "utils/methods";

import YesOrNoDialog from "elements/YesOrNoDialog";

import { Link } from "react-router-dom";

function ProductInfo({ product }) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const {
    product_id,
    model,
    price,
    price_dollar,
    available_quantity,
    description,
    weight,
    length,
    width,
    height,
    points,
    date_added,
    date_modified,
    status,
    brand_name,
    categories,
  } = product;

  const { name } = description[0];

  const statusText = () => {
    switch (status) {
      case 0:
        return "FEATURED";
      case 1:
        return "PROMOTED";
      case 2:
        return "NEW";
      default:
        return "/";
    }
  };

  const { user } = useContext(UserContext);
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);
  const navigate = useNavigate();

  const handleDeleteProduct = async () => {
    const result = await DeleteAdminProduct(product_id);
    if (result.status === 204) {
      // show deleted alert
      setSuccessMSG("Product Deleted Successfuly");
      navigate("/catalog/products");
    } else {
      // show error alert
      setErrorMSG("Something went wrong");
    }
  };
  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          {name}
        </MDTypography>
      </MDBox>
      {/* <MDTypography variant="h4" color="text">
        <Icon>star</Icon>
        <Icon>star</Icon>
        <Icon>star</Icon>
        <Icon>star</Icon>
        <Icon>star_half</Icon>
      </MDTypography> */}
      <Stack direction="row" spacing={2}>
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Price :
          </MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="medium">
            IQD {parseFloat(price)}, USD {parseFloat(price_dollar)}
          </MDTypography>
        </MDBox>

        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Model :
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography variant="h5" fontWeight="medium">
            {model}
          </MDTypography>
        </MDBox>
      </Stack>

      {available_quantity > 0 ? (
        <MDBadge variant="contained" color="success" badgeContent="in stock" container />
      ) : (
        <MDBadge variant="contained" color="error" badgeContent="not available" container />
      )}

      <Stack direction="row" mt={1} spacing={2}>
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Brand :
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography variant="h5" fontWeight="medium">
            {brand_name ? brand_name : "N/A"}
          </MDTypography>
        </MDBox>
      </Stack>

      <MDBox mt={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Categories :
        </MDTypography>
      </MDBox>
      <Stack direction="row" flexWrap="wrap">
        {categories?.length
          ? categories.map((category) => {
              return (
                <MDBox ml={1}>
                  <MDBadge badgeContent={category.name} color="dark" size="xs" container />
                </MDBox>
              );
            })
          : "N/A"}
      </Stack>
      <MDBox mt={2} ml={0.5}>
        <MDTypography variant="h6" fontWeight="medium">
          Description
        </MDTypography>
      </MDBox>
      {description?.length > 1 ? (
        description.map((description) => {
          return (
            <MDBox component="ul" m={0} pl={4} mb={0.5}>
              {description.name}
              <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography
                  variant="body2"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  {description.description}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        })
      ) : (
        <MDBox component="ul" m={0} pl={4} mb={2}>
          <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
            <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
              {description.description}
            </MDTypography>
          </MDBox>
        </MDBox>
      )}

      <MDBox mt={2}>
        <TableContainer sx={{ boxShadow: 1, borderRadius: "0 !important" }}>
          <Table
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
            aria-label="user details table"
          >
            <TableRow className="bold-row">
              <TableCell align="left">Weight</TableCell>
              <TableCell align="left">Length</TableCell>
              <TableCell align="left">Width</TableCell>
              <TableCell align="left">Height</TableCell>
            </TableRow>

            <TableBody>
              <TableRow>
                <TableCell align="left">{parseFloat(weight)}</TableCell>
                <TableCell align="left">{parseFloat(length)}</TableCell>
                <TableCell align="left">{parseFloat(width)}</TableCell>
                <TableCell align="left">{parseFloat(height)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer sx={{ boxShadow: 1, borderRadius: "0 !important", marginTop: 1 }}>
          <Table
            sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
            aria-label="user details table"
          >
            <TableRow className="bold-row">
              <TableCell align="left">Points</TableCell>
              <TableCell align="left">Date Added</TableCell>
              <TableCell align="left">Date Modified</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>

            <TableBody>
              <TableRow>
                <TableCell align="left">{points}</TableCell>
                <TableCell align="left">{displayBasicDate(date_added)}</TableCell>
                <TableCell align="left">{displayBasicDate(date_modified)}</TableCell>
                <TableCell align="left">
                  <MDBadge
                    badgeContent={statusText()}
                    color={statusText() === "NEW" ? "success" : "dark"}
                    container
                    size="xs"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>

      {/* TODO:  */}

      {/* {product_attributes.map((productAttribute) => (
        <MDBox component="ul" m={0} pl={4} mb={2}>
          {productAttribute.attributes_group}
          <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
            <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
              {productAttribute.description} : {productAttribute.text}
            </MDTypography>
          </MDBox>
        </MDBox>
      ))} */}

      <MDBox mt={3}>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <MDButton variant="gradient" color="info" fullWidth>
              add to cart
            </MDButton>
            <Link to={`/catalog/products/images/${product_id}`}>
              <MDButton variant="gradient" color="info" fullWidth>
                images
              </MDButton>
            </Link>

            <MDButton onClick={handleClickOpen} variant="gradient" color="error" fullWidth>
              delete
            </MDButton>
          </Stack>
        </Grid>
      </MDBox>

      <YesOrNoDialog
        heading="Are you sure want to delete this product !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteProduct}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
    </MDBox>
  );
}

export default ProductInfo;
