import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Post product attribute group
import { PostAdminProductsAttributesGroup } from "utils/apis/catalog/products/productsAttributesGroups";

function ProductAttributeGroupCreate() {
  // defualt state of attributes group
  const defualtFormFields = {
    nameEn: "",
    nameAr: "",
  };

  const [attributesGroup, setAttributesGroup] = useState(defualtFormFields);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // handle inputs change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAttributesGroup({ ...attributesGroup, [name]: value });
  };

  // handle create attributes group
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { nameEn, nameAr } = attributesGroup;

    // Reset validation messages
    clearErrorMSG();
    // Validate name a
    if (!nameEn || !nameAr) {
      return setErrorMSG("Please fill all the fields");
    }

    // /// send post attributes group
    const result = await PostAdminProductsAttributesGroup([
      { language_id: 1, name: nameEn },
      { language_id: 2, name: nameAr },
    ]);

    if (!(result instanceof Error)) {
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Group Created successfuly");
        // navigate to delivery costs list page
        navigate("/catalog/products/attributes-group");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mb={2}>
              <MDTypography variant="h3" fontWeight="bold">
                Create Attributes Group
              </MDTypography>
            </MDBox>

            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create Attributes Group
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="nameEn"
                    sx={{ width: 400 }}
                    label="English Name"
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="nameAr"
                    sx={{ width: 400 }}
                    label="Arabic Name"
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductAttributeGroupCreate;
