import ActionsCell from "../components/ActionsCell/index";
import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

export const pointsCouponsHeaders = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Points Needed",
    accessor: "points_needed",
    Cell: ({ value }) => <DefaultCell value={value.toString()} />,
  },
  {
    Header: "Discount",
    accessor: "discount",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Days to expire",
    accessor: "days_to_expire",
    Cell: ({ value }) => <DefaultCell value={value.toString()} />,
  },

  {
    Header: "Created at",
    accessor: "created_at",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
