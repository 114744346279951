// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import CustomerMembershipComponent from "./CustomerMembershipComponent";

function CustomerMembership() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CustomerMembershipComponent />
      <Footer />
    </DashboardLayout>
  );
}

export default CustomerMembership;
