import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";

import { Autocomplete, TextField } from "@mui/material";

import { PostHomeSection } from "utils/apis/home_section/home";
import { useNavigate } from "react-router-dom";

import { NotificationsContext } from "context/notifications";

import { useState, useEffect, useContext, useMemo } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import InputFileAndPreview from "elements/InputFileAndPreview";
import CategoryAutocomplete from "elements/CategoryAutocomplete";
import BrandAutocomplete from "elements/BrandAutocomplete";

import SectionTypeComponent from "../SectionTypeComponent";
import ProductStatusAutoComplete from "elements/ProductStatusAutoComplete";
import { convertProductStatusStringToId } from "utils/methods";

const defaultFormFields = {
  titleEn: "",
  titleAr: "",
  sub_titleEn: "",
  sub_titleAr: "",
  filter_id: null,
  order_id: "",
  // section_products_limit: null,
  sectionType: 1,
  section_background: null,
};

function CreateSection() {
  const navigate = useNavigate();
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);
  const [selectedFilterIdObj, setSelectedFilterIdObj] = useState(null);

  const [formFields, setFormFields] = useState(defaultFormFields);

  const {
    titleEn,
    titleAr,
    sub_titleEn,
    sub_titleAr,
    filter_id,
    order_id,
    // section_products_limit,
    sectionType,
    section_background,
  } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFormFields({
      ...formFields,
      section_background: file,
    });
  };

  // handle create section submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate name ar, name en, color, image
    // parent id default 0 if not selected (means this is a parent category)
    if (!titleEn || !titleAr || !order_id || !sectionType) {
      return setErrorMSG("Please fill all the fields");
    }

    let sectionProductLimit;
    let selectedFilterId;
    if (sectionType == 1 || sectionType == 4 || sectionType == 5) {
      selectedFilterId = selectedFilterIdObj?.category_id;
      if (sectionType == 1) sectionProductLimit = 6;
      if (sectionType == 4) sectionProductLimit = 4;
      if (sectionType == 5) sectionProductLimit = 8;
    } else if (sectionType == 2) {
      selectedFilterId = convertProductStatusStringToId(selectedFilterIdObj);
      sectionProductLimit = 6;
    } else if (sectionType == 3) {
      selectedFilterId = selectedFilterIdObj?.manufacturer_id;
      sectionProductLimit = 6;
    }

    if (selectedFilterId == null && selectedFilterId == undefined) {
      return setErrorMSG("Please fill filter id");
    }

    const sectionBody = {
      section_background,
      type: sectionType,
      section_products_limit: sectionProductLimit,
      order_id,
      filter_id: selectedFilterId,
      title: [
        {
          title: titleEn,
          sub_title: sub_titleEn,
          language_id: 1,
        },
        {
          title: titleAr,
          sub_title: sub_titleAr,
          language_id: 2,
        },
      ],
    };

    const result = await PostHomeSection(sectionBody);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("section created successfuly");
        // navigate to brands list page
        return navigate("/dashboards/home-section");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  // Fetch the home sections
  useEffect(() => {
    // const fetchHomeSections = async () => {
    //   try {
    //     const response = await FetchHomeSections();
    //     if (response.data) {
    //       setHomeSections(response.data?.results);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     setErrorMSG("something went wrong");
    //   }
    // };
    // fetchHomeSections();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center" mb={3}>
              <MDTypography variant="h3" fontWeight="bold">
                Create New Section
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New section
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDInput
                      name="titleEn"
                      fullWidth
                      label="section title in english"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="titleAr"
                      fullWidth
                      label="section title in arabic"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="sub_titleEn"
                      fullWidth
                      label="section sub title in english"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="sub_titleAr"
                      fullWidth
                      label="section sub title in arabic"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      name="order_id"
                      fullWidth
                      label="order id"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>

                  {/* <MDBox mb={2}>
                    <MDInput
                      name="section_products_limit"
                      fullWidth
                      label="products limit"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox> */}

                  <MDBox>
                    {/* <Autocomplete
                      sx={{ mb: 2 }}
                      onChange={handleSectionTypeChange}
                      value={sectionTypeOptionsValue}
                      options={sectionTypeOptions}
                      //   isOptionEqualToValue={(option) => option.parent_id === option.parent_id}
                      renderInput={(params) => (
                        <TextField {...params} label="Choose section type" />
                      )}
                    /> */}
                    <SectionTypeComponent formFields={formFields} setFormFields={setFormFields} />
                  </MDBox>

                  <MDBox mb={2}>
                    {sectionType == 1 && (
                      <CategoryAutocomplete
                        selectedCategory={selectedFilterIdObj}
                        setSelectedCategory={setSelectedFilterIdObj}
                      />
                    )}
                    {sectionType == 4 && (
                      <CategoryAutocomplete
                        selectedCategory={selectedFilterIdObj}
                        setSelectedCategory={setSelectedFilterIdObj}
                      />
                    )}
                    {sectionType == 5 && (
                      <CategoryAutocomplete
                        selectedCategory={selectedFilterIdObj}
                        setSelectedCategory={setSelectedFilterIdObj}
                      />
                    )}
                    {sectionType == 2 && (
                      <ProductStatusAutoComplete
                        productState={selectedFilterIdObj}
                        setProductState={setSelectedFilterIdObj}
                      />
                    )}
                    {sectionType == 3 && (
                      <BrandAutocomplete
                        selectedBrand={selectedFilterIdObj}
                        setSelectedBrand={setSelectedFilterIdObj}
                      />
                    )}
                  </MDBox>

                  <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                    <InputFileAndPreview
                      handleImageUpload={handleImageUpload}
                      file={formFields?.section_background}
                    />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateSection;
