// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// ProductPage page components
// import ProductImagesViewer from "./components/ProductImagesViewer";
import ProductImagesViewer from "layouts/catalog/products/product-images/components/ProductImagesViewer";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";

import { useEffect, useContext, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { FetchAdminProductImageById, updateImageSort } from "utils/apis/catalog/products/products";

import CircularProgress from "@mui/material/CircularProgress";
import NotFound404 from "assets/images/404.gif";

import AddImageDialog from "layouts/catalog/products/product-images/components/AddImageDialog";

import MDButton from "components/MDButton";

import SaveAltIcon from "@mui/icons-material/SaveAlt";

function ProductImages({ product_id }) {
  const id = product_id;
  // in case of 404
  const [notfoundMSG, setNotFoundMSG] = useState("");
  const [images, setImages] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);
  const [reRender, setRerender] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the click event of the hidden file input
    fileInputRef.current.click();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const saveImagesOrder = async () => {
    if (images?.length) {
      for (let i = 0; i < images?.length; i++) {
        const res = await updateImageSort(images[i]?.product_image_id, {
          sort_order: Number(images[i]?.sort_order),
          product_id: id,
        });
      }
      setRerender((prev) => !prev);
    } else return;
  };

  useEffect(() => {
    if (id) {
      const fetchProductImages = async () => {
        setLoadingOrNot(true);
        const result = await FetchAdminProductImageById(id);
        setLoadingOrNot(false);
        if (!(result instanceof Error)) {
          if (!result.data.error && result.data) {
            const productImages = result.data?.results.map((image) => {
              return {
                image: image?.image,
                sort_order: image?.sort_order,
                product_image_id: image?.product_image_id,
              };
            });

            // Sort the productImages array by the sort_order property
            productImages.sort((a, b) => a.sort_order - b.sort_order);

            return setImages(productImages);
          } else {
            return setErrorMSG(result.data.error);
          }
        } else {
          return setErrorMSG(result.message);
        }
      };

      fetchProductImages();
    }
  }, [id, selectedFile, reRender]);

  return (
    <>
      <MDBox py={3}>
        {loadingOrNot ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <>
            <Card sx={{ overflow: "visible" }}>
              {images?.length && loadingOrNot === false ? (
                <MDBox p={3}>
                  <MDBox
                    mb={3}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    <MDTypography variant="h5" fontWeight="medium">
                      Product images
                    </MDTypography>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      // onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                    <MDBox>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          handleClickOpenDialog();
                        }}
                        sx={{ mx: 1 }}
                      >
                        new image
                      </MDButton>
                      <MDButton variant="gradient" color="info" onClick={saveImagesOrder}>
                        <SaveAltIcon />
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ProductImagesViewer
                        images={images}
                        setRerender={setRerender}
                        setImages={setImages}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              ) : (
                <MDBox
                  my={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ textAlign: "center", fontWeight: "bold" }}>
                    {notfoundMSG}
                  </MDTypography>
                  <MDBox component="img" alt="category picture" src={NotFound404} />
                </MDBox>
              )}
            </Card>
          </>
        )}
      </MDBox>
      <AddImageDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        product_id={id}
        setRerender={setRerender}
      />
    </>
  );
}

export default ProductImages;
