// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// NewProduct page components
import FormField from "layouts/catalog/products/new-product/components/FormField";

import { formatDateToISO } from "utils/methods";

function Pricing(props) {
  const { localState, setParentState } = props;

  // handle change event
  const handleChange = (event) => {
    const { name, value } = event.target;

    setParentState((previouseState) => {
      return {
        ...previouseState,
        step3: {
          ...previouseState.step3,
          [name]: value,
        },
      };
    });
  };

  const handleDiscountStartDateChange = (_, value) => {
    setParentState((previouseState) => {
      return {
        ...previouseState,
        step3: {
          ...previouseState.step3,
          discount_start_date: formatDateToISO(value),
        },
      };
    });
  };

  const handleDiscountEndDateChange = (_, value) => {
    setParentState((previouseState) => {
      return {
        ...previouseState,
        step3: {
          ...previouseState.step3,
          discount_expiry_date: formatDateToISO(value),
        },
      };
    });
  };

  return (
    <MDBox>
      <MDTypography variant="h5">Pricing</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          {/* Price Input */}

          <Grid item xs={12} sm={3}>
            <FormField
              defaultValue={localState.price}
              type="number"
              onChange={handleChange}
              name="price"
              label="Price"
              placeholder="99.00"
            />
          </Grid>

          {/* Quantity Input */}
          <Grid item xs={12} sm={3}>
            <FormField
              defaultValue={localState.available_quantity}
              type="number"
              onChange={handleChange}
              name="available_quantity"
              label="Available Quantity"
              placeholder="0"
            />
          </Grid>

          {/* Discount Input */}
          <Grid item xs={12} sm={5}>
            <FormField
              defaultValue={localState.discountPrice}
              onChange={handleChange}
              type="number"
              name="discountPrice"
              label="Discount Price"
              placeholder="00.00"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <MDDatePicker
              input={{
                id: "discount_start_date",
                placeholder: "discount start date",
              }}
              options={{
                enableTime: true,
                enableSeconds: true,
              }}
              onChange={handleDiscountStartDateChange}
              value={localState.discount_start_date}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <MDDatePicker
              input={{
                id: "discount_expiry_date",
                placeholder: "discount expiry date",
              }}
              options={{
                enableTime: true,
                enableSeconds: true,
              }}
              onChange={handleDiscountEndDateChange}
              value={localState.discount_expiry_date}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormField
              defaultValue={localState.points}
              type="number"
              onChange={handleChange}
              name="points"
              label="points"
              placeholder="0"
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Pricing;
