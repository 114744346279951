import { useState, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, FormControl, MenuItem, Select } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";

// Notifications Context

import { NotificationsContext } from "context/notifications";

import "app.css";

// Post banner method

import { PostAdminBanner } from "utils/apis/design/banners";
import CategoryAutocomplete from "elements/CategoryAutocomplete";
import BrandAutocomplete from "elements/BrandAutocomplete";
import ProductAutocomplete from "elements/ProductAutocomplete";

import InputFileAndPreview from "elements/InputFileAndPreview";

function BannerCreate() {
  const defaultFormFields = {
    title: "",
    language_id: 1,
    image: null,
    banner_type: "brand",
    banner_type_id: "",
    sort_order: "",
  };

  const [formFields, setFormFields] = useState(defaultFormFields);

  const { title, language_id, image, banner_type, banner_type_id, sort_order } = formFields;

  const [selectedBannerTypeIdObj, setSelectedBannerTypeIdObj] = useState(null);

  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // handle change handler (general)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // set image state
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setFormFields((previousState) => {
      return {
        ...previousState,
        image: file,
      };
    });
  };

  // handle create brand submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate banner title and image
    if (!title || !image) {
      return setErrorMSG("Please fill all the fields");
    }

    let bannerTypeId = banner_type_id;
    if (banner_type == "brand") {
      bannerTypeId = selectedBannerTypeIdObj?.manufacturer_id;
    } else if (banner_type == "category") {
      bannerTypeId = selectedBannerTypeIdObj?.category_id;
    } else if (banner_type == "product") {
      bannerTypeId = selectedBannerTypeIdObj?.product_id;
    }

    /// send post banner request
    const result = await PostAdminBanner({
      title,
      sort_order,
      image,
      language_id,
      banner_type,
      banner_type_id: bannerTypeId,
    });

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Banner created successfuly");
        // navigate to brands list page
        return navigate("/design/banners");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  console.log(selectedBannerTypeIdObj);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={2} textAlign="center">
              <MDTypography variant="h3" fontWeight="bold">
                Create New Banner
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New Banner
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="title"
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="sort_order"
                    fullWidth
                    label="sort_order"
                    value={sort_order}
                    onChange={handleChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="banner_type_id_label">Banner Type (where to go)</InputLabel>
                    <Select
                      value={banner_type}
                      name="banner_type"
                      onChange={handleChange}
                      labelId="banner_type_id_label"
                      label="banner type"
                      id="banner-type-id"
                      fullWidth
                      sx={{
                        minWidth: 120,
                        lineHeight: "3rem",
                      }}
                    >
                      <MenuItem value="brand">brand</MenuItem>
                      <MenuItem value="category">category</MenuItem>
                      <MenuItem value="product">product</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  {banner_type == "brand" && (
                    <BrandAutocomplete
                      selectedBrand={selectedBannerTypeIdObj}
                      setSelectedBrand={setSelectedBannerTypeIdObj}
                    />
                  )}
                  {banner_type == "category" && (
                    <CategoryAutocomplete
                      selectedCategory={selectedBannerTypeIdObj}
                      setSelectedCategory={setSelectedBannerTypeIdObj}
                    />
                  )}
                  {banner_type == "product" && (
                    <ProductAutocomplete
                      selectedProduct={selectedBannerTypeIdObj}
                      setSelectedProduct={setSelectedBannerTypeIdObj}
                    />
                  )}
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput type="file" onChange={handleImageUpload} />
                </MDBox> */}
                <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                  <InputFileAndPreview handleImageUpload={handleImageUpload} file={image} />
                </MDBox>
                <MDBox mb={2} sx={{ width: "400px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="language_id_label">Language</InputLabel>
                    <Select
                      value={language_id}
                      name="language_id"
                      onChange={handleChange}
                      labelId="language-id"
                      label="Language"
                      id="language_id"
                      fullWidth
                      sx={{
                        minWidth: 120,
                        lineHeight: "3rem",
                      }}
                    >
                      <MenuItem value={1}>English</MenuItem>
                      <MenuItem value={2}>Arabic</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BannerCreate;
