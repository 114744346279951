// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axiosInstance from "utils/axiosInstance";
import qs from "qs";

import { displayBasicDate } from "utils/methods";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { usersHeaders } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils

import downloadCsv from "download-csv";

import { fetchCustomersUsers, getExportedCsv } from "utils/apis/users/users";

// users list contxt
import { UsersContext } from "context/users";

import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";

// loged in user
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import "app.css";
import { formatDateToISO } from "utils/methods";

import CategoryAutocomplete from "elements/CategoryAutocomplete";

function UsersList() {
  // State for seraching
  const { users, setUsers, setUsersFilter, usersFilters } = useContext(UsersContext);

  // state for filters
  const [usersWithZeroOrders, setUsersWithZeroOrders] = useState(
    usersFilters.usersWithZeroOrders || false
  );
  const [usersWithDoneOrders, setUsersWithDoneOrders] = useState(
    usersFilters.usersWithDoneOrders || false
  );
  const [isActive, setIsActive] = useState(usersFilters.isActive || false);

  const [userName, setUserName] = useState(usersFilters.userName || null);
  const [firstName, setFirstName] = useState(usersFilters.firstName || null);
  const [LastName, setLastName] = useState(usersFilters.LastName || null);
  const [dateJoined, setDateJoined] = useState(usersFilters.dateJoined || null);

  const [ltvMaxThreshold, setLtvMaxThreshold] = useState(usersFilters.ltvMaxThreshold || null);
  const [ltvMinThreshold, setLtvMinThreshold] = useState(usersFilters.ltvMinThreshold || null);
  const [ltvStartDate, setLtvStartDate] = useState(usersFilters.ltvStartDate || null);
  const [ltvEndDate, setLtvEndDate] = useState(usersFilters.ltvEndDate || null);

  const [selectedCategory, setSelectedCategory] = useState(usersFilters.selectedCategory || null);

  const [getNewResults, setGetNewResults] = useState(0);
  const [csvDownload, setCsvDownload] = useState(false);
  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  const handleLtvStartDateChange = (_, value) => {
    setLtvStartDate(value);
  };
  const handleLtvEndDateChange = (_, value) => {
    setLtvEndDate(value);
  };

  const handleDateJoinedChange = (_, value) => {
    setDateJoined(value);
  };

  // Fetch the users
  useEffect(() => {
    const fetchUsers = async () => {
      let searchKeyObject;
      if (
        !usersWithZeroOrders &&
        !usersWithDoneOrders &&
        !isActive &&
        !userName &&
        !firstName &&
        !LastName &&
        !dateJoined &&
        !ltvMaxThreshold &&
        !ltvMinThreshold &&
        !ltvStartDate &&
        !ltvEndDate &&
        !selectedCategory?.category_id
      ) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        // filter the object to have only not undefined value property
        searchKeyObject = Object.fromEntries(
          Object.entries({
            users_with_zero_orders: usersWithZeroOrders,
            users_with_done_orders: usersWithDoneOrders,
            is_active: isActive,

            username: userName,
            first_name: firstName,
            last_name: LastName,
            date_joined: dateJoined ? formatDateToISO(dateJoined) : null,

            ltv_max_threshold: ltvMaxThreshold,
            ltv_min_threshold: ltvMinThreshold,
            ltv_start_date: ltvStartDate ? formatDateToISO(ltvStartDate) : null,
            ltv_end_date: ltvEndDate ? formatDateToISO(ltvEndDate) : null,
            orders_in_category: selectedCategory?.category_id,
          }).filter(([_, value]) => value)
        );
        setSearchKeyObjectPagination(searchKeyObject);
      }
      try {
        const response = await fetchCustomersUsers(searchKeyObject);

        if (response.data) {
          const { count, next, previous, results } = response.data;
          return setUsers({
            count,
            next,
            previous,
            data: results,
          });
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchUsers();
  }, [
    usersWithZeroOrders,
    usersWithDoneOrders,
    isActive,
    // ltvMaxThreshold,
    // ltvMinThreshold,
    // ltvStartDate,
    // ltvEndDate,
    // userName,
    // firstName,
    // LastName,
    // dateJoined,
    // selectedCategory?.category_id
    getNewResults,
  ]);

  const exportFunction = async () => {
    setCsvDownload(true);
    let searchKeyObject;
    if (
      !usersWithZeroOrders &&
      !usersWithDoneOrders &&
      !ltvMaxThreshold &&
      !ltvMinThreshold &&
      !ltvStartDate &&
      !ltvEndDate &&
      !selectedCategory?.category_id
    ) {
      searchKeyObject = {};
    } else {
      searchKeyObject = Object.fromEntries(
        Object.entries({
          users_with_zero_orders: usersWithZeroOrders,
          users_with_done_orders: usersWithDoneOrders,
          ltv_max_threshold: ltvMaxThreshold,
          ltv_min_threshold: ltvMinThreshold,
          ltv_start_date: ltvStartDate ? formatDateToISO(ltvStartDate) : null,
          ltv_end_date: ltvEndDate ? formatDateToISO(ltvEndDate) : null,
          orders_in_category: selectedCategory?.category_id,
        }).filter(([_, value]) => value)
      );
    }
    try {
      const response = await getExportedCsv(searchKeyObject);

      if (response.data) {
        setCsvDownload(false);
        downloadCsv(response.data, undefined, "users.csv");
      }
    } catch (error) {
      setCsvDownload(false);
      console.log(error);
      setErrorMSG("something went wrong");
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      setGetNewResults(Math.random());
    }
  };

  useEffect(() => {
    setUsersFilter("usersWithZeroOrders", usersWithZeroOrders);
    setUsersFilter("usersWithDoneOrders", usersWithDoneOrders);
    setUsersFilter("isActive", isActive);
    setUsersFilter("ltvMaxThreshold", ltvMaxThreshold);
    setUsersFilter("ltvMinThreshold", ltvMinThreshold);
    setUsersFilter("ltvStartDate", ltvStartDate);
    setUsersFilter("ltvEndDate", ltvEndDate);
    setUsersFilter("userName", userName);
    setUsersFilter("firstName", firstName);
    setUsersFilter("LastName", LastName);
    setUsersFilter("dateJoined", dateJoined);
    setUsersFilter("selectedCategory", selectedCategory);
  }, [
    usersWithZeroOrders,
    usersWithDoneOrders,
    isActive,
    ltvMaxThreshold,
    ltvMinThreshold,
    ltvStartDate,
    ltvEndDate,
    userName,
    firstName,
    LastName,
    dateJoined,
    selectedCategory,
  ]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox className="before-footer users-page" my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/users/create"}>
            <MDButton variant="gradient" color="info">
              New user
            </MDButton>
          </Link>
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton
                variant="outlined"
                color="dark"
                onClick={async () => {
                  await exportFunction();
                }}
              >
                {!csvDownload ? (
                  <>
                    <Icon>description</Icon>
                    &nbsp;export csv
                  </>
                ) : (
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress color="info" size={20} sx={{ marginInline: 0.5 }} />
                    &nbsp;export csv
                  </MDBox>
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={2}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={9} lg={9}>
              <Grid container spacing={2} mb={1.5}>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="LTV Max"
                    variant="outlined"
                    onBlur={(e) => {
                      setLtvMaxThreshold(e.target.value);
                    }}
                    defaultValue={ltvMaxThreshold}
                    onKeyDown={(e) => handleKeyPress(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="LTV Min"
                    variant="outlined"
                    onBlur={(e) => {
                      setLtvMinThreshold(e.target.value);
                    }}
                    defaultValue={ltvMinThreshold}
                    onKeyDown={(e) => handleKeyPress(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDDatePicker
                    input={{
                      id: "Ltv Start Date",
                      name: "ltvStartDate",
                      placeholder: "Select Ltv Start Date",
                    }}
                    defaultValue={ltvStartDate}
                    name="LtvStartDate"
                    options={{
                      enableTime: true,
                      enableSeconds: true,
                    }}
                    onChange={handleLtvStartDateChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDDatePicker
                    input={{
                      id: "Ltv End Date",
                      name: "LtvEndDate",
                      placeholder: "Select Ltv End Date",
                    }}
                    defaultValue={ltvEndDate}
                    name="LtvEndDate"
                    options={{
                      enableTime: false,
                      enableSeconds: false,
                    }}
                    onChange={handleLtvEndDateChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={1.5}>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="phone number"
                    variant="outlined"
                    onBlur={(e) => {
                      setUserName(e.target.value);
                    }}
                    defaultValue={userName}
                    onKeyDown={(e) => handleKeyPress(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="firstName"
                    variant="outlined"
                    onBlur={(e) => {
                      setFirstName(e.target.value);
                    }}
                    defaultValue={firstName}
                    onKeyDown={(e) => handleKeyPress(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="lastName"
                    variant="outlined"
                    onBlur={(e) => {
                      setLastName(e.target.value);
                    }}
                    defaultValue={LastName}
                    onKeyDown={(e) => handleKeyPress(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDDatePicker
                    input={{
                      id: "date_joined",
                      name: "date_joined",
                      placeholder: "Select date_joined",
                    }}
                    defaultValue={dateJoined}
                    name="date_joined"
                    options={{
                      enableTime: true,
                      enableSeconds: true,
                    }}
                    onChange={handleDateJoinedChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <CategoryAutocomplete
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    // makeSelectByThisCategoryId={}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <FormGroup>
                <Grid container alignItems="center">
                  <Grid item sx={12} lg={6}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        setGetNewResults(Math.random());
                      }}
                    >
                      Search
                    </MDButton>
                  </Grid>
                  <Grid item sx={12} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { border: "0.0625rem solid #000000 !important" },
                          }}
                          checked={usersWithZeroOrders}
                          onChange={(event) => {
                            setUsersWithZeroOrders(event.target.checked);
                          }}
                        />
                      }
                      label="Zero Orders"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { border: "0.0625rem solid #000000 !important" },
                          }}
                          checked={usersWithDoneOrders}
                          onChange={(event) => {
                            setUsersWithDoneOrders(event.target.checked);
                          }}
                        />
                      }
                      label="Done Orders"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { border: "0.0625rem solid #000000 !important" },
                          }}
                          checked={isActive}
                          onChange={(event) => {
                            setIsActive(event.target.checked);
                          }}
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
        <Card>
          {!users?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {users?.data && (
            <DataTable
              table={{
                columns: usersHeaders,
                rows: users.data.map((user) => ({
                  id: user.id,
                  name: user.first_name + " " + user.last_name,
                  username: user.username,
                  is_active: user.is_active ? "Yes" : "NO",
                  dateJoined: user?.date_joined ? displayBasicDate(user?.date_joined) : "/",
                  actions: user,
                })),
              }}
              nextPageUrl={users.next}
              previousPageUrl={users.previous}
              countPagination={users.count}
              searchKeyObjectPagination={searchKeyObjectPagination}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersList;
