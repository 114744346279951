import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable";
import { Link } from "react-router-dom";

import { useContext, useEffect } from "react";
import { FetchNotifications } from "utils/apis/notifications";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import CircularProgress from "@mui/material/CircularProgress";

import { NotificationsContext } from "context/notifications";

import { notificationsHeader } from "./data/dataTableData";

import { displayBasicDate } from "utils/methods";

function NotificationList() {
  const [notifications, setNotifications] = useState({});
  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchNotifications = async () => {
      const result = await FetchNotifications();

      if (!(result instanceof Error)) {
        if (!result.data.error && result.data) {
          const { results, next, previous, count } = result.data;
          return setNotifications({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    };

    fetchNotifications();
  }, []);

  // TODO:

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDTypography variant="h3">Notification Section</MDTypography>
          <MDBox display="flex">
            <Link to="/dashboards/notifications/send">
              <MDButton variant="gradient" color="info">
                send notification
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
        <Card>
          {!notifications?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}

          {notifications?.data && (
            <DataTable
              table={{
                columns: notificationsHeader,
                rows: notifications.data.map((notification) => ({
                  id: notification.id,
                  title: notification.title,
                  body: notification.body,
                  sentAt: notification.sent_at ? displayBasicDate(notification.sent_at) : "/",
                })),
              }}
              nextPageUrl={notifications.next}
              previousPageUrl={notifications.previous}
              countPagination={notifications.count}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NotificationList;
