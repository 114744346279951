import React, { useEffect, useState } from "react";

export default function ImagePreview({ fileInput, alt }) {
  const [src, setSrc] = useState(null);
  useEffect(() => {
    if (fileInput) {
      if (typeof fileInput == "object") {
        console.log(fileInput);
        setSrc(URL.createObjectURL(fileInput));
      } else {
        setSrc(fileInput);
      }
    }
  }, [fileInput]);

  return (
    <div>
      <img src={src ? src : "null"} alt={alt} style={{ width: "100%", height: "auto" }} />
    </div>
  );
}
