import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Post brand method

import { GetAdminBrandById, EditAdminBrand } from "utils/apis/ecommerce/brands";
import InputFileAndPreview from "elements/InputFileAndPreview";

import { useParams, useNavigate } from "react-router-dom";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";

function BrandEdit() {
  const { id } = useParams();

  // old brand

  const [brand, setBrand] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    if (id) {
      const getBrandById = async () => {
        setLoadingOrNot(true);
        const result = await GetAdminBrandById(id);
        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            // set state of brand
            const { name, sort_order, enabled, image } = result.data;

            return setBrand({
              name,
              sortOrder: sort_order,
              enabled,
              image,
            });
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };

      getBrandById();
    }
  }, [id]);

  // use navigate hook

  const navigate = useNavigate();

  // set brand name state
  const handleBrandNameChange = (event) => {
    // update the brand name
    setBrand({
      ...brand,
      name: event.target.value,
    });
  };

  const handleSortOrderChange = (event) => {
    setBrand({
      ...brand,
      sortOrder: event.target.value,
    });
  };

  // set image state
  const handleImageUpload = (event) => {
    // update the brand iamge
    const file = event.target.files[0];
    setBrand({
      ...brand,
      image: file,
    });
  };

  // handle update brand submit button
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset validation messages
    clearErrorMSG();

    // Validate brand name and image
    if (!brand.name) {
      return setErrorMSG("Please fill all the fields");
    }

    const result = await EditAdminBrand(id, brand);

    if (!(result instanceof Error)) {
      // back end validation errors
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Brand updated successfuly");
        // navigate to brands list page
        navigate("/ecommerce/brands-list");
      } else {
        setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  const handleEnableOrDisableChange = (event) => {
    if (event.target.value == "enable")
      setBrand({
        ...brand,
        enabled: true,
      });
    else if (event.target.value == "disable")
      setBrand({
        ...brand,
        enabled: false,
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          {loadingOrNot ? (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Grid item xs={12} lg={8}>
              <MDBox mt={2} mb={2} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Edit Brand
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Card>
                {brand ? (
                  <MDBox p={3}>
                    <MDBox mb={2}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Edit Brand #{id}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        fullWidth
                        label="Brand Name"
                        onChange={handleBrandNameChange}
                        type="text"
                        value={brand.name}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        fullWidth
                        type="number"
                        label="Sort Order"
                        name="sortOrder"
                        onChange={handleSortOrderChange}
                        value={brand.sortOrder}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl>
                        {/* <FormLabel id="enable-or-disable-brand">Notification For</FormLabel> */}
                        <MDTypography>please be careful with that</MDTypography>
                        <RadioGroup
                          row
                          aria-labelledby="enable-or-disable-brand"
                          name="enable-or-disable-brand-group"
                          onChange={handleEnableOrDisableChange}
                        >
                          <FormControlLabel
                            value="enable"
                            control={<Radio checked={brand.enabled} />}
                            label="Enable"
                          />
                          <FormControlLabel
                            value="disable"
                            control={<Radio checked={!brand.enabled} />}
                            label="Disable"
                          />
                        </RadioGroup>
                      </FormControl>
                    </MDBox>

                    {/* <MDBox mb={2}>
                      <MDInput type="file" onChange={handleImageUpload} />
                    </MDBox> */}

                    <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                      <InputFileAndPreview
                        handleImageUpload={handleImageUpload}
                        file={brand?.image}
                      />
                    </MDBox>

                    <MDBox display="flex" justifyContent="flex-end">
                      <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ) : (
                  <MDBox
                    my={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <MDBox component="img" alt="Not Found" src={NotFound404} />
                  </MDBox>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default BrandEdit;
