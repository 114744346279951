import React from "react";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { displayBasicDate } from "utils/methods";

export default function ChangeBox({ historyObj }) {
  return (
    <MDBox mt={1}>
      {historyObj && (
        <>
          {historyObj.map((single_change) => (
            <Card sx={{ p: 5, mb: 1 }}>
              <h3>In date : {displayBasicDate(single_change.timestamp)}</h3>
              <List>
                {Object.entries(JSON.parse(single_change?.changes)).map(
                  ([key, [oldValue, newValue]]) => (
                    <ListItemButton key={key}>
                      <ListItemIcon>
                        <PublishedWithChangesIcon />
                      </ListItemIcon>
                      <strong>{key}: </strong> {oldValue} {"-->"} {newValue}
                    </ListItemButton>
                  )
                )}
              </List>
            </Card>
          ))}
        </>
      )}
    </MDBox>
  );
}
