import React, { createContext, useReducer } from "react";

const SET_COUPONS_ACTIONS = {
  SET_COUPONS: "SET_COUPONS",
  CLEAR_COUPONS: "CLEAR_COUPONS",
};

const initialState = {
  coupons: null,
};

const couponsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COUPONS_ACTIONS.SET_COUPONS:
      return { ...state, coupons: payload };
    case SET_COUPONS_ACTIONS.CLEAR_COUPONS:
      return initialState;
    default:
      return state;
  }
};

const CouponsContext = createContext();

const CouponsContextProvider = ({ children }) => {
  const [{ coupons }, dispatch] = useReducer(couponsReducer, initialState);

  const setCoupons = (coupons) => {
    dispatch({ type: SET_COUPONS_ACTIONS.SET_COUPONS, payload: coupons });
  };

  const clearCoupons = () => {
    dispatch({ type: SET_COUPONS_ACTIONS.CLEAR_COUPONS });
  };

  const value = { coupons, setCoupons, clearCoupons };
  return <CouponsContext.Provider value={value}>{children}</CouponsContext.Provider>;
};

export { CouponsContext, CouponsContextProvider };
