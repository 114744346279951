import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Link } from "react-router-dom";

export const OrderItem = (props) => {
  const { orderProducts, total, total_usd, coupon, total_after_discount, delivery_costs } = props;

  const priceOfAllProduct = (orderProducts) => {
    if (orderProducts) {
      const totalPrices = [];
      let priceOfAllProductSum = 0;
      Array.from(orderProducts)?.forEach((orderProduct) => {
        totalPrices.push(parseFloat(orderProduct.quantity * orderProduct.product.price));
      });
      totalPrices?.forEach((price) => {
        priceOfAllProductSum += price;
      });

      return priceOfAllProductSum;
    }
  };

  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: "16px", boxShadow: "none", overflow: "visible" }}
      className="for-print-table"
    >
      <Table sx={{ minWidth: 650 }} size="small">
        <TableBody>
          {/* headers */}
          <TableRow>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              Product
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              model
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              Price
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              Quantity
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              Available Quantity
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              Total
            </TableCell>
            <TableCell style={{ border: "1px solid #e0e0e0", fontWeight: "bold" }} align="left">
              Product Image
            </TableCell>
          </TableRow>

          {/* date */}

          {orderProducts?.map((orderProduct) => (
            <TableRow>
              <TableCell style={{ border: "1px solid #e0e0e0" }} align="left">
                <Link
                  to={`/catalog/products/edit/${orderProduct.product.product_id}`}
                  target="_blank"
                >
                  {orderProduct.product.description.name
                    ? orderProduct.product.description.name
                    : "N/A"}
                </Link>
              </TableCell>
              <TableCell style={{ border: "1px solid #e0e0e0" }} align="left">
                {orderProduct.product?.model}
              </TableCell>

              <TableCell style={{ border: "1px solid #e0e0e0" }} align="left">
                IQD {parseFloat(orderProduct.product.price)}
              </TableCell>
              <TableCell style={{ border: "1px solid #e0e0e0" }} align="left">
                {orderProduct.quantity}
              </TableCell>
              <TableCell style={{ border: "1px solid #e0e0e0" }} align="left">
                {orderProduct.product.available_quantity}
              </TableCell>

              <TableCell style={{ border: "1px solid #e0e0e0" }} align="left">
                IQD {parseFloat(orderProduct.quantity * orderProduct.product.price)}
              </TableCell>

              <TableCell style={{ border: "1px solid #e0e0e0", width: "auto" }} align="center">
                <img
                  alt={
                    orderProduct.product.description.name
                      ? orderProduct.product.description.name
                      : "N/A"
                  }
                  src={orderProduct.product.image}
                  width="120px"
                  height="auto"
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow style={{ border: "2px solid #e0e0e0" }}>
            <TableCell>total</TableCell>
            <TableCell align="center">{total}</TableCell>
          </TableRow>
          <TableRow style={{ border: "2px solid #e0e0e0" }}>
            <TableCell>total usd</TableCell>
            <TableCell align="center">{total_usd}</TableCell>
          </TableRow>
          <TableRow style={{ border: "2px solid #e0e0e0" }}>
            <TableCell>coupon</TableCell>
            <TableCell align="center">{coupon}</TableCell>
          </TableRow>
          <TableRow style={{ border: "2px solid #e0e0e0" }}>
            <TableCell>delivery cost</TableCell>
            <TableCell align="center">{delivery_costs}</TableCell>
          </TableRow>
          <TableRow style={{ border: "2px solid #e0e0e0" }}>
            <TableCell>total after discount</TableCell>
            <TableCell align="center">{total_after_discount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
