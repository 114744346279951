// Otis Admin PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import HomeSection from "layouts/dashboards/home-section";

import OrdersList from "layouts/sales/orders/orders-list";
import RequestsList from "layouts/sales/orders/requests-list";

import BrandsList from "layouts/ecommerce/brands/brand-list/index";
import BrandCreate from "layouts/ecommerce/brands/brand-create";

import CategoryCreate from "layouts/catalog/categories/category-create";
import CategoriesList from "layouts/catalog/categories/categories-list";

import NewProduct from "layouts/catalog/products/new-product";
import ProductsList from "layouts/catalog/products/products-list";
import ProductsAttributesGroupsList from "layouts/catalog/products/attributesGroups/attributes-groups-list";
import ProductsAttributesList from "layouts/catalog/products/attributes/attributes-list";

import DeliveryCostsList from "layouts/sales/delivery-costs/delivery-costs-list";

import PointsCouponsList from "layouts/ecommerce/points-coupons/coupons-list";
import PointsCouponCreate from "layouts/ecommerce/points-coupons/coupon-create";

import CurrencyExchange from "layouts/settings/currency-exchange";

import UsersList from "layouts/users/users-list";
import AdminsList from "layouts/users/admins-users/admins-list";

import CouponsList from "layouts/ecommerce/general-coupons/coupons-list";

import CouponCreate from "layouts/ecommerce/general-coupons/coupon-create";

import BannersList from "layouts/design/banners/banners-list";
import BannerCreate from "layouts/design/banners/banner-create";

import NotesList from "layouts/for-app/notes/notes-list";

import NotificationList from "layouts/dashboards/notification/notification-list";

import CustomerMembership from "layouts/users/customer-membership";

// Otis Admin PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images

import NoteCreate from "layouts/for-app/notes/note-create";
import RanksList from "layouts/users/user-ranking/ranks-list";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      // {
      //   name: "Analytics",
      //   key: "analytics",
      //   route: "/dashboards/analytics",
      //   icon: <Icon fontSize="medium">receipt_long</Icon>,
      //   component: <Analytics />,
      // },
      {
        name: "Home Section",
        key: "home-section",
        route: "/dashboards/home-section",
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        component: <HomeSection />,
      },
      {
        name: "Notifications",
        key: "notifications",
        route: "/dashboards/notifications",
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        component: <NotificationList />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },

  {
    type: "collapse",
    name: "Catalog",
    key: "catalog",
    icon: <Icon fontSize="medium">category</Icon>,
    collapse: [
      // {
      //   name: "Categories",
      //   key: "categories",
      //   collapse: [
      //     {
      //       name: "Categories List",
      //       key: "categories-list",
      //       route: "/catalog/categories",
      //       component: <CategoriesList />,
      //     },
      //     {
      //       name: "New Category",
      //       key: "new-category",
      //       route: "/catalog/categories/create",
      //       component: <CategoryCreate />,
      //     },
      //   ],
      // },
      {
        name: "Categories List",
        key: "categories",
        route: "/catalog/categories",
        component: <CategoriesList />,
      },
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "Products List",
            key: "products-list",
            route: "/catalog/products",
            component: <ProductsList />,
          },
          // {
          //   name: "New Product",
          //   key: "new-product",
          //   route: "/catalog/products/create",
          //   component: <NewProduct />,
          // },
          {
            name: "Attributes Group",
            key: "product-attributes-group",
            route: "/catalog/products/attributes-group",
            component: <ProductsAttributesGroupsList />,
          },
          {
            name: "Attributes",
            key: "product-attributes",
            route: "/catalog/products/attributes",
            component: <ProductsAttributesList />,
          },
        ],
      },
    ],
  },

  {
    type: "collapse",
    name: "Design",
    key: "design",
    icon: <Icon fontSize="medium">brush</Icon>,
    collapse: [
      // {
      //   name: "Banners",
      //   key: "banners",
      //   collapse: [
      //     {
      //       name: "Banners List",
      //       key: "banners-list",
      //       route: "/design/banners",
      //       component: <BannersList />,
      //     },
      //     {
      //       name: "New Banner",
      //       key: "new-banner",
      //       route: "/design/banners/create",
      //       component: <BannerCreate />,
      //     },
      //   ],
      // },
      {
        name: "Banners List",
        key: "banners",
        route: "/design/banners",
        component: <BannersList />,
        icon: <Icon fontSize="medium">receipt_long</Icon>,
      },
    ],
  },

  {
    type: "collapse",
    name: "Sales",
    key: "sales",
    icon: <Icon fontSize="medium">real_estate_agent</Icon>,
    collapse: [
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Orders List",
            key: "orders-list",
            route: "/sales/orders",
            component: <OrdersList />,
          },
          {
            name: "Request List",
            key: "requests-list",
            route: "/sales/requestsList",
            component: <RequestsList />,
          },
        ],
      },
      {
        name: "Delivery Cost",
        key: "delivery-cost",
        route: "/sales/delivery-cost",
        component: <DeliveryCostsList />,
      },
      // {
      //   name: "Update Delivery Cost",
      //   key: "update-delivery-cost",
      //   route: "/sales/delivery-cost/update",
      //   component: <DeliveryCostUpdate />,
      // },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Brands",
  //       key: "brands",
  //       collapse: [
  //         {
  //           name: "Brands List",
  //           key: "brands-list",
  //           route: "/ecommerce/brands",
  //           component: <BrandsList />,
  //         },
  //         {
  //           name: "New Brand",
  //           key: "new-brand",
  //           route: "/ecommerce/brands/create",
  //           component: <BrandCreate />,
  //         },
  //       ],
  //     },

  //     {
  //       name: "Coupons",
  //       key: "coupons",
  //       collapse: [
  //         {
  //           name: "Coupons List",
  //           key: "general-coupons-list",
  //           route: "/ecommerce/coupons",
  //           component: <CouponsList />,
  //         },
  //         {
  //           name: "New Coupon",
  //           key: "new-general-coupon",
  //           route: "/ecommerce/coupons/create",
  //           component: <CouponCreate />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Points System",
  //       key: "points-system",
  //       collapse: [
  //         {
  //           name: "Coupons List",
  //           key: "points-coupons",
  //           route: "/ecommerce/points-coupons",
  //           component: <PointsCouponsList />,
  //         },
  //         {
  //           name: "New Coupon",
  //           key: "new-points-coupon",
  //           route: "/ecommerce/points-coupons/create",
  //           component: <PointsCouponCreate />,
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Brands List",
        key: "brands-list",
        route: "/ecommerce/brands-list",
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        component: <BrandsList />,
      },
      {
        name: "Coupons List",
        key: "general-coupons-list",
        route: "/ecommerce/general-coupons-list",
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        component: <CouponsList />,
      },
      {
        name: "points coupons",
        key: "points-coupons",
        route: "/ecommerce/points-coupons",
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        component: <PointsCouponsList />,
      },
    ],
  },

  { type: "title", title: "Customers", key: "title-users" },
  {
    type: "collapse",
    name: "customers",
    key: "customers",
    icon: <Icon fontSize="medium">group</Icon>,
    collapse: [
      {
        name: "customers List",
        key: "customers",
        route: "/customers",
        component: <UsersList />,
      },
      {
        name: "customer membership",
        key: "membership",
        route: "/customers/membership",
        component: <CustomerMembership />,
      },
      {
        name: "admins List",
        key: "admins",
        route: "/admins",
        component: <AdminsList />,
      },
      {
        name: "ranks List",
        key: "ranks",
        route: "/ranks",
        component: <RanksList />,
      },
    ],
  },
  { type: "title", title: "Settings", key: "title-settings" },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Currency Exchange",
        key: "currency-exchange",
        route: "/settings/currency-exchange",
        component: <CurrencyExchange />,
      },
    ],
  },
  {
    type: "collapse",
    name: "For App",
    key: "for-app",
    icon: <Icon fontSize="medium">phone_android</Icon>,
    collapse: [
      {
        name: "Important Notes",
        key: "important-notes",
        collapse: [
          {
            name: "Notes",
            key: "notes",
            route: "/notes/notes-list",
            component: <NotesList />,
          },
          {
            name: "New Note",
            key: "create",
            route: "/notes/create",
            component: <NoteCreate />,
          },
        ],
      },
    ],
  },
];

export default routes;
