import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

import "app.css";

// Post brand method

import { PostAdminBrand } from "utils/apis/ecommerce/brands";

import InputFileAndPreview from "elements/InputFileAndPreview";

function BrandCreate() {
  const [brandName, setBrandName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [sortOrder, setSortOrder] = useState();
  const [enabled, setEnabled] = useState(true);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // set brand name state
  const handleBrandNameChange = (event) => {
    setBrandName(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleEnableOrDisableChange = (event) => {
    if (event.target.value == "enable") setEnabled(true);
    else if (event.target.value == "disable") setEnabled(false);
  };

  // set image state
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  // handle create brand submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate brand name and image
    if (!brandName) {
      return setErrorMSG("Please fill all the fields");
    }

    /// send post brand request
    const result = await PostAdminBrand({
      name: brandName ? brandName : "",
      image: selectedImage,
      sortOrder,
      enabled,
    });

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Brand created successfuly");
        // navigate to brands list page
        return navigate("/ecommerce/brands-list");
      } else {
        console.log(result.data);
        return setErrorMSG(result.data.error);
      }
    } else {
      console.log(result);
      if (result?.response?.data?.error) return setErrorMSG(result.response.data.error);
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={2} mb={2} textAlign="center">
              <MDBox>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Brand
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New Brand
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    fullWidth
                    label="Brand Name"
                    value={brandName}
                    onChange={handleBrandNameChange}
                    type="text"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    fullWidth
                    type="number"
                    label="Sort Order"
                    name="sortOrder"
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl>
                    {/* <FormLabel id="enable-or-disable-brand-create">Notification For</FormLabel> */}
                    <MDTypography>please be careful with that</MDTypography>
                    <RadioGroup
                      row
                      aria-labelledby="enable-or-disable-brand-create"
                      name="enable-or-disable-brand-create-group"
                      onChange={handleEnableOrDisableChange}
                    >
                      <FormControlLabel
                        value="enable"
                        control={<Radio checked={enabled} />}
                        label="Enable"
                      />
                      <FormControlLabel
                        value="disable"
                        control={<Radio checked={!enabled} />}
                        label="Disable"
                      />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput type="file" onChange={handleImageUpload} />
                </MDBox> */}
                <MDBox mb={2} sx={{ maxWidth: "300px" }}>
                  <InputFileAndPreview handleImageUpload={handleImageUpload} file={selectedImage} />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BrandCreate;
