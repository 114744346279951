import React, { createContext, useReducer } from "react";

const SET_USER_ACTIONS = {
  SET_USER: "SET_USER",
  CLEAR_USER: "CLEAR_USER",
};

const savedUserString = localStorage.getItem("user");
const savedUser = JSON.parse(savedUserString);

// Initial state of the user context

const initialState = {
  user: savedUser ? savedUser : null,
};
// User context reducer function
const userReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_ACTIONS.SET_USER:
      return { ...state, user: payload };
    case SET_USER_ACTIONS.CLEAR_USER:
      return { ...state, user: null };
    default:
      return state;
  }
};

// Create the user context
const UserContext = createContext();

// User context provider component
const UserContextProvider = ({ children }) => {
  const [{ user }, dispatch] = useReducer(userReducer, initialState);

  // Action to set the user context
  const setUser = (user) => {
    dispatch({ type: SET_USER_ACTIONS.SET_USER, payload: user });
  };

  // Action to clear the user context
  const clearUser = () => {
    dispatch({ type: SET_USER_ACTIONS.CLEAR_USER });
  };

  const value = { user, setUser, clearUser };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
