import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable";

// Data table headers
import { requestsListHeaders } from "./data/dataTableData";
// Request Utils
import { UserContext } from "context/user";
import { useContext, useEffect } from "react";
// import { FetchAdminOrders } from "../../../../utils/apis/ecommerce/orders";

import CircularProgress from "@mui/material/CircularProgress";

// import { OrdersContext } from "context/orders";
import { NotificationsContext } from "context/notifications";

import { displayBasicDate } from "utils/methods";

function RequestsList() {
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Local State
  //const { orders, setOrders } = useContext(OrdersContext);

  // test state

  const [requestsList, setRequestsList] = useState(null);

  useEffect(() => {
    const fetchRequests = async () => {
      const requestsList = [
        {
          id: 1,
          customerName: "John Doe",
          productName: "Widget",
          productModel: "Model X",
          requiredQuantity: 5,
          date_added: "2023-08-01",
        },
        {
          id: 2,
          customerName: "Jane Smith",
          productName: "Gadget",
          productModel: "Model Y",
          requiredQuantity: 10,
          date_added: "2023-08-02",
        },
        {
          id: 3,
          customerName: "Michael Johnson",
          productName: "Widget",
          productModel: "Model Z",
          requiredQuantity: 3,
          date_added: "2023-08-03",
        },
        {
          id: 4,
          customerName: "Emily Williams",
          productName: "Tool",
          productModel: "Model A",
          requiredQuantity: 8,
          date_added: "2023-08-04",
        },
        {
          id: 5,
          customerName: "Robert Brown",
          productName: "Gadget",
          productModel: "Model B",
          requiredQuantity: 2,
          date_added: "2023-08-05",
        },
        {
          id: 6,
          customerName: "Sophia Lee",
          productName: "Widget",
          productModel: "Model C",
          requiredQuantity: 7,
          date_added: "2023-08-06",
        },
        {
          id: 7,
          customerName: "William Davis",
          productName: "Tool",
          productModel: "Model D",
          requiredQuantity: 4,
          date_added: "2023-08-07",
        },
        {
          id: 8,
          customerName: "Olivia Martinez",
          productName: "Gadget",
          productModel: "Model E",
          requiredQuantity: 6,
          date_added: "2023-08-08",
        },
        {
          id: 9,
          customerName: "James Rodriguez",
          productName: "Widget",
          productModel: "Model F",
          requiredQuantity: 1,
          date_added: "2023-08-09",
        },
        {
          id: 10,
          customerName: "Isabella Wilson",
          productName: "Tool",
          productModel: "Model G",
          requiredQuantity: 9,
          date_added: "2023-08-10",
        },
        {
          id: 11,
          customerName: "Noah Taylor",
          productName: "Gadget",
          productModel: "Model H",
          requiredQuantity: 3,
          date_added: "2023-08-11",
        },
        {
          id: 12,
          customerName: "Emma Anderson",
          productName: "Widget",
          productModel: "Model I",
          requiredQuantity: 5,
          date_added: "2023-08-12",
        },
        {
          id: 13,
          customerName: "Liam Hernandez",
          productName: "Tool",
          productModel: "Model J",
          requiredQuantity: 2,
          date_added: "2023-08-13",
        },
        {
          id: 14,
          customerName: "Ava Martin",
          productName: "Gadget",
          productModel: "Model K",
          requiredQuantity: 4,
          date_added: "2023-08-14",
        },
        {
          id: 15,
          customerName: "Logan Green",
          productName: "Widget",
          productModel: "Model L",
          requiredQuantity: 6,
          date_added: "2023-08-15",
        },
        {
          id: 16,
          customerName: "Mia Perez",
          productName: "Tool",
          productModel: "Model M",
          requiredQuantity: 8,
          date_added: "2023-08-16",
        },
        {
          id: 17,
          customerName: "Ethan Evans",
          productName: "Gadget",
          productModel: "Model N",
          requiredQuantity: 1,
          date_added: "2023-08-17",
        },
        {
          id: 18,
          customerName: "Amelia Turner",
          productName: "Widget",
          productModel: "Model O",
          requiredQuantity: 7,
          date_added: "2023-08-18",
        },
        {
          id: 19,
          customerName: "Aiden Collins",
          productName: "Tool",
          productModel: "Model P",
          requiredQuantity: 9,
          date_added: "2023-08-19",
        },
        {
          id: 20,
          customerName: "Charlotte Rivera",
          productName: "Gadget",
          productModel: "Model Q",
          requiredQuantity: 3,
          date_added: "2023-08-20",
        },
      ];

      setRequestsList({
        data: requestsList,
      });

      //const result = await FetchAdminOrders(user);

      // if (!(result instanceof Error)) {
      //   if (!result.data.error && result.data) {
      //     const { results, next, previous, count } = result.data;
      //     return setOrders({
      //       count,
      //       next,
      //       previous,
      //       data: results,
      //     });
      //   } else {
      //     return setErrorMSG(result.data.error);
      //   }
      // } else {
      //   return setErrorMSG(result.message);
      // }
    };

    fetchRequests();
  }, []);

  // TODO:

  const handleFilter = (val) => {
    //const newFilterdOrders = componentOrders?.filter(order => order.)
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => handleFilter("Paid")}>Status: Paid</MenuItem>
      <MenuItem onClick={() => handleFilter("Refunded")}>Status: Refunded</MenuItem>
      <MenuItem onClick={() => handleFilter("Canceled")}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDTypography>Requests not available</MDTypography>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          {!requestsList?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {requestsList?.data && (
            <DataTable
              table={{
                columns: requestsListHeaders,
                rows: requestsList.data.map((request) => ({
                  id: request.id,
                  customer: request.customerName,
                  productName: request.productName,
                  model: request.productModel,
                  requiredQuantity: request.requiredQuantity,
                  dateAdded: request.date_added,
                  actions: request.id,
                })),
              }}
              canSearch
              // nextPageUrl={orders.next}
              // previousPageUrl={orders.previous}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RequestsList;
