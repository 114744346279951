import { useEffect, useState } from "react";
import { useContext } from "react";
import { GetAndSearchAdminBrands } from "utils/apis/ecommerce/brands";
import { Autocomplete, TextField } from "@mui/material";

import MDInput from "components/MDInput";
import { NotificationsContext } from "context/notifications";
import { GetAdminBrandById } from "utils/apis/ecommerce/brands";

export default function BrandAutocomplete({
  selectedBrand,
  setSelectedBrand,
  makeSelectByThisBrandId = null,
}) {
  const { setErrorMSG } = useContext(NotificationsContext);

  const [brands, setBrands] = useState();
  //   const [selectedBrand, setSelectedBrand] = useState();
  const [brandsSearchKey, setBrandsSearchKey] = useState(null);

  const handleSelectBrandChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedBrand(value);
    } else {
      setSelectedBrand(null);
    }

    if (reason === "clear" && !brandsSearchKey) {
      setSelectedBrand(null);
    }

    if (reason === "clear" && brandsSearchKey) {
      setSelectedBrand(null);

      setBrandsSearchKey(null);
    }
  };

  const handleBrandSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setBrandsSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setBrandsSearchKey(null);
    }
  };

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchBrands = async () => {
      try {
        const result = await GetAndSearchAdminBrands({ search: brandsSearchKey });

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha brands as auto complete item
          const brandsItems = results?.map((brand) => ({
            label: brand.name,
            manufacturer_id: brand.manufacturer_id,
          }));

          // set the brands options state
          setBrands(brandsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchBrands();
  }, [brandsSearchKey]);

  useEffect(() => {
    if (makeSelectByThisBrandId) {
      const fetchThisBrand = async () => {
        try {
          const brandResult = await GetAdminBrandById(makeSelectByThisBrandId);
          if (brandResult.status === 200 && brandResult.data) {
            const { data } = brandResult;

            const brandOfThisId = {
              label: data?.name,
              manufacturer_id: data.manufacturer_id,
            };

            setSelectedBrand(brandOfThisId);
          }
        } catch (error) {
          console.log(error);
          setErrorMSG("something went wrong");
        }
      };
      fetchThisBrand();
    }
  }, [makeSelectByThisBrandId]);

  return (
    <Autocomplete
      // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
      value={selectedBrand ? selectedBrand : null}
      onChange={handleSelectBrandChange}
      onInputChange={handleBrandSearch}
      options={brands ? brands : []}
      renderInput={(params) => <MDInput label="Select Brand" {...params} variant="standard" />}
    />
  );
}
