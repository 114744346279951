import { useEffect, useState } from "react";
import { useContext } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { NotificationsContext } from "context/notifications";
import { FetchAdminCategories } from "utils/apis/catalog/categories";

import MDInput from "components/MDInput";
import { FetchAdminCategoryById } from "utils/apis/catalog/categories";

export default function CategoryAutocomplete({
  selectedCategory,
  setSelectedCategory,
  makeSelectByThisCategoryId = null,
}) {
  const { setErrorMSG } = useContext(NotificationsContext);
  const [categories, setCategories] = useState();
  //   const [selectedCategory, setSelectedCategory] = useState();
  const [categorySearchKey, setCategorySearchKey] = useState(null);

  const handleCategorySearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 2) {
      setCategorySearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setCategorySearchKey(null);
    }
  };

  const handleSelectCategoryChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setSelectedCategory(value);
    } else {
      setSelectedCategory(null);
    }

    if (reason === "clear" && !categorySearchKey) {
      setSelectedCategory(null);
    }

    if (reason === "clear" && categorySearchKey) {
      setSelectedCategory(null);

      setCategorySearchKey(null);
    }
  };

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchCategories = async () => {
      try {
        let searchKeyObject;
        if (!categorySearchKey) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              name: categorySearchKey,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }
        const result = await FetchAdminCategories(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha categories as auto complete item
          const categoriesItems = results?.map((category) => ({
            label: category.description[0].name,
            category_id: category.category_id,
          }));

          // set the categories options state
          setCategories(categoriesItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchCategories();
  }, [categorySearchKey]);

  useEffect(() => {
    if (makeSelectByThisCategoryId) {
      const fetchThisCategory = async () => {
        try {
          const categoryResult = await FetchAdminCategoryById(makeSelectByThisCategoryId);
          if (categoryResult.status === 200 && categoryResult.data) {
            const { data } = categoryResult;

            const categoryOfThisId = {
              label: data?.description[0].name,
              category_id: data?.category_id,
            };
            console.log(categoryOfThisId);
            // set the categories options state
            setSelectedCategory(categoryOfThisId);
          }
        } catch (error) {
          console.log(error);
          setErrorMSG("something went wrong");
        }
      };
      fetchThisCategory();
    }
  }, [makeSelectByThisCategoryId]);

  return (
    <Autocomplete
      // getOptionSelected={(option, value) => option.id === value.manufacturer_id}
      value={selectedCategory ? selectedCategory : null}
      onChange={handleSelectCategoryChange}
      onInputChange={handleCategorySearch}
      options={categories ? categories : []}
      renderInput={(params) => <MDInput label="Select Category" {...params} variant="standard" />}
    />
  );
}
