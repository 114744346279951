// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
import FormField from "layouts/catalog/products/new-product/components/FormField";

function Dimensions(props) {
  const { localState, setParentState } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;

    setParentState((previouseState) => {
      return {
        ...previouseState,
        step3: {
          ...previouseState.step3,
          [name]: value,
        },
      };
    });
  };
  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Dimensions
      </MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <FormField
              defaultValue={localState.weight}
              type="number"
              name="weight"
              label="Weight"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField
              defaultValue={localState.width}
              type="number"
              name="width"
              label="Width"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField
              defaultValue={localState.height}
              type="number"
              name="height"
              label="Height"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField
              defaultValue={localState.length}
              type="number"
              name="length"
              label="Length"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Dimensions;
