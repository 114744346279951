import { useState, useContext, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// NewProduct page components
import ProductInfo from "./components/ProductInfo";
import Media from "./components/Media";
import Dimensions from "./components/Dimensions";
import Pricing from "./components/Pricing";
import OtherStep from "./components/Other";

import { CircularProgress } from "@mui/material";
import NotFound404 from "assets/images/404.gif";

import { NotificationsContext } from "context/notifications";

import { FetchAdminProductById, EditAdminProduct } from "utils/apis/catalog/products/products";
import { convertProductStatusStringToId, convertProductStatusIdToString } from "utils/methods";

import { UserContext } from "context/user";

import { useNavigate, useParams } from "react-router-dom";
import { AddProductAttribute } from "utils/apis/catalog/products/productsAttributes";
import MDBadge from "components/MDBadge";
import { AddProductAttributes } from "utils/apis/catalog/products/productsAttributes";

function getSteps() {
  return ["1. Product Info", "2. Pricing & Quantity", "3. Dimensions", "4. Other"];
}

function getStepContent(stepIndex, state) {
  const { formData, setFormData } = state;
  switch (stepIndex) {
    case 0:
      return <ProductInfo localState={formData.step1} setParentState={setFormData} />;
    // case 1:
    //   return <Media localState={formData.step2} setParentState={setFormData} />;
    case 1:
      return <Pricing localState={formData.step2} setParentState={setFormData} />;
    case 2:
      return <Dimensions localState={formData.step3} setParentState={setFormData} />;
    case 3:
      return <OtherStep localState={formData.otherStep} setParentState={setFormData} />;
    default:
      return null;
  }
}

function ProductEdit() {
  const { id } = useParams();

  // old product

  const [product, setProduct] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // set not found message

  const [notfoundMSG, setNotFoundMSG] = useState(null);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const { setErrorMSG, setSuccessMSG, clearErrorMSG } = useContext(NotificationsContext);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  // handle next button
  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      step1: formData.step1,
    }));
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  // Global State of the Stepper
  const [formData, setFormData] = useState({
    step1: {
      nameEnglish: "",
      nameArabic: "",
      description: "",
      categories: [],
      mainCategories: [],
      subCategories: [],
      brand: "", // there is no brand for this product
      model: "",
    },

    step2: {
      price: "",
      discountPrice: "",
      available_quantity: "",
      discount_start_date: "",
      discount_expiry_date: "",
      points: "",
    },
    step3: {
      weight: "",
      width: "",
      height: "",
      length: "",
    },

    otherStep: {
      status: "",
      attributes: [],
    },

    // Add more steps as needed
  });

  // fetch the product by id

  useEffect(() => {
    if (id) {
      const getProductById = async () => {
        // set loading true
        setLoadingOrNot(true);

        // fetch the product
        const result = await FetchAdminProductById(id);

        if (!(result instanceof Error)) {
          if (!result.data.error && result.data) {
            setLoadingOrNot(false);

            // old product
            setProduct({
              ...result.data,
            });

            // destructuring the values

            const {
              description,
              manufacturer_id,
              brand_name,
              model,
              price,
              discounted_price,
              available_quantity,
              weight,
              width,
              height,
              length,
              status,
              categories,
              product_attributes,
              discount_start_date,
              discount_expiry_date,
              points,
            } = result.data;

            // set the default values so user can see the current values of a product and change them if he want
            setFormData((previousState) => {
              return {
                ...previousState,
                step1: {
                  // ...previousState.step1,
                  // english description only
                  description: description.length > 0 ? description[0].description : "",
                  nameArabic: description.length > 0 ? description[0].name : "",
                  nameEnglish: description.length > 0 ? description[1].name : "",
                  brand: { manufacturer_id: manufacturer_id, label: brand_name },
                  model,
                  categories: categories.map((category) => ({
                    category_id: category.category_id,
                    label: category.name,
                  })),
                  mainCategories: [],
                  subCategories: [],
                },

                /// step 2 is seperated enpoint for images

                step2: {
                  ...previousState.step2,
                  price,
                  discountPrice: discounted_price ? discounted_price : 0,
                  available_quantity,
                  discount_start_date,
                  discount_expiry_date,
                  points,
                },

                step3: {
                  ...previousState.step3,
                  weight,
                  width,
                  height,
                  length,
                },

                otherStep: {
                  ...previousState.otherStep,
                  status: convertProductStatusIdToString(status),
                  attributes: product_attributes ? product_attributes : [],
                },
              };
            });
          } /// if there is an error inside data.error
          else {
            return setErrorMSG(result.data.error);
          }
        } else {
          /// if the response was an error
          return setErrorMSG(result.message);
        }
      };

      getProductById();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // clear validation messages
    clearErrorMSG();

    // validate required fields
    const { model, categories, brand, nameEnglish, nameArabic, description } = formData.step1;

    const {
      available_quantity,
      price,
      discountPrice,
      discount_start_date,
      discount_expiry_date,
      points,
    } = formData.step2;
    const { weight, length, width, height } = formData.step3;
    const { status, attributes } = formData.otherStep;

    // these are required fields
    if (!model || !available_quantity || !price) {
      return setErrorMSG("Please fill all the required fields");
    }

    // reform the attributes to take just the group id from
    // const reformedAttributes = attributes.map((attribute) => ({
    //   text: attribute.text,
    //   description: attribute.description,
    //   language_id: 1, // default english
    //   attributes_group_id: attribute.attributes_group.id,
    // }));
    // reform the categories to take just the category id from

    const reformedCategories = categories.map((category) => category.category_id);

    let statusId = convertProductStatusStringToId(status);

    const productBody = {
      model,
      available_quantity,
      price,
      discounted_price: discountPrice,
      discount_start_date,
      discount_expiry_date,
      points,
      weight,
      length,
      width,
      height,
      status: statusId,
      categories: reformedCategories,

      manufacturer_id: brand.manufacturer_id,
      description: [
        {
          name: nameEnglish,
          description: description,
          language_id: 1,
        },
        {
          name: nameArabic,
          description: description,
          language_id: 2,
        },
      ],
    };

    //send post request to create product
    const result = await EditAdminProduct(id, productBody);

    if (result.status === 200) {
      const reformedAttributes = attributes.map((attribute) => ({
        text: attribute.text,
        product_id: id,
        attribute_id: attribute.attributeId,
        language_id: 1,
      }));

      const R = await AddProductAttributes(reformedAttributes);

      // show success notification
      setSuccessMSG("Product updated successfuly");
      // navigate to products list page
      navigate("/catalog/products");
    } else {
      setErrorMSG("Something went wrong");
    }
  };

  return (
    <MDBox mb={4}>
      <Grid container justifyContent="center">
        {loadingOrNot ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center" mb={4}>
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Edit Product ({formData.step1?.nameEnglish} - {formData.step1?.nameArabic}){" "}
                  {formData?.otherStep?.status == "NEW" && (
                    <MDBadge badgeContent="New" color={"success"} container size="xs" />
                  )}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              {product ? (
                <>
                  <MDBox mt={-3} mb={3} mx={2}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox>
                      {getStepContent(activeStep, { formData, setFormData })}
                      <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton variant="gradient" color="light" onClick={handleBack}>
                            back
                          </MDButton>
                        )}
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={!isLastStep ? handleNext : handleSubmit}
                        >
                          {isLastStep ? "Edit" : "next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  my={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <MDBox component="img" src={NotFound404} />
                </MDBox>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}

export default ProductEdit;
