import ShaghlatyImagePlaceholder from "assets/images/for-signin.png";

export const displayBasicDate = (timestamp) => {
  // split it to remove empty spaces
  const splitedTimestamp = timestamp.split();
  const dateOb = new Date(splitedTimestamp);

  // get the hour
  const hour = dateOb.getUTCHours();

  // get the minute
  const minute = dateOb.getUTCMinutes();

  // get the year
  const year = dateOb.getUTCFullYear();

  // get the month ** this function return the previous month so always i increas it by 1 to fit with our local date standers
  const month = dateOb.getUTCMonth() + 1;

  // get the day
  const day = dateOb.getUTCDate();

  // return the basic date format
  const basicDate = `${year}-${month}-${day} : ${hour}:${minute} `;

  return basicDate;
};

export const formatDateToISO = (dateString) => {
  const dt = new Date(dateString);
  const isoFormat = dt.toISOString();
  return isoFormat;
};

export const convertStatusIdToString = (statusId) => {
  const statusNumber = Number(statusId);

  switch (statusNumber) {
    case 1:
      return "New Order";
    case 5:
      return "Completed";
    case 7:
      return "Cancelled Order";
    case 11:
      return "Refunded";
    default:
      return "unknown";
  }
};

export const convertProductStatusStringToId = (statusString) => {
  switch (statusString) {
    case "NEW":
      return 0;
    case "PROMOTED":
      return 1;
    case "FEATURED":
      return 2;
    case "DISCOUNT":
      return 3;
    default:
      return 0;
  }
};

export const convertProductStatusIdToString = (statusId) => {
  const statusNumber = Number(statusId);
  switch (statusNumber) {
    case 0:
      return "NEW";
    case 1:
      return "PROMOTED";
    case 2:
      return "FEATURED";
    case 3:
      return "DISCOUNT";
    default:
      return 0;
  }
};

export const giveMeDefaultFile = async (url = ShaghlatyImagePlaceholder) => {
  try {
    // Fetch the image data
    const response = await fetch(url);

    // Get the image blob
    const blob = await response.blob();

    // Create a new File object
    const file = new File([blob], "image.png", { type: "image/png" });

    return file;
  } catch (error) {
    console.error("Error converting image to File:", error);
    return null;
  }
};
