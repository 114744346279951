import { useState, useContext, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { AddProductAttribute } from "utils/apis/catalog/products/productsAttributes";
import { convertProductStatusStringToId } from "utils/methods";

// NewProduct page components
import ProductInfo from "./components/ProductInfo";
import Media from "./components/Media";
import Dimensions from "./components/Dimensions";
import Pricing from "./components/Pricing";
import OtherStep from "./components/Other";

import { ProductsContext } from "context/products/products";

import { NotificationsContext } from "context/notifications";

import { PostAdminProduct } from "utils/apis/catalog/products/products";

import { UserContext } from "context/user";

import { useNavigate } from "react-router-dom";
import { AddProductAttributes } from "utils/apis/catalog/products/productsAttributes";

function getSteps() {
  return ["1. Product Info", "2. Media", "3. Pricing & Quantity", "4. Dimensions", "5. Other"];
}

function getStepContent(stepIndex, state) {
  const { formData, setFormData } = state;
  switch (stepIndex) {
    case 0:
      return <ProductInfo localState={formData.step1} setParentState={setFormData} />;
    case 1:
      return <Media localState={formData.step2} setParentState={setFormData} />;
    case 2:
      return <Pricing localState={formData.step3} setParentState={setFormData} />;
    case 3:
      return <Dimensions localState={formData.step4} setParentState={setFormData} />;
    case 4:
      return <OtherStep localState={formData.otherStep} setParentState={setFormData} />;
    default:
      return null;
  }
}

function NewProduct() {
  const navigate = useNavigate();

  const { clearProducts } = useContext(ProductsContext);

  const { setErrorMSG, setSuccessMSG, clearErrorMSG } = useContext(NotificationsContext);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  // handle next button
  const handleNext = () => {
    setActiveStep(activeStep + 1);

    // not needed code from gazwan
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   step1: formData.step1,
    // }));
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  // Global State of the Stepper
  const [formData, setFormData] = useState({
    step1: {
      nameEnglish: "",
      nameArabic: "",
      description: "",
      categories: [],
      mainCategories: [],
      subCategories: [],
      brand: "", // there is no brand for this product
      model: "",
    },
    step2: {
      images: "",
    },

    step3: {
      price: "",
      discountPrice: "",
      available_quantity: "",
      discount_start_date: "",
      discount_expiry_date: "",
      points: "",
    },
    step4: {
      weight: "",
      width: "",
      height: "",
      length: "",
    },

    otherStep: {
      status: "",
      attributes: [],
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // clear validation messages
    clearErrorMSG();

    // validate required fields
    const { model, categories, brand, nameEnglish, nameArabic, description } = formData.step1;
    const { images } = formData.step2;

    const {
      available_quantity,
      price,
      discountPrice,
      discount_start_date,
      discount_expiry_date,
      points,
    } = formData.step3;
    const { weight, length, width, height } = formData.step4;
    const { status, attributes } = formData.otherStep;

    // these are required fields
    if (!model || !available_quantity || !price) {
      return setErrorMSG("Please fill all the required fields");
    }

    // reform the attributes to take just the group id from

    // const reformedAttributes = attributes.map((attribute) => ({
    //   text: attribute.text,
    //   description: attribute.description,
    //   language_id: attribute.language_id,
    //   attributes_group_id: attribute.attributes_group.id,
    // }));

    // reform the categories to take just the category id from
    const reformedCategories = categories.map((category) => category.category_id);

    let statusId = convertProductStatusStringToId(status);

    const productBody = {
      model,
      available_quantity: parseInt(available_quantity),
      price: parseInt(price),
      discounted_price: parseInt(discountPrice),
      discount_start_date,
      discount_expiry_date,
      points,
      weight: weight ? parseInt(weight) : 0,
      length: length ? parseInt(length) : 0,
      width: width ? parseInt(width) : 0,
      height: height ? parseInt(height) : 0,
      status: statusId,
      categories: reformedCategories,
      images,
      manufacturer_id: brand.manufacturer_id,
      description: [
        {
          name: nameEnglish,
          description: description,
          language_id: 1,
        },
        {
          name: nameArabic,
          description: description,
          language_id: 2,
        },
      ],
    };

    // send post request to create product

    const result = await PostAdminProduct(productBody);

    if (!(result instanceof Error)) {
      if (!result.data.error && result.status === 201) {
        // after the product is added now we can add attributes to it by making new request
        const productId = result.data?.product_id;

        const reformedAttributes = attributes.map((attribute) => ({
          text: attribute.text,
          product_id: productId,
          attribute_id: attribute.attributeId,
          language_id: 1,
        }));

        const R = await AddProductAttributes(reformedAttributes);

        // show success notification
        setSuccessMSG("Product created successfuly");
        clearProducts();
        // navigate to brands list page
        navigate("/catalog/products");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      console.log(result);
      if (result?.response?.data?.error) return setErrorMSG(result.response.data.error);
      return setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Product
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the product.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep, { formData, setFormData })}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleSubmit}
                    >
                      {isLastStep ? "Create" : "next"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProduct;
