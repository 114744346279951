// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";

function IdCell({ id, arrayOfSelectedProducts, setArrayOfSelectedProducts }) {
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Update the key whenever arrayOfSelectedProducts changes
    setKey((prevKey) => prevKey + 1);
  }, [id]);

  const handleChange = (event) => {
    if (event.target.checked) {
      if (!arrayOfSelectedProducts?.includes(id)) {
        setArrayOfSelectedProducts((prev) => [...prev, id]);
      } else {
        setArrayOfSelectedProducts((prev) => prev.filter((productId) => productId !== id));
      }
    } else {
      setArrayOfSelectedProducts((prev) => prev.filter((productId) => productId !== id));
    }
  };

  return (
    <MDBox display="flex" alignItems="center">
      <Checkbox key={key} onChange={handleChange} />
      <MDBox ml={0.2}>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          #{id}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default value for the props of IdCell
// IdCell.defaultProps = {
//   checked: false,
// };

// Typechecking props for the IdCell
IdCell.propTypes = {
  id: PropTypes.string.isRequired,
  // checked: PropTypes.bool,
};

export default IdCell;
