import BannerImageCell from "../components/BannerImageCell/index";
import ActionsCell from "../components/ActionsCell/index";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";
// Images

export const bannersHeaders = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Sort Order",
    accessor: "sortOrder",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Image",
    accessor: "image",
    Cell: ({ value }) => <BannerImageCell image={value} />,
  },
  // {
  //   Header: "Type",
  //   accessor: "type",
  //   Cell: ({ value }) => <DefaultCell value={value} />,
  // },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
