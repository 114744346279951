import React, { useContext } from "react";
import MDBox from "components/MDBox";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { NotificationsContext } from "context/notifications";
import { EditCustomerMarketerCode } from "utils/apis/users/users";

import MDTypography from "components/MDTypography";

export default function InfluencerInfo({ parentState, upadteParentState }) {
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    upadteParentState((previouseState) => {
      return {
        ...previouseState,
        step3: {
          ...previouseState.step3,
          referral_code: { ...previouseState?.step3?.referral_code, [name]: value },
        },
      };
    });
  };
  const changeCodeMarketer = async () => {
    const newCode = parentState?.referral_code?.code;
    const codeIdToChange = parentState?.referral_code?.id;
    try {
      const result = await EditCustomerMarketerCode(codeIdToChange, newCode);
      if (!(result instanceof Error)) {
        // this is from backend (backend-validations),
        if (!result.data.error) {
          // show success notification
          setSuccessMSG("code updated successfully");
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    } catch (error) {
      setErrorMSG(error);
      console.log(error);
    }
  };
  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Influencer Information
      </MDTypography>

      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox>
              <MDTypography>Influencer Code : {parentState.referral_code?.code}</MDTypography>
              <MDTypography>the number of registered by this influencer : 0</MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={4}>
            <MDInput
              value={parentState.referral_code?.code}
              name="code"
              label="Code Marketer"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" onClick={changeCodeMarketer}>
                Change Marketer Code
              </MDButton>
            </Stack>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
