import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, TextField } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

import NotFound404 from "assets/images/404.gif";
import CircularProgress from "@mui/material/CircularProgress";

import { GetAdminCouponById, EditAdminCoupon } from "utils/apis/ecommerce/coupons";
import { useParams, useNavigate } from "react-router-dom";

function CouponEdit() {
  const { id } = useParams();

  // old  coupon

  const [couponToUpdate, setCouponToUpdate] = useState(null);

  const [loadingOrNot, setLoadingOrNot] = useState(false);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    if (id) {
      const GetCoupon = async () => {
        setLoadingOrNot(true);
        const result = await GetAdminCouponById(id);
        setLoadingOrNot(false);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            const {
              name,
              code,
              discount,
              total_max,
              status,
              date_start,
              date_end,
              for_customer_id,
              type,
              uses_customer,
              uses_total,
            } = result.data;
            return setCouponToUpdate({
              name,
              code,
              discount,
              total_max,
              status,
              date_start,
              date_end,
              for_customer_id,
              type,
              uses_customer,
              uses_total,
            });
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };

      GetCoupon();
    }
  }, [id]);

  // use navigate hook

  const navigate = useNavigate();

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCouponToUpdate({ ...couponToUpdate, [name]: value });
  };

  const handleStatusChange = (_, value, reason) => {
    if (reason === "clear") {
      return setCouponToUpdate((previousState) => ({
        ...previousState,
        status: "",
      }));
    }

    if (reason === "selectOption") {
      if (value) {
        return setCouponToUpdate((previousState) => ({
          ...previousState,
          status: mapTextToEnumValue(value),
        }));
      }
    }
  };

  // handle update coupon submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();

    // type added as come from Get methode because Ezz can not make it not required

    const {
      name,
      code,
      status,
      discount,
      total_max,
      date_start,
      date_end,
      for_customer_id,
      type,
      uses_customer,
      uses_total,
    } = couponToUpdate;

    // Validate name and code, discount, total_max, dates values
    if (!name || !code || !discount || !date_start || !date_end || !status) {
      return setErrorMSG("Please fill all the fields");
    }

    /// send put coupon request
    const result = await EditAdminCoupon(id, {
      name,
      code,
      discount,
      total_max,
      status,
      date_start,
      date_end,
      for_customer_id,
      type,
      uses_customer,
      uses_total,
    });

    // this is not an error
    if (!(result instanceof Error)) {
      if (!result.data.error) {
        //show success notification
        setSuccessMSG("Coupon updated successfuly");
        // navigate to  coupons list page
        navigate("ecommerce/general-coupons-list");
      } else {
        // backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // response error
      return setErrorMSG(result.message);
    }
  };

  function mapTextToEnumValue(text) {
    switch (text) {
      case "Disable":
        return 0;
      case "Enable":
        return 1;
      default:
        return "";
    }
  }

  function mapEnumValueToText(enumValue) {
    switch (enumValue) {
      case 0:
        return "Disable";
      case 1:
        return "Enable";
      default:
        return "";
    }
  }

  const formatDate = (dateString) => {
    const dt = new Date(dateString);
    const isoFormat = dt.toISOString();
    return isoFormat;
  };

  const handleStartDateChange = (_, value) => {
    return setCouponToUpdate((previousState) => ({
      ...previousState,
      date_start: formatDate(value),
    }));
  };

  const handleEndDateChange = (_, value) => {
    return setCouponToUpdate((previousState) => ({
      ...previousState,
      date_end: formatDate(value),
    }));
  };

  return (
    <MDBox mt={1} mb={7}>
      <Grid container justifyContent="center">
        {loadingOrNot ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <Grid item xs={12} lg={8}>
            <MDBox mt={1} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Edit Coupon
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              {couponToUpdate ? (
                <MDBox p={3}>
                  <MDBox mb={2}>
                    <MDTypography variant="h6" fontWeight="bold">
                      Edit Coupon
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      defaultValue={couponToUpdate.name}
                      name="name"
                      fullWidth
                      label="Name"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      fullWidth
                      defaultValue={couponToUpdate.code}
                      name="code"
                      label="Code"
                      onChange={handleChange}
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      sx={{ mr: 1 }}
                      defaultValue={couponToUpdate.discount}
                      name="discount"
                      label="Discount"
                      onChange={handleChange}
                      type="number"
                    />
                    <MDInput
                      sx={{ mr: 1 }}
                      value={couponToUpdate?.uses_total}
                      name="uses_total"
                      label="uses total"
                      onChange={handleChange}
                      type="number"
                    />
                    <MDInput
                      sx={{ mr: 1 }}
                      value={couponToUpdate?.uses_customer}
                      name="uses_customer"
                      label="uses customer"
                      onChange={handleChange}
                      type="number"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      defaultValue={couponToUpdate.total_max}
                      name="total_max"
                      label="Total Max"
                      onChange={handleChange}
                      type="number"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      value={couponToUpdate.for_customer_id}
                      name="for_customer_id"
                      fullWidth
                      label="for customer id"
                      onChange={handleChange}
                    />
                  </MDBox>

                  <MDBox sx={{ mb: 2 }}>
                    <Autocomplete
                      name="status"
                      onChange={handleStatusChange}
                      defaultValue={mapEnumValueToText(couponToUpdate.status)}
                      options={["Enable", "Disable"]}
                      renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                  </MDBox>

                  <MDBox
                    mb={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <MDDatePicker
                      input={{
                        placeholder: "Select start date",
                      }}
                      options={{
                        enableTime: true,
                        enableSeconds: true,
                      }}
                      value={couponToUpdate.date_start}
                      onChange={handleStartDateChange}
                    />

                    <MDDatePicker
                      input={{
                        placeholder: "Select end date",
                      }}
                      options={{
                        enableTime: true,
                        enableSeconds: true,
                      }}
                      value={couponToUpdate.date_end}
                      onChange={handleEndDateChange}
                    />
                  </MDBox>

                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                      Update
                    </MDButton>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox
                  my={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <MDBox component="img" alt="Not Found" src={NotFound404} />
                </MDBox>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}

export default CouponEdit;
