import React from "react";

export default function UserLoginDetails({ userLoginDetails }) {
  return (
    <div>
      <div>
        operating_system :{" "}
        {userLoginDetails?.operating_system && userLoginDetails?.operating_system}
      </div>
      <div>
        last_login_ip : {userLoginDetails?.last_login_ip && userLoginDetails?.last_login_ip}
      </div>
    </div>
  );
}
