import React, { createContext, useReducer } from "react";

const SET_PRODUCTS_ATTRIBUTES_GROUPS_ACTIONS = {
  SET_PRODUCTS_ATTRIBUTES_GROUPS: "SET_PRODUCTS_ATTRIBUTES_GROUPS",
  CLEAR_PRODUCTS_ATTRIBUTES_GROUPS: "CLEAR_PRODUCTS_ATTRIBUTES_GROUPS",
};

// Initial state of the products attributes GROUPS context
const initialState = {
  productsAttributesGroups: null,
};

// Products attributes groups context reducer function
const productsAttributesGroupsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PRODUCTS_ATTRIBUTES_GROUPS_ACTIONS.SET_PRODUCTS_ATTRIBUTES_GROUPS:
      return { ...state, productsAttributesGroups: payload };
    case SET_PRODUCTS_ATTRIBUTES_GROUPS_ACTIONS.CLEAR_PRODUCTS_ATTRIBUTES_GROUPS:
      return initialState;
    default:
      return state;
  }
};

// Create the products attrs groups context
const ProductsAttributesGroupsContext = createContext();

// products attrs groups context provider component
const ProductsAttributesGroupsContextProvider = ({ children }) => {
  const [{ productsAttributesGroups }, dispatch] = useReducer(
    productsAttributesGroupsReducer,
    initialState
  );

  // Action to set the products attrs groups context
  const setProductsAttributesGroups = (productsAttributesGroups) => {
    dispatch({
      type: SET_PRODUCTS_ATTRIBUTES_GROUPS_ACTIONS.SET_PRODUCTS_ATTRIBUTES_GROUPS,
      payload: productsAttributesGroups,
    });
  };

  // Action to clear the products attrs groups context

  const clearProductsAttributesGroups = () => {
    dispatch({ type: SET_PRODUCTS_ATTRIBUTES_GROUPS_ACTIONS.CLEAR_PRODUCTS_ATTRIBUTES_GROUPS });
  };

  const value = {
    productsAttributesGroups,
    setProductsAttributesGroups,
    clearProductsAttributesGroups,
  };
  return (
    <ProductsAttributesGroupsContext.Provider value={value}>
      {children}
    </ProductsAttributesGroupsContext.Provider>
  );
};

export { ProductsAttributesGroupsContext, ProductsAttributesGroupsContextProvider };
