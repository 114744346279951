import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

// Context

import { UserContextProvider } from "./context/user";

import { BrandsContextProvider } from "context/brands";

import { NotificationsContextProvider } from "./context/notifications";

import { CategoriesContextProvider } from "context/categories";
import { UsersContextProvider } from "context/users";
import { UsersMContextProvider } from "context/customersMembership";

import { ProductsContextProvider } from "context/products/products";

import { ProductsAttributesGroupsContextProvider } from "context/products/productsAttributesGroups";
import { PointsCouponsContextProvider } from "context/pointsCoupons";

import { OrdersContextProvider } from "context/orders";

import { CouponsContextProvider } from "context/coupons";

import { BannersContextProvider } from "context/banners";
import { NotesContextProvider } from "context/notes";
import { ProductsAttributesContextProvider } from "context/products/productAttributes";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <NotificationsContextProvider>
        <UserContextProvider>
          <BrandsContextProvider>
            <CategoriesContextProvider>
              <UsersContextProvider>
                <ProductsContextProvider>
                  <PointsCouponsContextProvider>
                    <ProductsAttributesGroupsContextProvider>
                      <ProductsAttributesContextProvider>
                        <OrdersContextProvider>
                          <CouponsContextProvider>
                            <BannersContextProvider>
                              <NotesContextProvider>
                                <UsersMContextProvider>
                                  <App />
                                </UsersMContextProvider>
                              </NotesContextProvider>
                            </BannersContextProvider>
                          </CouponsContextProvider>
                        </OrdersContextProvider>
                      </ProductsAttributesContextProvider>
                    </ProductsAttributesGroupsContextProvider>
                  </PointsCouponsContextProvider>
                </ProductsContextProvider>
              </UsersContextProvider>
            </CategoriesContextProvider>
          </BrandsContextProvider>
        </UserContextProvider>
      </NotificationsContextProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
