/* eslint-disable react/prop-types */
// ProductsList page components
import StatusCell from "../components/StatusCell";
import CustomerCell from "../components/CustomerCell";
import ActionsCell from "../components/ActionsCell";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

// Images

export const ordersHeaders = [
  { Header: "Id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "customer",
    accessor: "customer",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "shipment name",
    accessor: "shipmentName",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "customer number",
    accessor: "customerNumber",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "coupon",
    accessor: "coupon",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status = value;

      if (value == 1) {
        status = <StatusCell color="info" status="New" />;
      } else if (value == 5) {
        status = <StatusCell color="success" status="Completed" />;
      } else if (value == 7) {
        status = <StatusCell color="warning" status="Cancelled Order" />;
      } else if (value == 11) {
        status = <StatusCell color="primary" status="Refunded" />;
      }

      return status;
    },
  },
  {
    Header: "total",
    accessor: "total",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Payment Method",
    accessor: "paymentMethod",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Date Added",
    accessor: "dateAdded",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Date Modified",
    accessor: "dateModified",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
