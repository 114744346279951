import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

import MDDatePicker from "components/MDDatePicker";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Put delivery cost method

import "app.css";

import { UpdateAdminDeliveryCost } from "utils/apis/sales/deliveryCosts";

import { formatDateToISO } from "utils/methods";

function DeliveryCostUpdate() {
  // defualt state of delivery cost
  const defualtFormFields = {
    zone: "baghdad",
    cost: "",
    special_cost: "",
    special_cost_total_order: "",
    date_start: null,
    date_end: null,
  };
  const [deliveryCost, setDeliveryCost] = useState(defualtFormFields);
  const { zone, cost, special_cost, special_cost_total_order, date_start, date_end } = deliveryCost;

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDeliveryCost({ ...deliveryCost, [name]: value });
  };

  const handleStartDateChange = (_, value) => {
    setDeliveryCost((previousState) => {
      return {
        ...previousState,
        date_start: formatDateToISO(value),
      };
    });
  };

  const handleEndDateChange = (_, value) => {
    setDeliveryCost((previousState) => {
      return {
        ...previousState,
        date_end: formatDateToISO(value),
      };
    });
  };

  // handle create delivery cost submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate zone and cost
    if (!zone || !cost) {
      return setErrorMSG("Please fill all the fields");
    }

    // set the id
    const deliveryCostId = zone === "baghdad" ? 1 : 2;

    /// send post delivery cost request
    const result = await UpdateAdminDeliveryCost(user, deliveryCostId, deliveryCost);

    if (!(result instanceof Error)) {
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Delivery cost updated successfuly");
        // navigate to delivery costs list page
        navigate("/settings/delivery-costs");
      } else {
        setErrorMSG(result.data.error);
      }
    } else {
      setErrorMSG(result.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center">
              <MDBox mb={2}>
                <MDTypography variant="h3" fontWeight="bold">
                  Update Delivery Cost
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Update Delivery Cost
                  </MDTypography>
                </MDBox>
                <MDBox mb={2} sx={{ width: "400px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="parent-label">Zone</InputLabel>
                    <Select
                      value={zone}
                      name="zone"
                      onChange={handleChange}
                      labelId="zone-label"
                      label="select"
                      id="zone-id"
                      fullWidth
                      sx={{
                        minWidth: 120,
                        lineHeight: "3rem",
                      }}
                    >
                      <MenuItem value="baghdad">Baghdad</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="cost"
                    fullWidth
                    label="Cost"
                    value={cost}
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="special_cost"
                    fullWidth
                    label="Special_cost"
                    value={special_cost}
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>

                {special_cost && (
                  <>
                    <MDBox mb={2}>
                      <MDDatePicker
                        input={{
                          id: "start_date",
                          name: "start_date",
                          placeholder: "Select start date",
                        }}
                        options={{
                          enableTime: true,
                          enableSeconds: true,
                        }}
                        onChange={handleStartDateChange}
                      />

                      <MDDatePicker
                        input={{
                          id: "end_date",
                          name: "end_date",
                          placeholder: "Select end date",
                        }}
                        options={{
                          enableTime: true,
                          enableSeconds: true,
                        }}
                        onChange={handleEndDateChange}
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        name="special_cost_total_order"
                        fullWidth
                        label="Special cost total order"
                        value={special_cost_total_order}
                        onChange={handleChange}
                        type="number"
                      />
                    </MDBox>
                  </>
                )}

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Update
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeliveryCostUpdate;
