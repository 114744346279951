// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function YesOrNoDialog({ heading, description, functiontoDo, openDialog, closeFunction }) {
  return (
    <Dialog
      open={openDialog}
      onClose={closeFunction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" color="error" onClick={functiontoDo}>
          Delete
        </MDButton>
        <MDButton variant="gradient" color="info" onClick={closeFunction}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// Setting default values for the props of DashboardNavbar
YesOrNoDialog.defaultProps = {
  heading: "are you sure ?",
  description: "if you do that you can not rollback",
  functiontoDo: () => {
    console.log("no function");
  },
  openDialog: false,
};

// Typechecking props for the DashboardNavbar
YesOrNoDialog.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  functiontoDo: PropTypes.func,
  openDialog: PropTypes.bool,
};

export default YesOrNoDialog;
