/* eslint-disable react/prop-types */
// ProductsList page components
import StatusCell from "../components/StatusCell";
import CustomerCell from "../components/CustomerCell";
import ActionsCell from "../components/ActionsCell";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

// Images

export const validated_top_5_purchase_total_customers_Headers = [
  {
    Header: "username",
    accessor: "username",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "joined date",
    accessor: "date_joined",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "OS",
    accessor: "operating_system",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "IP",
    accessor: "last_login_ip",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "login time",
    accessor: "login_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "total",
    accessor: "total_amount",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
];

export const top_5_customer_users_Headers = [
  {
    Header: "username",
    accessor: "username",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "joined date",
    accessor: "date_joined",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "OS",
    accessor: "operating_system",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "IP",
    accessor: "last_login_ip",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "login time",
    accessor: "login_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "order count",
    accessor: "order_count",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
];
