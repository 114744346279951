import MDBox from "components/MDBox";

import { DeleteAdminCoupon } from "utils/apis/ecommerce/coupons";
import { useContext } from "react";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";

import { CouponsContext } from "context/coupons";
import { Link } from "react-router-dom";

import { Icon } from "@mui/material";

const ActionsCell = ({ id }) => {
  // ** id is the coupon id **

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  // Accsss coupon context to change the state after delete
  const { coupons, setCoupons } = useContext(CouponsContext);

  // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  // Delete Coupons Action
  const handleDeleteCoupon = async () => {
    const result = await DeleteAdminCoupon(id);

    console.log(result);

    if (!(result instanceof Error)) {
      if (result.status === 204) {
        // delete it from ui
        const { count, next, previous, data } = coupons;
        const newCoupons = data?.filter((coupon) => coupon.coupon_id != id);

        setCoupons({
          count,
          next,
          previous,
          data: newCoupons,
        });

        // show deleted alert
        return setSuccessMSG("Coupon Deleted Successfuly");
      }

      if (result.data.error) {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <MDBox>
      <Icon
        color="error"
        fontSize="medium"
        onClick={handleDeleteCoupon}
        sx={{ mx: 2, cursor: "pointer" }}
      >
        delete
      </Icon>
      <Link to={`/ecommerce/coupons/edit/${id}`}>
        <Icon color="info" fontSize="medium">
          edit
        </Icon>
      </Link>
    </MDBox>
  );
};

export default ActionsCell;
