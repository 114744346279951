import CategoryImageCell from "../components/CategoryImageCell/index";
import ActionsCell from "../components/ActionsCell/index";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";
// Images

export const categoriesListHeader = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Category Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Sort Order",
    accessor: "sortOrder",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Image",
    accessor: "image",
    Cell: ({ value }) => <CategoryImageCell image={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
