import ActionsCell from "../components/ActionsCell/index";
import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";
export const generalCouponsHeaders = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Code",
    accessor: "code",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Discount",
    accessor: "discount",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Type",
    accessor: "type",
    Cell: ({ value }) => {
      let typeText = value;
      switch (value) {
        case 0:
          typeText = "General";
          break;
        case 1:
          typeText = "Points";
          break;
        case 2:
          typeText = "Other";
          break;
      }

      return <DefaultCell value={typeText} />;
    },
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      let statusText = value;
      switch (value) {
        case 0:
          statusText = "disabled";
          break;
        case 1:
          statusText = "enabled";
          break;
      }

      return <DefaultCell value={statusText} />;
    },
  },
  {
    Header: "Total Max",
    accessor: "total_max",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Start Date",
    accessor: "date_start",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "End Date",
    accessor: "date_end",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Date Added",
    accessor: "date_added",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
