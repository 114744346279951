// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { productsAttributesHeader } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils
import { ProductsAttributesGroupsContext } from "context/products/productsAttributesGroups";
import { FetchAdminProductsAttributesGroups } from "utils/apis/catalog/products/productsAttributesGroups";
import { UserContext } from "context/user";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

// context
function ProductsAttributesGroupsList() {
  // state
  const { productsAttributesGroups, setProductsAttributesGroups } = useContext(
    ProductsAttributesGroupsContext
  );
  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the products attrs groups
  useEffect(() => {
    const fetchProductsAttributesGroups = async () => {
      const result = await FetchAdminProductsAttributesGroups();

      if (!(result instanceof Error)) {
        if (!result.data.error && result.data) {
          const { count, next, previous, results } = result.data;
          return setProductsAttributesGroups({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    };

    fetchProductsAttributesGroups();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/catalog/products/attributes-group/create"}>
            <MDButton variant="gradient" color="info">
              New Group
            </MDButton>
          </Link>
          <MDBox display="flex">
            {/* <MDButton variant={menu ? "contained" : "outlined"} color="dark">
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton> */}
            {/* {renderMenu} */}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card sx={{ padding: 6 }}>
          {!productsAttributesGroups?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {productsAttributesGroups?.data && (
            <DataTable
              table={{
                columns: productsAttributesHeader,
                rows: productsAttributesGroups.data.map((group) => ({
                  id: group.attribute_group_id,
                  nameEnglish: group.text[0]?.name || "no name",
                  nameArabic: group.text[1]?.name || "لا يوجد اسم",
                  actions: group.attribute_group_id,
                })),
              }}
              canSearch
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsAttributesGroupsList;
