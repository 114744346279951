import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import { useEffect, useState, useContext } from "react";
import Card from "@mui/material/Card";

import CircularProgress from "@mui/material/CircularProgress";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  fetchAdminUsersDetails,
  EditCustomerRequest,
  EditCustomerStatusRequest,
} from "utils/apis/users/users";

import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";

import { useParams, useNavigate } from "react-router-dom";
import { NotificationsContext } from "context/notifications";

import BasicInformation from "./components/BasicInformation";
import MuiDialog from "./components/MuiDialog";
import RewardPoints from "./components/RewardPoints";
import InfluencerInfo from "./components/InfluencerInfo";
import CustomerOrders from "./components/CustomerOrders";

import MDButton from "components/MDButton";

import CouponsList from "./components/CouponsList";
import UserLoginDetails from "./components/UserLoginDetails";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserDetails() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const params = useParams();

  const { setErrorMSG, setSuccessMSG } = useContext(NotificationsContext);
  const [user, setUser] = useState();
  const [loadingOrNot, setLoadingOrNot] = useState(false);
  const [reRenderUserData, setReRenderUserData] = useState(false);

  const [formData, setFormData] = useState({
    step1: {
      id: "",
      first_name: "",
      last_name: "",
      username: "",
      is_active: true,
      date_joined: "",
    },
    step2: {
      id: "",
    },

    step3: {
      // it is user id for edit the influencer code
      id: "",
      referral_code: {},
      refferals_received: [],
      refferals_made: [],
    },
    step4: {
      id: "",
      user_rank: {},
      user_points: "",
    },

    step5: {
      coupons: [],
    },

    step6: {
      user_login_details: {},
    },
  });

  const { id } = params;
  useEffect(() => {
    if (id) {
      const fetchUserDetails = async () => {
        setLoadingOrNot(true);
        const result = await fetchAdminUsersDetails(id);
        setLoadingOrNot(false);

        if (!(result instanceof Error) && result.data) {
          setUser(result.data);
          const {
            username,
            first_name,
            last_name,
            is_active,
            date_joined,
            user_login_details,
            referral_code,
            user_rank,
            user_points,
            coupons,
            refferals_received,
            refferals_made,
          } = result.data;
          setFormData((previousState) => {
            return {
              ...previousState,
              step1: { first_name, last_name, username, is_active, id, date_joined },
              step2: { id },
              step3: { referral_code, refferals_received, refferals_made, id },
              step4: { user_rank, user_points, id },
              step5: { coupons },
              step6: { user_login_details },
            };
          });
        } else {
          // result = error
          // if error status === 404, ignore the error and just show the 404 image (check the template expression inside the JSX).
          // else will set the error message to show it in case if there is network error or something else
          if (!(result.message === "Request failed with status code 404")) {
            setErrorMSG(result.message);
          }
        }
      };

      fetchUserDetails();
    }
  }, [id, reRenderUserData]);

  const editUserData = async () => {
    const editData = formData.step1;
    // first update general information
    try {
      const result = await EditCustomerRequest(id, editData);
      if (!(result instanceof Error)) {
        // this is from backend (backend-validations),
        if (!result.data.error) {
          // show success notification
          setSuccessMSG("user updated successfully");
          setReRenderUserData((prev) => !prev);
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    } catch (error) {
      setErrorMSG(error);
      console.log(error);
    }
    // update user status
    try {
      const result = await EditCustomerStatusRequest(id, editData?.is_active);
      if (!(result instanceof Error)) {
        // this is from backend (backend-validations),
        if (!result.data.error) {
          // show success notification
          setSuccessMSG("user updated successfully");
          setReRenderUserData((prev) => !prev);
        } else {
          return setErrorMSG(result.data.error);
        }
      } else {
        return setErrorMSG(result.message);
      }
    } catch (error) {
      setErrorMSG(error);
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loadingOrNot ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "80vh",
          }}
        >
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
              <Tab label="Basic Information" {...a11yProps(0)} />
              <Tab label="Orders" {...a11yProps(1)} />
              <Tab label="influencer information" {...a11yProps(2)} />
              <Tab label="Reward Points" {...a11yProps(3)} />
              <Tab label="Coupons" {...a11yProps(4)} />
              <Tab label="Login Details" {...a11yProps(5)} />
            </Tabs>
          </Box>

          <MDBox display="flex" justifyContent="flex-end" alignItems="flex-start" mb={2} gap={2}>
            <MDButton variant="gradient" color="info" onClick={handleClickOpenDialog}>
              change password
            </MDButton>
            <MDButton variant="gradient" color="info" onClick={editUserData}>
              <SaveAltIcon />
            </MDButton>
          </MDBox>

          <Card sx={{ mt: 3 }}>
            <CustomTabPanel value={value} index={0}>
              <BasicInformation parentState={formData.step1} upadteParentState={setFormData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CustomerOrders parentState={formData.step2} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <InfluencerInfo parentState={formData.step3} upadteParentState={setFormData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <RewardPoints
                parentState={formData.step4}
                upadteParentState={setFormData}
                setReRenderUserData={setReRenderUserData}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <CouponsList coupons={formData.step5.coupons} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <UserLoginDetails userLoginDetails={formData.step6.user_login_details} />
            </CustomTabPanel>
          </Card>
        </Box>
      )}
      <MuiDialog open={openDialog} handleClose={handleCloseDialog} user_id={id} />
    </DashboardLayout>
  );
}
