import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";
import { useContext } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// get parents categories

import { FetchAdminCategories, PostAdminCategory } from "utils/apis/catalog/categories";
import InputFileAndPreview from "elements/InputFileAndPreview";
import { AddUser } from "utils/apis/users/users";
import { UsersContext } from "context/users";

function CreateUser() {
  const { clearUsers } = useContext(UsersContext);

  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // parent categories state

  const [parentsCategories, setParentsCategories] = useState(null);
  const [categorySearchKey, setCategorySearchKey] = useState(null);

  // use navigate hook

  const navigate = useNavigate();

  const defaultFormFields = {
    username: "",
    first_name: "",
    last_name: "",
    is_active: true,
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { username, first_name, last_name, is_active } = formFields;

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // handle create category submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();

    if (!username || !first_name || !last_name) {
      return setErrorMSG("Please fill all the fields");
    }

    const userBody = {
      username,
      first_name,
      last_name,
      is_active,
    };

    const result = await AddUser(userBody);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("user created successfuly");
        clearUsers();
        // navigate to brands list page
        return navigate("/customers");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  const handleActiveOrNotChange = (event) => {
    if (event.target.value == "enable") {
      setFormFields((prev) => {
        return { ...prev, is_active: true };
      });
    } else if (event.target.value == "disable") {
      setFormFields((prev) => {
        return { ...prev, is_active: false };
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center" mb={3}>
              <MDTypography variant="h3" fontWeight="bold">
                Create New User
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Create New User
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDBox mb={2}>
                    <MDInput
                      name="username"
                      fullWidth
                      label="username"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="first_name"
                      fullWidth
                      label="first_name"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      name="last_name"
                      fullWidth
                      label="last_name"
                      onChange={handleChange}
                      type="text"
                    />
                  </MDBox>

                  <MDBox mb={2} px={1}>
                    <FormControl>
                      <MDTypography>please be careful with that</MDTypography>
                      <RadioGroup
                        row
                        aria-labelledby="enable-or-disable-user"
                        name="enable-or-disable-user-group"
                        onChange={handleActiveOrNotChange}
                      >
                        <FormControlLabel
                          value="enable"
                          control={<Radio checked={is_active} />}
                          label="Active"
                        />
                        <FormControlLabel
                          value="disable"
                          control={<Radio checked={!is_active} />}
                          label="Not Active"
                        />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateUser;
