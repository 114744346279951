/* eslint-disable react/prop-types */
// ProductsList page components
import StatusCell from "../components/StatusCell";
import CustomerCell from "../components/CustomerCell";
import ActionsCell from "../components/ActionsCell";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

// Images

export const notificationsHeader = [
  { Header: "Id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "title",
    accessor: "title",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "body",
    accessor: "body",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "sent at",
    accessor: "sentAt",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
];
