import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

import { loginWithPhonenumberAndPassword } from "utils/apis/auth";

import { NotificationsContext } from "context/notifications";

import { UserContext } from "context/user";
import { useContext } from "react";

import { useNavigate } from "react-router-dom";

function Cover() {
  // component state
  const [rememberMe, setRememberMe] = useState(true);
  const { setErrorMSG, setSuccessMSG } = useContext(NotificationsContext);

  // const defaultFormFields = {
  //   phone_number: "admin",
  //   password: "Shalaty34u_7sl",
  // };

  const defaultFormFields = {
    phone_number: "",
    password: "",
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { phone_number, password } = formFields;

  // user context state
  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();

  // redirect user to dashboards if exsit
  useEffect(() => {
    if (user) {
      navigate("/dashboards");
    }
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  //  handle change

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // handle submit
  const handelSubmit = async (e) => {
    e.preventDefault();

    // Reset error
    setErrorMSG(null);

    if (!phone_number || !password) {
      return setErrorMSG("Please fill all the fields");
    }

    // result may be an error object that contains a message or valid response
    const response = await loginWithPhonenumberAndPassword(phone_number, password);

    // this is a valid response
    // meke sure to keep the ()
    if (!(response instanceof Error)) {
      // set user inside context
      setUser(response.data);
      // set user inside localstorge
      const jsonUserObject = JSON.stringify(response.data);
      localStorage.setItem("user", jsonUserObject);

      // show success notification
      setSuccessMSG("Logged in successfuly");
      // navigate to home
      return navigate("/dashboards");

      // this is and error
    } else {
      if (response.code === "ERR_BAD_REQUEST") {
        return setErrorMSG("Incorrect login details");
      } else if (response.code === "ERR_NETWORK") {
        return setErrorMSG("Network error, please try again");
      } else {
        return setErrorMSG("An unknown error occurred. Please try again later");
      }
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handelSubmit}>
            <MDBox mb={2}>
              <MDInput
                name="phone_number"
                label="phone number"
                variant="standard"
                fullWidth
                placeholder="Enter phone number or username "
                value={phone_number}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                name="password"
                type="password"
                label="Password"
                variant="standard"
                placeholder="Enter your password "
                fullWidth
                value={password}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
