import React, { useContext, useState } from "react";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Stack from "@mui/material/Stack";

import { ChangePasswordRequest } from "utils/apis/users/users";
import { NotificationsContext } from "context/notifications";

export default function MuiDialog({ open, handleClose, user_id }) {
  const { setErrorMSG, setSuccessMSG } = useContext(NotificationsContext);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const changePassword = async () => {
    if (password != confirmPassword) return;
    try {
      const data = { user_id, new_password: password };
      const result = await ChangePasswordRequest(data);
      if (result.status === 200 && result.data) {
        setSuccessMSG("the password changed successfuly");
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setErrorMSG("there is an error");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="change-password-dialog">change the password of the user ::</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ width: "500px !important", marginTop: 2 }}>
          <MDInput
            value={password}
            name="password"
            label="password"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
          />
          <MDInput
            value={confirmPassword}
            name="confirm password"
            label="confirm password"
            fullWidth
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <MDTypography sx={{ color: "red", fontSize: "0.8rem" }}>
            {password != confirmPassword ? "the password and confirm password not the same" : ""}
          </MDTypography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose}>close</MDButton>
        <MDButton onClick={changePassword} variant="gradient" color="info">
          Change
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
