import React, { createContext, useReducer } from "react";

const SET_POINTS_COUPONS_ACTIONS = {
  SET_POINTS_COUPONS: "SET_POINTS_COUPONS",
  CLEAR_POINTS_COUPONS: "CLEAR_POINTS_COUPONS",
};

// Initial state of the poinst coupons context
const initialState = {
  pointsCoupons: null,
};

// Points Coupons context reducer function
const pointsCouponsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_POINTS_COUPONS_ACTIONS.SET_POINTS_COUPONS:
      return { ...state, pointsCoupons: payload };
    case SET_POINTS_COUPONS_ACTIONS.CLEAR_POINTS_COUPONS:
      return initialState;
    default:
      return state;
  }
};

// Create the Points Coupons context
const PointsCouponsContext = createContext();

// brands context provider component
const PointsCouponsContextProvider = ({ children }) => {
  const [{ pointsCoupons }, dispatch] = useReducer(pointsCouponsReducer, initialState);

  // Action to set the PointsCoupons context
  const setPointsCoupons = (pointsCoupons) => {
    dispatch({ type: SET_POINTS_COUPONS_ACTIONS.SET_POINTS_COUPONS, payload: pointsCoupons });
  };

  // Action to clear the PointsCoupons context
  const clearPointsCoupons = () => {
    dispatch({ type: SET_POINTS_COUPONS_ACTIONS.CLEAR_POINTS_COUPONS });
  };

  const value = { pointsCoupons, setPointsCoupons, clearPointsCoupons };
  return <PointsCouponsContext.Provider value={value}>{children}</PointsCouponsContext.Provider>;
};

export { PointsCouponsContext, PointsCouponsContextProvider };
