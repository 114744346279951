import { useContext, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Stack } from "@mui/material";
import MDBadge from "components/MDBadge";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/catalog/products/new-product/components/FormField";
import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";

import { FetchAdminCategories, FetchSubCategories } from "utils/apis/catalog/categories";
import { GetAndSearchAdminBrands } from "utils/apis/ecommerce/brands";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function ProductInfo(props) {
  const { localState, setParentState } = props;

  const [brandsSearchKey, setBrandsSearchKey] = useState(null);
  const [categorySearchKey, setCategorySearchKey] = useState(null);
  const [brandsOptions, setBrandsOptions] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState(null);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState(null);

  // Handle input change (General)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        [name]: value,
      },
    }));
  };

  // handle brand change
  const handleBrandChange = (_, value, reason) => {
    // Only if there is a value
    if (value) {
      setParentState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          brand: value,
        },
      }));
    } else {
      // Make sure that no null or undefined values set to the state when change event happen
      setParentState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          brand: "",
        },
      }));
    }

    // clear the state if the user cleard the input by icon or by removeing text
    // if (no brands search key) => that means user selecting from default pages.
    if (reason === "clear" && !brandsSearchKey) {
      setParentState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          brand: "",
        },
      }));
    }
    // clear the state if the user cleard the input by icon or by removeing
    // text  (if there is search key ) =>  this means user not selecting from default optiosn so
    // i have to back to the defualt options
    // by trigger a fetch request to page 1. this happen when i set the search keyword to null
    //, so the effect of fetching will run again

    if (reason === "clear" && brandsSearchKey) {
      setParentState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          brand: "",
        },
      }));

      setBrandsSearchKey(null);
    }
  };

  const handleBrandsSearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 1) {
      setBrandsSearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setBrandsSearchKey(null);
    }
  };

  const handleCategorySearch = async (_, searchValue, reason) => {
    // if the user change the input (only by typing) perform a search
    // the threshold to do the search is 3 chars
    let searchKeyword = searchValue.trim();
    if (reason === "input" && searchKeyword.length >= 3) {
      setCategorySearchKey(searchKeyword);

      // This code abov will trigger the fetch effect again with the search keyword.
    }
    if (searchKeyword.length == 0) {
      setCategorySearchKey(null);
    }
  };

  // handle category change
  const handleMainCategoryChange = (_, value, reason) => {
    // if event was selecting from list
    if (reason === "selectOption") {
      // the value is an array of options
      if (value) {
        setParentState((prevState) => {
          // const newCategories = value.filter(
          //   (category) => !prevState.step1.categories.includes(category)
          // );

          return {
            ...prevState,
            step1: {
              ...prevState.step1,
              mainCategories: value?.length ? [...value] : [...prevState.step1.mainCategories],
            },
          };
        });
      } else {
        // Make sure that no null or undefined values set to the state when change event happen
        setParentState((prevState) => ({
          ...prevState,
          step1: {
            ...prevState.step1,
            mainCategories: [],
          },
        }));
      }
    }

    if (reason === "removeOption") {
      setParentState((prevState) => {
        // when removeing option the value automatically re filter
        return {
          ...prevState,
          step1: {
            ...prevState.step1,
            mainCategories: value.length ? [...value] : [],
          },
        };
      });
    }

    // clear the state if the user cleard the input by icon or by removeing text
    // if (no category search key) => that means user selecting from default pages.
    if (reason === "clear") {
      setParentState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          mainCategories: [],
        },
      }));
    }
  };

  const handleSubCategoryChange = (_, value, reason) => {
    if (reason === "selectOption") {
      // the value is an array of options

      setParentState((prevState) => {
        //  filter them to add just the new categories that not existed in the previous state
        // const newCategories = value.filter(
        //   (category) => !prevState.step1.categories.includes(category)
        // );

        return {
          ...prevState,
          step1: {
            ...prevState.step1,
            subCategories: value.length ? [...value] : [...prevState.step1.subCategories],
          },
        };
      });
    }

    if (reason === "removeOption") {
      setParentState((prevState) => {
        // when removeing option the value automatically re filter
        return {
          ...prevState,
          step1: {
            ...prevState.step1,
            subCategories: value.length ? [...value] : [],
          },
        };
      });
    }

    // clear the state if the user cleard the input by icon or by removeing text
    // if (no category search key) => that means user selecting from default pages.
    if (reason === "clear") {
      setParentState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          subCategories: [], // keep just the parent category in the state and clear the subs
        },
      }));
    }
  };

  const { user } = useContext(UserContext);
  const { setErrorMSG } = useContext(NotificationsContext);

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchBrands = async () => {
      try {
        const result = await GetAndSearchAdminBrands({ search: brandsSearchKey });

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha brands as auto complete item
          const brandsItems = results?.map((brand) => ({
            label: brand.name,
            manufacturer_id: brand.manufacturer_id,
          }));

          // set the brands options state
          setBrandsOptions(brandsItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchBrands();
  }, [brandsSearchKey]);

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchCategories = async () => {
      try {
        let searchKeyObject;
        if (!categorySearchKey) {
          searchKeyObject = {};
        } else {
          searchKeyObject = Object.fromEntries(
            Object.entries({
              name: categorySearchKey,
            }).filter(([_, value]) => value !== undefined && value !== null)
          );
        }
        const result = await FetchAdminCategories(searchKeyObject);

        if (result.status === 200 && result.data) {
          const { results } = result.data;

          // map tha categories as auto complete item
          const categoriesItems = results?.map((category) => ({
            label: category.description[0].name,
            category_id: category.category_id,
          }));

          // set the categories options state
          setCategoriesOptions(categoriesItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchCategories();
  }, [categorySearchKey]);

  useEffect(() => {
    // default result will be page 1 (if no search key added)
    const fetchSubCategories = async () => {
      if (localState.mainCategories.length) {
        //select parent category
        const parentCategoryId =
          localState.mainCategories[localState.mainCategories.length - 1].category_id;

        try {
          const result = await FetchSubCategories(parentCategoryId);

          if (result.status === 200 && result.data) {
            const { results } = result.data;

            // map tha sub categories as auto complete item
            const subCategoriesItems = results?.map((subCategory) => ({
              label: subCategory.description[0].name,
              category_id: subCategory.category_id,
            }));

            // set the sub categories options state
            setSubCategoriesOptions(subCategoriesItems);
          }
        } catch (error) {
          console.log(error);
          setErrorMSG("something went wrong");
        }
      }
    };

    fetchSubCategories();
  }, [localState.mainCategories]);

  useEffect(() => {
    if (localState.mainCategories?.length || localState.subCategories?.length)
      setParentState((prevState) => {
        const categoriesForAdd = localState.mainCategories.concat(localState.subCategories);
        const newCategories = prevState?.step1?.categories;
        categoriesForAdd.forEach((newCat) => {
          if (
            !prevState?.step1?.categories?.some(
              (prevCat) => prevCat.category_id == newCat.category_id
            )
          ) {
            newCategories.push(newCat);
          }
        });
        return {
          ...prevState,
          step1: {
            ...prevState.step1,
            categories: newCategories,
          },
        };
      });
  }, [localState.mainCategories, localState.subCategories]);

  const deleteCategory = (category_id) => {
    setParentState((prevState) => {
      console.log(prevState);
      const categoriesAfterDelete = prevState?.step1?.categories.filter(
        (cat) => cat.category_id != category_id
      );
      return { ...prevState, step1: { ...prevState.step1, categories: categoriesAfterDelete } };
    });
  };

  return (
    <MDBox>
      <MDTypography variant="h5">Product Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="nameEnglish"
              label="Name English"
              onChange={handleChange}
              value={localState.nameEnglish}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              name="nameArabic"
              label="Name Arabic"
              onChange={handleChange}
              value={localState.nameArabic}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={1} ml={0.5}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            <MDInput
              value={localState.description}
              style={{ width: "100%" }}
              multiline
              rows={5}
              onChange={handleChange}
              name="description"
            />
            <MDBox mt={2}>
              <FormField
                type="text"
                label="Model"
                name="model"
                onChange={handleChange}
                value={localState.model}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox my={1}>
              <MDTypography variant="h6" fontWeight="medium">
                Categories :
              </MDTypography>
              <Stack direction="row" flexWrap="wrap">
                {localState?.categories?.length
                  ? localState.categories.map((category) => {
                      return (
                        <MDBox ml={1}>
                          <MDBadge
                            badgeContent={
                              <MDBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  color: "white !important",
                                }}
                              >
                                {category.label}
                                <HighlightOffIcon
                                  color="primary"
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => deleteCategory(category.category_id)}
                                />
                              </MDBox>
                            }
                            color="dark"
                            size="xs"
                            container
                          />
                        </MDBox>
                      );
                    })
                  : "N/A"}
              </Stack>
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                // getOptionSelected={(option, value) => option.category_id === value.category_id}
                defaultValue={localState.mainCategories?.length ? localState.mainCategories : []}
                options={categoriesOptions ? categoriesOptions : []}
                multiple
                onInputChange={handleCategorySearch}
                renderInput={(params) => (
                  <MDInput label="Select Category" {...params} variant="standard" />
                )}
                onChange={handleMainCategoryChange}
              />
            </MDBox>

            {localState.mainCategories?.length >= 1 ? (
              <MDBox mb={2}>
                <Autocomplete
                  getOptionSelected={(option, value) => option.category_id === value.category_id}
                  value={localState.subCategories?.length ? localState.subCategories : []}
                  options={subCategoriesOptions ? subCategoriesOptions : []}
                  multiple
                  renderInput={(params) => (
                    <MDInput label="Select Sub Categories" {...params} variant="standard" />
                  )}
                  onChange={handleSubCategoryChange}
                />
              </MDBox>
            ) : (
              ""
            )}

            <MDBox mb={3}>
              <Autocomplete
                isOptionEqualToValue={(option) => option.manufacturer_id}
                value={localState.brand ? localState.brand : null}
                onChange={handleBrandChange}
                onInputChange={handleBrandsSearch}
                options={brandsOptions ? brandsOptions : []}
                renderInput={(params) => (
                  <MDInput label="Select Brand" {...params} variant="standard" />
                )}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ProductInfo;
