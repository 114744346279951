import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

import { OrdersContext } from "context/orders";

import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { useState, useContext } from "react";
import YesOrNoDialog from "elements/YesOrNoDialog";

import { NotificationsContext } from "context/notifications";
import { DeleteOrder } from "utils/apis/sales/orders";

const ActionsCell = ({ id }) => {
  const { orders, setOrders } = useContext(OrdersContext);
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteOrder = async () => {
    const result = await DeleteOrder(id);
    // this is a valid response
    if (!(result instanceof Error)) {
      if (result.data.status) {
        const { count, next, previous, data } = orders;
        const newOrders = data.filter((order) => order.order_id != id);

        setOrders({
          count,
          next,
          previous,
          data: newOrders,
        });

        setOpenDialog(false);

        setSuccessMSG("Order Deleted Successfuly");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  return (
    <Stack direction="row" spacing={0.8}>
      <Link to={`/sales/orders/details/${id}`}>
        <PreviewIcon color="success" fontSize="medium" />
      </Link>
      <Link to={`/sales/orders/edit/${id}`}>
        <EditIcon color="info" fontSize="medium" />
      </Link>

      <HighlightOffIcon
        color="primary"
        fontSize="medium"
        sx={{ cursor: "pointer" }}
        onClick={handleClickOpen}
      />

      <YesOrNoDialog
        heading="Are you sure want to delete this order !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteOrder}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
    </Stack>
  );
};

export default ActionsCell;
