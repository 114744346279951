import { DeleteAdminNote } from "utils/apis/for_app/notes";
import { useContext } from "react";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";
import { NotesContext } from "context/notes";
import { Link } from "react-router-dom";

import YesOrNoDialog from "elements/YesOrNoDialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Stack from "@mui/material/Stack";
import { useState } from "react";

import { Icon } from "@mui/material";

const ActionsCell = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  // ** id is the banner id **

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  // Accsss banners to change the state after delete
  const { notes, setNotes } = useContext(NotesContext);

  // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  const handleDeleteNote = async () => {
    const result = await DeleteAdminNote(id);

    // this is a valid response
    if (!(result instanceof Error)) {
      if (result.status === 204) {
        // banner deleted successfuly

        // remove it from the UI
        const { count, next, previous, data } = notes;
        const newNotes = data.filter((note) => note.important_notes_id != id);

        setNotes({
          count,
          next,
          previous,
          data: newNotes,
        });

        setOpenDialog(false);

        // show deleted alert
        setSuccessMSG("Banner Deleted Successfuly");
      } else {
        // this is backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // this is server error or other erro that could happen
      return setErrorMSG(result.message);
    }
  };

  return (
    <>
      <YesOrNoDialog
        heading="Are you sure you want to delete this note !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteNote}
        openDialog={openDialog}
        closeFunction={handleClose}
      />

      <Stack direction="row" spacing={1}>
        <HighlightOffIcon
          color="primary"
          fontSize="medium"
          onClick={handleClickOpen}
          sx={{ cursor: "pointer" }}
        />

        <Link to={`/notes/edit/${id}`}>
          <Icon color="info" fontSize="medium">
            edit
          </Icon>
        </Link>
      </Stack>
    </>
  );
};

export default ActionsCell;
