import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";
import Footer from "components/Footer";

import "app.css";

// User context

import { UserContext } from "context/user";
import { useContext, useEffect } from "react";

// Notifications Context

import { NotificationsContext } from "context/notifications";

// Put and Get currency exchange
import {
  fetchAdminCurrencyExchange,
  EditAdminCurrencyExchange,
} from "utils/apis/settings/currencyExchange";

function CurrencyExchange() {
  const [currencyExchange, setCurrencyExchange] = useState(0);

  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  const handleChange = (event) => {
    setCurrencyExchange(event.target.value);
  };

  useEffect(() => {
    const getCurrencyExchange = async () => {
      const result = await fetchAdminCurrencyExchange(user);
      if (result.status === 200 && result.data) {
        const { iqd_to_dollars_exchange } = result.data;
        setCurrencyExchange(iqd_to_dollars_exchange);
      }
    };

    getCurrencyExchange();
  }, []);

  // handle create delivery cost submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate currency Change
    if (!currencyExchange) {
      return setErrorMSG("Please fill all the fields");
    }

    /// send post delivery cost request
    const response = await EditAdminCurrencyExchange(user, 1, currencyExchange);

    if (response.status === 200) {
      // show success notification
      setSuccessMSG("Currency exchange updated successfuly");
    } else {
      setErrorMSG("Something went wrong");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox textAlign="center">
              <MDBox mb={2}>
                <MDTypography variant="h3" fontWeight="bold">
                  Update Currency Exchange
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Update Currency Exchange
                  </MDTypography>
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    placeholder="1450.000"
                    fullWidth
                    label="Currency Exchange"
                    value={currencyExchange}
                    onChange={handleChange}
                    type="number"
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Update
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CurrencyExchange;
