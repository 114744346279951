// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProductsListComponent from "./ProductsListComponent";

//  products context
function ProductsList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ProductsListComponent />
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsList;
