import React from "react";
import DataTable from "./DataTable/index";

// Data table headers
import { usersHeaders } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils

import downloadCsv from "download-csv";

import { fetchCustomersMembership } from "utils/apis/users/users";

// users list contxt
import { UsersMContext } from "context/customersMembership";

import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";

// loged in user
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import "app.css";
import { formatDateToISO } from "utils/methods";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axiosInstance from "utils/axiosInstance";
import qs from "qs";

import { displayBasicDate } from "utils/methods";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export default function CustomerMembershipComponent({ customer_id }) {
  // State for seraching
  const { usersmembership, activeCustomerMembershipId, setUsersM, setActiveCustomerMembershipId } =
    useContext(UsersMContext);

  const [customerId, setCustomerId] = useState(customer_id || null);

  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the users
  useEffect(() => {
    const fetchUsers = async () => {
      let searchKeyObject;
      if (!customerId) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        // filter the object to have only not undefined value property
        searchKeyObject = Object.fromEntries(
          Object.entries({
            customer_id: customerId,
          }).filter(([_, value]) => value)
        );

        setSearchKeyObjectPagination(searchKeyObject);
      }
      try {
        const response = await fetchCustomersMembership(searchKeyObject);

        if (response.data) {
          const { count, next, previous, results } = response.data;

          setActiveCustomerMembershipId(results?.[0]?.id);
          setUsersM({
            count,
            next,
            previous,
            data: results,
          });
          // if (customerId && results?.length) {
          //   setActiveCustomerMembershipId(results?.data?.[0]?.id);
          // }
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchUsers();
  }, [customerId]);

  return (
    <MDBox my={3}>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDInput
          label="customer id"
          variant="outlined"
          onBlur={(e) => {
            setCustomerId(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </MDBox>
      <Card>
        {!usersmembership?.data && (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        )}
        {usersmembership?.data && (
          <DataTable
            table={{
              columns: usersHeaders,
              rows: usersmembership.data.map((user) => ({
                customerId: user.customer_id,
                highestCombinedPoints: user.highest_combined_points,
                numberTimesPointsTransferred: user.number_times_points_transferred,
                currentRewardPoints: user.current_reward_points,
                currentMembership: user.current_membership,
                dateAdded: user?.date_added ? displayBasicDate(user?.date_added) : "/",
                pointsDateModified: user?.date_modified
                  ? displayBasicDate(user?.date_modified)
                  : "/",

                // actions: user,
              })),
            }}
            nextPageUrl={usersmembership.next}
            previousPageUrl={usersmembership.previous}
            countPagination={usersmembership.count}
            searchKeyObjectPagination={searchKeyObjectPagination}
          />
        )}
      </Card>
    </MDBox>
  );
}
