import React, { createContext, useReducer } from "react";

const SET_CATEGORIES_ACTIONS = {
  SET_CATEGORIES: "SET_CATEGORIES",
  CLEAR_CATEGORIES: "CLEAR_CATEGORIES",
  FILTER: "SET_FILTERS",
};

// Initial state of the brands context
const initialState = {
  categories: null,
  categoriesFilters: {
    onlyMainCategories: false,
    searchKey: null,
  },
};

// Categories context reducer function
const categoriesReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CATEGORIES_ACTIONS.SET_CATEGORIES:
      return { ...state, categories: payload };
    case SET_CATEGORIES_ACTIONS.CLEAR_CATEGORIES:
      return initialState;
    case SET_CATEGORIES_ACTIONS.FILTER:
      return {
        ...state,
        categoriesFilters: { ...state.categoriesFilters, [payload.name]: payload.value },
      };
    default:
      return state;
  }
};

// Create the categories context
const CategoriesContext = createContext();

// categories context provider component
const CategoriesContextProvider = ({ children }) => {
  const [{ categories, categoriesFilters }, dispatch] = useReducer(categoriesReducer, initialState);

  // Action to set the categories context
  const setCategories = (categories) => {
    dispatch({ type: SET_CATEGORIES_ACTIONS.SET_CATEGORIES, payload: categories });
  };

  // Action to clear the categories context
  const clearCategories = () => {
    dispatch({ type: SET_CATEGORIES_ACTIONS.CLEAR_CATEGORIES });
  };

  const setCategoriesFilter = (name, value) => {
    const filterObj = { name, value };
    dispatch({ type: SET_CATEGORIES_ACTIONS.FILTER, payload: filterObj });
  };

  const value = {
    categories,
    setCategories,
    clearCategories,
    setCategoriesFilter,
    categoriesFilters,
  };
  return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};

export { CategoriesContext, CategoriesContextProvider };
