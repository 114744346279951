import React, { useEffect, useState } from "react";
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

import { FetchRanks } from "utils/apis/users/users-ranking";
import UserRankCard from "./components/UserRankCard";

export default function RanksList() {
  const [ranks, setRanks] = useState();

  useEffect(() => {
    const fetchUserRanking = async () => {
      try {
        const response = await FetchRanks();

        if (response.data) {
          const { results } = response.data;
          setRanks(results);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchUserRanking();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox className="before-footer" mb={4}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/ranks/create"}>
            <MDButton variant="gradient" color="info">
              New Rank
            </MDButton>
          </Link>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            {ranks?.map((rank) => (
              <Grid item xs={12} md={4} key={rank?.id}>
                <UserRankCard
                  rankName={rank?.rank_name?.[0]?.rank_name}
                  minRange={rank?.min_points}
                  maxRange={rank?.max_points}
                  id={rank?.id}
                  setRanks={setRanks}
                  ranks={ranks}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
