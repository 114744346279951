import { useState } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { DeleteImageFromProduct } from "utils/apis/catalog/products/products";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import YesOrNoDialog from "elements/YesOrNoDialog";
import MDInput from "components/MDInput";

function ProductImagesViewer({ images, setRerender, setImages }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);

  const handleClickOpen = (imageForDeleteId) => {
    setSelectedImageId(imageForDeleteId);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const deleteImage = async () => {
    await DeleteImageFromProduct(selectedImageId);
    setRerender((prev) => !prev);
  };

  const handleSortOrderChange = (e, index) => {
    setImages((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          // Update the sort order of the target item
          return { ...item, sort_order: e.target.value };
        }
        return item; // Return other items unchanged
      });
    });
  };

  return (
    <MDBox>
      {images?.length ? (
        <>
          <Grid container spacing={4}>
            {images.map((image, index) => (
              <Grid item xs={12} lg={3}>
                <MDBox sx={{ position: "relative" }}>
                  <MDBox
                    key={image.product_image_id}
                    component="img"
                    id={image.product_image_id}
                    src={image.image}
                    alt="product image"
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    height="16rem"
                    sx={{ objectFit: "cover" }}
                    // onClick={handleSetCurrentImage}
                  />

                  {/* <MDTypography>sort order : {image?.sort_order}</MDTypography> */}
                  <MDInput
                    value={image?.sort_order}
                    label="sort_order"
                    fullWidth
                    type="number"
                    onChange={(e) => handleSortOrderChange(e, index)}
                  />

                  <HighlightOffIcon
                    sx={{ position: "absolute", top: 8, right: 8, cursor: "pointer" }}
                    color="primary"
                    fontSize="large"
                    onClick={() => {
                      handleClickOpen(image.product_image_id);
                    }}
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <MDTypography>Product has no Images</MDTypography>
      )}
      <YesOrNoDialog
        heading="Are you sure you want to delete this image !?"
        description="if you delete it, you can not back from this"
        functiontoDo={deleteImage}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
    </MDBox>
  );
}
export default ProductImagesViewer;
