// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { usersHeaders } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

// Request Utils

import { fetchAdminUsers } from "utils/apis/users/users";

// users list contxt
import { UsersContext } from "context/users";

import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";

// loged in user
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import "app.css";
import { formatDateToISO, displayBasicDate } from "utils/methods";

function AdminsList() {
  // State for seraching
  const { users, setUsers } = useContext(UsersContext);
  const [userName, setUserName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [LastName, setLastName] = useState(null);
  const [dateJoined, setDateJoined] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(0);
  const [isActive, setIsActive] = useState(0);

  // Accessing user context
  const { user } = useContext(UserContext);

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  const handleDateJoinedChange = (_, value) => {
    setDateJoined(value);
  };

  const handleLtvStartDateChange = (_, value) => {
    setLtvStartDate(value);
  };
  const handleLtvEndDateChange = (_, value) => {
    setLtvEndDate(value);
  };

  // Fetch the users
  useEffect(() => {
    const fetchUsers = async () => {
      let searchKeyObject;
      if (!userName && !firstName && !LastName && !dateJoined && !isActive) {
        searchKeyObject = {};
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            username: userName,
            first_name: firstName,
            last_name: LastName,
            date_joined: dateJoined ? formatDateToISO(dateJoined) : null,
            is_superuser: isSuperuser,
            is_active: isActive,
          }).filter(([_, value]) => value)
        );
      }
      try {
        const response = await fetchAdminUsers(searchKeyObject);

        if (response.data) {
          const { count, next, previous, results } = response.data;
          return setUsers({
            count,
            next,
            previous,
            data: results,
          });
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchUsers();
  }, [userName, firstName, LastName, dateJoined, isSuperuser, isActive]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox className="before-footer users-page" my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Link to={"/users/create"}>
            <MDButton variant="gradient" color="info">
              New user
            </MDButton>
          </Link>
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={2}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={9} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="userName"
                    variant="outlined"
                    onBlur={(e) => {
                      setUserName(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="firstName"
                    variant="outlined"
                    onBlur={(e) => {
                      setFirstName(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDInput
                    label="lastName"
                    variant="outlined"
                    onBlur={(e) => {
                      setLastName(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <MDDatePicker
                    input={{
                      id: "date_joined",
                      name: "date_joined",
                      placeholder: "Select date_joined",
                    }}
                    name="date_joined"
                    options={{
                      enableTime: true,
                      enableSeconds: true,
                    }}
                    onChange={handleDateJoinedChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <FormGroup>
                <Grid container>
                  <Grid item lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { border: "0.0625rem solid #000000 !important" },
                          }}
                          checked={!!isActive}
                          onChange={(event) => {
                            setIsActive(event.target.checked ? 1 : 0);
                          }}
                        />
                      }
                      label="Is Active"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": { border: "0.0625rem solid #000000 !important" },
                          }}
                          checked={!!isSuperuser}
                          onChange={(event) => {
                            setIsSuperuser(event.target.checked ? 1 : 0);
                          }}
                        />
                      }
                      label="Superuser"
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
        <Card>
          {!users?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {users?.data && (
            <DataTable
              table={{
                columns: usersHeaders,
                rows: users.data.map((user) => ({
                  id: user.id,
                  name: user.first_name + " " + user.first_name,
                  username: user.username,
                  is_active: user.is_active ? "Yes" : "NO",
                  dateJoined: user?.date_joined ? displayBasicDate(user?.date_joined) : "/",
                  actions: user,
                })),
              }}
              nextPageUrl={users.next}
              previousPageUrl={users.previous}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminsList;
