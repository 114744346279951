import IconImageCell from "../components/IconImageCell/index";
import ActionsCell from "../components/ActionsCell/index";
import MDBadge from "components/MDBadge";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";
import MDBox from "components/MDBox";
// Images

export const notesHeaders = [
  { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },

  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Type",
    accessor: "type",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Bg Color",
    accessor: "bgColor",
    Cell: ({ value }) => (
      <MDBox sx={{ backgroundColor: value, paddingInline: 2, paddingBlock: 0.5, borderRadius: 4 }}>
        {value}
      </MDBox>
    ),
  },

  {
    Header: "Color",
    accessor: "color",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Icon",
    accessor: "icon",
    Cell: ({ value }) => <IconImageCell image={value} />,
  },

  {
    Header: "Category",
    accessor: "category",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Date Added",
    accessor: "dateAdded",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Date Modified",
    accessor: "dateModified",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
