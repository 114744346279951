import MDInput from "components/MDInput";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FetchUserAddress } from "utils/apis/address";
import { NotificationsContext } from "context/notifications";
import { displayBasicDate } from "utils/methods";

export default function BasicInformation({ parentState, upadteParentState }) {
  const { setErrorMSG } = useContext(NotificationsContext);
  const [userAdress, setUserAddress] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;

    upadteParentState((previouseState) => {
      return {
        ...previouseState,
        step1: {
          ...previouseState.step1,
          [name]: value,
        },
      };
    });
  };

  const handleEnableOrDisableChange = (event) => {
    if (event.target.value == "enable") {
      upadteParentState((prev) => {
        return { ...prev, step1: { ...prev.step1, is_active: true } };
      });
    } else if (event.target.value == "disable") {
      upadteParentState((prev) => {
        return { ...prev, step1: { ...prev.step1, is_active: false } };
      });
    }
  };

  useEffect(() => {
    if (parentState.id) {
      const getAdress = async () => {
        // fetch parent categories to show them as options
        const result = await FetchUserAddress(parentState.id);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            const { results } = result.data;
            return setUserAddress(results);
          } else {
            // this is backend validation error
            return setErrorMSG(result.data.error);
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };
      getAdress();
    }
  }, [parentState.id]);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Basic Information
      </MDTypography>

      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <MDInput
              value={parentState.first_name}
              name="first_name"
              label="first_name"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDInput
              value={parentState.last_name}
              name="last_name"
              label="last_name"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDInput
              value={parentState.username}
              name="username"
              label="username"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography>
              Date Joined : {parentState.date_joined && displayBasicDate(parentState.date_joined)}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl>
              {/* <FormLabel id="enable-or-disable-user">Notification For</FormLabel> */}
              <MDTypography>please be careful with that</MDTypography>
              <RadioGroup
                row
                aria-labelledby="enable-or-disable-user"
                name="enable-or-disable-user-group"
                onChange={handleEnableOrDisableChange}
              >
                <FormControlLabel
                  value="enable"
                  control={<Radio checked={parentState.is_active} />}
                  label="Enable"
                />
                <FormControlLabel
                  value="disable"
                  control={<Radio checked={!parentState.is_active} />}
                  label="Disable"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MDTypography>Adresses :</MDTypography>
          </Grid>
          {userAdress?.map((address, index) => (
            <Grid item xs={12} md={6} key={index}>
              <MDBox>
                <MDTypography>Adresses {index + 1}</MDTypography>
                <MDTypography>address_1 : {address.address_1}</MDTypography>
                <MDTypography>address_2 : {address.address_2}</MDTypography>
                <MDTypography>city : {address.city}</MDTypography>
                <MDTypography>zone_id : {address.zone_id}</MDTypography>
                <MDTypography>postcode : {address.postcode}</MDTypography>
                <MDTypography>alternative_phone : {address.alternative_phone}</MDTypography>
                <MDTypography>address_type : Home</MDTypography>
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
}
