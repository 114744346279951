// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import CircularProgress from "@mui/material/CircularProgress";
// Otis Admin PRO React components
import MDBox from "components/MDBox";

import { Link } from "react-router-dom";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ComplexStatisticsCard from "elements/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "elements/Cards/BookingCard";

import { fetchDashboardStatistics } from "utils/apis/dashboard_statistics/statistics";
import { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "context/notifications";

import DataTable from "./DataTable";
import {
  validated_top_5_purchase_total_customers_Headers,
  top_5_customer_users_Headers,
} from "./data/dataTableData";
import { displayBasicDate } from "utils/methods";
import MDTypography from "components/MDTypography";

function Analytics() {
  const [dashboardStatistics, setDashboardStatistics] = useState();

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the users
  useEffect(() => {
    const fetchDashboardStatisticsFun = async () => {
      try {
        const response = await fetchDashboardStatistics();

        if (response.data) {
          setDashboardStatistics(response.data);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchDashboardStatisticsFun();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {JSON.stringify(dashboardStatistics)} */}
      <MDBox py={3}>
        {!dashboardStatistics ? (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <MDBox mt={1.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <Link to="/customers">
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Customers"
                      count={dashboardStatistics?.total_customers}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Just updated",
                      }}
                    />
                  </MDBox>
                </Link>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Link to="/sales/orders">
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="shopping_cart"
                      title="Orders"
                      count={dashboardStatistics?.total_number_orders}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Just updated",
                      }}
                    />
                  </MDBox>
                </Link>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Total Amount"
                    count={parseFloat(dashboardStatistics?.total_amount_of_orders).toFixed(2)}
                    percentage={{
                      color: "success",
                      amount: "+3%",
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="store"
                    title="Revenue"
                    count="34k"
                    percentage={{
                      color: "success",
                      amount: "+1%",
                      label: "than yesterday",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container rowGap={2}>
              <Grid item xs={12}>
                <MDTypography sx={{ px: 1, py: 0.5, mb: 0.5, fontWeight: "bold" }}>
                  Most by price
                </MDTypography>
                <DataTable
                  table={{
                    columns: validated_top_5_purchase_total_customers_Headers,
                    rows: dashboardStatistics?.validated_top_5_purchase_total_customers.map(
                      (customer) => ({
                        username: customer.customer.username,
                        name: customer.customer.first_name + " " + customer.customer.last_name,
                        active: customer.customer.is_active,
                        date_joined: customer.customer.date_joined
                          ? displayBasicDate(customer.customer.date_joined)
                          : "no date",
                        operating_system: customer.customer.user_login_details.operating_system,
                        last_login_ip: customer.customer.user_login_details.last_login_ip,
                        login_time: customer.customer.user_login_details.login_time,
                        total_amount: customer.total_amount,
                      })
                    ),
                  }}
                  nextPageUrl="https://test-backend.shaghlaty.com/orders_admin/?page=2"
                  entriesPerPage={false}
                  previousPageUrl={null}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography sx={{ px: 1, py: 0.5, mb: 0.5, fontWeight: "bold" }}>
                  Most by orders numer
                </MDTypography>
                <DataTable
                  table={{
                    columns: top_5_customer_users_Headers,
                    rows: dashboardStatistics?.top_5_customer_users.map((customer) => ({
                      username: customer.customer.username,
                      name: customer.customer.first_name + " " + customer.customer.last_name,
                      active: customer.customer.is_active,
                      date_joined: customer.customer.date_joined
                        ? displayBasicDate(customer.customer.date_joined)
                        : "no date",
                      operating_system: customer.customer.user_login_details.operating_system,
                      last_login_ip: customer.customer.user_login_details.last_login_ip,
                      login_time: customer.customer.user_login_details.login_time,
                      order_count: customer.order_count,
                    })),
                  }}
                  nextPageUrl="https://test-backend.shaghlaty.com/orders_admin/?page=2"
                  entriesPerPage={false}
                  previousPageUrl={null}
                />
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
