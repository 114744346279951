import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

import ActionsCell from "../component/ActionsCell";
export const usersHeaders = [
  {
    Header: "customer id",
    accessor: "customerId",
    Cell: ({ value }) => <IdCell id={value?.toString()} />,
  },
  {
    Header: "highest combined points",
    accessor: "highestCombinedPoints",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "times points transferred",
    accessor: "numberTimesPointsTransferred",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "current reward points",
    accessor: "currentRewardPoints",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "current membership",
    accessor: "currentMembership",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "date added",
    accessor: "dateAdded",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "points date modified",
    accessor: "pointsDateModified",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  // {
  //   Header: "Actions",
  //   accessor: "actions",
  //   Cell: ({ value }) => <ActionsCell user={value} id={value.id} />,
  // },
];
