// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "./DataTable/index";

// Data table headers
import { categoriesListHeader } from "./data/dataTableData";

import { NotificationsContext } from "context/notifications";

import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

// Request Utils
import { CategoriesContext } from "context/categories";
import { UserContext } from "context/user";
import { useContext, useEffect, useState } from "react";
import { FetchAdminCategories } from "utils/apis/catalog/categories";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

function CategoriesList() {
  // Categories State
  const { categories, setCategories, categoriesFilters, setCategoriesFilter } =
    useContext(CategoriesContext);
  const [searchKey, setSearchKey] = useState(categoriesFilters.searchKey || null);
  const [onlyMainCategories, setOnlyMainCategories] = useState(
    categoriesFilters.onlyMainCategories || false
  );
  const [searchKeyObjectPagination, setSearchKeyObjectPagination] = useState({});

  //Access notifications context
  const { setErrorMSG } = useContext(NotificationsContext);

  // Fetch the catagories
  useEffect(() => {
    const fetchCategories = async () => {
      let searchKeyObject;
      if (!searchKey && !onlyMainCategories) {
        searchKeyObject = {};
        setSearchKeyObjectPagination({});
      } else {
        searchKeyObject = Object.fromEntries(
          Object.entries({
            name: searchKey,
            parent_id: onlyMainCategories ? 0 : null,
          }).filter(([_, value]) => value !== undefined && value !== null)
        );
        setSearchKeyObjectPagination(searchKeyObject);
      }
      const result = await FetchAdminCategories(searchKeyObject);

      // this is a valid response
      if (!(result instanceof Error)) {
        // check if there is a validations errors
        if (!result.data.error) {
          const { count, next, previous, results } = result.data;
          return setCategories({
            count,
            next,
            previous,
            data: results,
          });
        } else {
          // this is backend validation error
          return setErrorMSG(result.data.error);
        }
      } else {
        // this is server error or other erro that could happen
        return setErrorMSG(result.message);
      }
    };

    fetchCategories();
  }, [searchKey, onlyMainCategories]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  useEffect(() => {
    setCategoriesFilter("searchKey", searchKey);
    setCategoriesFilter("onlyMainCategories", onlyMainCategories);
  }, [searchKey, onlyMainCategories]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Link to={"/catalog/categories/create"}>
            <MDButton variant="gradient" color="info">
              new Category
            </MDButton>
          </Link>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              "@media only screen and (max-width: 435px)": {
                gap: 1,
                flexDirection: "column",
                maxWidth: "46vw",
              },
            }}
          >
            {/* <MDButton variant={menu ? "contained" : "outlined"} color="dark">
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton> */}
            {/* {renderMenu} */}
            {/* <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
            <MDInput
              label="searching for categories"
              variant="outlined"
              onBlur={(e) => {
                setSearchKey(e.target.value);
              }}
              defaultValue={searchKey}
              onKeyDown={(e) => handleKeyPress(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": { border: "0.0625rem solid #000000 !important" },
                  }}
                  checked={onlyMainCategories}
                  onChange={(event) => {
                    setOnlyMainCategories(event.target.checked);
                  }}
                />
              }
              label="just main categories"
            />
          </MDBox>
        </MDBox>
        <Card>
          {!categories?.data && (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "80vh",
              }}
            >
              <CircularProgress color="info" />
            </MDBox>
          )}
          {categories?.data && (
            <DataTable
              table={{
                columns: categoriesListHeader,
                rows: categories.data.map((category) => ({
                  id: category.category_id,
                  name: category.description[0]?.name || "no name",
                  image: category.image,
                  sortOrder: category.sort_order,
                  actions: category.category_id,
                })),
              }}
              nextPageUrl={categories.next}
              previousPageUrl={categories.previous}
              countPagination={categories.count}
              searchKeyObjectPagination={searchKeyObjectPagination}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CategoriesList;
