import axios from "axios";

const vonageSendMessageRoute = "https://msg.shaghlaty.com/v1/api-messages/vonage-send-message";

export const vonageSendMessage = async (messageObjectBody, page = vonageSendMessageRoute) => {
  const response = await axios.post(page, messageObjectBody, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
