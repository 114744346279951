import React, { useState } from "react";
import MDInput from "components/MDInput";
import ImagePreview from "./ImagePreview";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Card, Stack } from "@mui/material";
export default function InputFileAndPreview({ handleImageUpload, file }) {
  return (
    <Card sx={{ p: 2 }}>
      <Stack>
        {file && <ImagePreview fileInput={file} alt="uplaod image" />}

        <MDInput type="file" onChange={handleImageUpload} />
      </Stack>
    </Card>
  );
}
