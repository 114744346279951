import ActionsCell from "../components/ActionsCell";

import IdCell from "components/TableCells/IdCell/";
import DefaultCell from "components/TableCells/DefaultCell/";

// Images

export const requestsListHeaders = [
  { Header: "Request Id", accessor: "id", Cell: ({ value }) => <IdCell id={value.toString()} /> },
  {
    Header: "Customer Name",
    accessor: "customer",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Product Name",
    accessor: "productName",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Model",
    accessor: "model",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Required Quantity",
    accessor: "requiredQuantity",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Date Added",
    accessor: "dateAdded",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ value }) => <ActionsCell id={value} />,
  },
];
