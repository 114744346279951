import React, { createContext, useReducer } from "react";

const SET_BANNERS_ACTIONS = {
  SET_BANNERS: "SET_BANNERS",
  CLEAR_BANNERS: "CLEAR_BANNERS",
};

const initialState = {
  banners: null,
};

const bannersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_BANNERS_ACTIONS.SET_BANNERS:
      return { ...state, banners: payload };
    case SET_BANNERS_ACTIONS.CLEAR_BANNERS:
      return initialState;
    default:
      return state;
  }
};

const BannersContext = createContext();

const BannersContextProvider = ({ children }) => {
  const [{ banners }, dispatch] = useReducer(bannersReducer, initialState);

  const setBanners = (banners) => {
    dispatch({ type: SET_BANNERS_ACTIONS.SET_BANNERS, payload: banners });
  };

  const clearBanners = () => {
    dispatch({ type: SET_BANNERS_ACTIONS.CLEAR_BANNERS });
  };

  const value = { banners, setBanners, clearBanners };
  return <BannersContext.Provider value={value}>{children}</BannersContext.Provider>;
};

export { BannersContext, BannersContextProvider };
