import React, { createContext, useReducer } from "react";

const SET_USERS_ACTIONS = {
  SET_USERS: "SET_USER",
  CLEAR_USERS: "CLEAR_USER",
  FILTER: "SET_FILTERS",
};

// Initial state of the users context

const initialState = {
  users: null,
  usersFilters: {
    usersWithZeroOrders: false,
    usersWithDoneOrders: false,
    isActive: false,
    userName: null,
    firstName: null,
    LastName: null,
    dateJoined: null,
    selectedCategory: null,
    ltvMaxThreshold: null,
    ltvMinThreshold: null,
    ltvStartDate: null,
    ltvEndDate: null,
  },
};
// Users context reducer function
const usersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USERS_ACTIONS.SET_USERS:
      return { ...state, users: payload };
    case SET_USERS_ACTIONS.CLEAR_USERS:
      return initialState;
    case SET_USERS_ACTIONS.FILTER:
      return { ...state, usersFilters: { ...state.usersFilters, [payload.name]: payload.value } };
    default:
      return state;
  }
};

// Create the user context
const UsersContext = createContext();

// Users context provider component
const UsersContextProvider = ({ children }) => {
  const [{ users, usersFilters }, dispatch] = useReducer(usersReducer, initialState);

  // Action to set the user context
  const setUsers = (users) => {
    dispatch({ type: SET_USERS_ACTIONS.SET_USERS, payload: users });
  };

  // Action to clear the user context
  const clearUsers = () => {
    dispatch({ type: SET_USERS_ACTIONS.CLEAR_USERS });
  };

  const setUsersFilter = (name, value) => {
    const filterObj = { name, value };
    dispatch({ type: SET_USERS_ACTIONS.FILTER, payload: filterObj });
  };

  const value = { users, setUsers, clearUsers, setUsersFilter, usersFilters };
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

export { UsersContext, UsersContextProvider };
