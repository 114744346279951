import React, { createContext, useReducer } from "react";

const SET_ORDERS_ACTIONS = {
  SET_ORDERS: "SET_ORDERS",
  CLEAR_ORDERS: "CLEAR_ORDERS",
  FILTER: "SET_FILTERS",
};

// Initial state of the orders context
const initialState = {
  orders: null,
  ordersFilters: {
    orderId: null,
    customerId: null,
    byProduct: null,
    customerFirstname: null,
    customerPhoneNumber: null,
    orderStatusId: null,
    coupon: null,
  },
};

// Orders context reducer function
const ordersReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ORDERS_ACTIONS.SET_ORDERS:
      return { ...state, orders: payload };
    case SET_ORDERS_ACTIONS.CLEAR_ORDERS:
      return initialState;
    case SET_ORDERS_ACTIONS.FILTER:
      return {
        ...state,
        ordersFilters: {
          ...state.ordersFilters,
          [payload.name]: payload.value,
        },
      };
    default:
      return state;
  }
};

// Create the orders context
const OrdersContext = createContext();

// Orders context provider component
const OrdersContextProvider = ({ children }) => {
  const [{ orders, ordersFilters }, dispatch] = useReducer(ordersReducer, initialState);

  // Action to set the orders context
  const setOrders = (orders) => {
    dispatch({ type: SET_ORDERS_ACTIONS.SET_ORDERS, payload: orders });
  };

  // Action to clear the orders context
  const clearOrders = () => {
    dispatch({ type: SET_ORDERS_ACTIONS.CLEAR_ORDERS });
  };

  const setOrdersFilter = (name, value) => {
    const filterObj = { name, value };
    console.log(name, value);
    dispatch({ type: SET_ORDERS_ACTIONS.FILTER, payload: filterObj });
  };

  const value = { orders, setOrders, clearOrders, setOrdersFilter, ordersFilters };
  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
};

export { OrdersContext, OrdersContextProvider };
