import { useState, useContext, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, FormControl, MenuItem, Select } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Otis Admin PRO React example components
import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDInput from "components/MDInput";

import { useNavigate } from "react-router-dom";

// User context

import { UserContext } from "context/user";

// Notifications Context

import { NotificationsContext } from "context/notifications";

import "app.css";

// Post banner method

import { useParams } from "react-router-dom";

import { FetchAdminCategories } from "utils/apis/catalog/categories";
import { EditAdminNotes, GetAdminNoteById } from "utils/apis/for_app/notes";

function NoteEdit() {
  const { id } = useParams();

  const defaultFormFields = {
    icon: null,
    title: "",
    type: "",
    language_id: null,
    status: "",
    bgColor: "",
    color: "",
    category_id: null,
    category_name: "",
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [categoriesOptions, setCategoriesOptions] = useState(null);

  const { title, icon, type, language_id, status, bgColor, color, category_id, category_name } =
    formFields || {};

  console.log(formFields);
  // access user context
  const { user } = useContext(UserContext);
  // access notifications context to show alerts
  const { setSuccessMSG, setErrorMSG, clearErrorMSG } = useContext(NotificationsContext);

  // use navigate hook

  const navigate = useNavigate();

  // handle change handler (general)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  // set image state
  const handelIconUpload = (event) => {
    const file = event.target.files[0];
    setFormFields((previousState) => {
      return {
        ...previousState,
        icon: file,
      };
    });
  };

  // handle create brand submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset validation messages
    clearErrorMSG();
    // Validate banner title and image
    if (!title || !icon) {
      return setErrorMSG("Please fill all the fields");
    }

    /// send post banner request
    const result = await EditAdminNotes(id, formFields);

    // check the result if it's an error or not
    if (!(result instanceof Error)) {
      // this is from backend (backend-validations),
      if (!result.data.error) {
        // show success notification
        setSuccessMSG("Note created successfuly");
        // navigate to brands list page
        return navigate("/notes/notes-list");
      } else {
        return setErrorMSG(result.data.error);
      }
    } else {
      return setErrorMSG(result.message);
    }
  };

  // handle category change
  const handleCategoryChange = (_, value, reason) => {
    // if event was selecting from list
    if (reason === "selectOption") {
      setFormFields({
        ...formFields,
        category_name: value.category_name,
        category_id: value.category_id,
      });
    } else {
      setFormFields({ ...formFields, category_name: "", category_id: "" });
    }

    // clear the state if the user cleard the input by icon or by removeing text
    // if (no category search key) => that means user selecting from default pages.
    if (reason === "clear") {
      setFormFields({ ...formFields, category_name: "", category_id: "" });
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const result = await FetchAdminCategories();

        if (result.status === 200 && result.data) {
          const { results } = result.data;
          console.log(result);
          // map tha categories as auto complete item
          const categoriesItems = results?.map((category) => ({
            label: category.description[0].name,
            category_id: category.category_id,
            category_name: category.description[0].name,
          }));

          // set the categories options state
          setCategoriesOptions(categoriesItems);
        }
      } catch (error) {
        console.log(error);
        setErrorMSG("something went wrong");
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (id) {
      const getNoteById = async () => {
        const result = await GetAdminNoteById(id);

        // this is a valid response
        if (!(result instanceof Error)) {
          // check if there is a validations errors
          if (!result.data.error) {
            // set state of category
            console.log(result.data, "sasasasasas");
            const {
              important_notes_id,
              important_notes_title,
              important_notes_type,
              language_id,
              important_notes_status,
              important_notes_bkcolor,
              important_notes_title_color,
              important_notes_icon,
              category_id,
              category_name,
              date_added,
              date_modified,
            } = result.data;

            setFormFields((prev) => {
              setFormFields({
                ...prev,
                icon: important_notes_icon,
                title: important_notes_title,
                type: important_notes_type,
                language_id,
                status: important_notes_status,
                bgColor: important_notes_bkcolor,
                color: important_notes_title_color,
                category_id,
                category_name,
              });
            });
          }
        } else {
          // this is server error or other erro that could happen
          return setErrorMSG(result.message);
        }
      };
      getNoteById();
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} className="before-footer">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mb={2} textAlign="center">
              <MDTypography variant="h3" fontWeight="bold">
                Edit Note
              </MDTypography>
            </MDBox>

            <Card>
              <MDBox p={3}>
                <MDBox mb={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Edit Note #{id || ""}
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={4}>
                    <MDInput
                      fullWidth
                      label="Title"
                      onChange={handleChange}
                      type="text"
                      name="title"
                      value={title}
                    />
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <MDInput type="file" onChange={handelIconUpload} />
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <MDInput
                      fullWidth
                      label="Type"
                      onChange={handleChange}
                      type="text"
                      name="type"
                      value={type}
                    />
                  </Grid>

                  <Grid item md={6} lg={4}>
                    <FormControl fullWidth>
                      <InputLabel id="language_id">Language</InputLabel>
                      <Select
                        value={language_id}
                        name="language_id"
                        onChange={handleChange}
                        labelId="language_id"
                        label="Language"
                        id="language_id_input"
                        fullWidth
                        sx={{
                          minWidth: 120,
                          lineHeight: "3rem",
                        }}
                      >
                        <MenuItem value={1}>English</MenuItem>
                        <MenuItem value={2}>Arabic</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <MDInput
                      fullWidth
                      label="Status"
                      onChange={handleChange}
                      type="text"
                      name="status"
                      value={status}
                    />
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <MDInput
                      name="bgColor"
                      fullWidth
                      label="Color code #HEXA"
                      type="color"
                      onChange={handleChange}
                      value={bgColor}
                    />
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <MDInput
                      name="color"
                      fullWidth
                      label="Color code #HEXA"
                      type="color"
                      onChange={handleChange}
                      value={color}
                    />
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <Autocomplete
                      getOptionSelected={(option, value) =>
                        option.category_id === value.category_id
                      }
                      value={category_name?.length ? category_name : null}
                      options={categoriesOptions ? categoriesOptions : []}
                      renderInput={(params) => (
                        <MDInput label="Select Category" {...params} variant="standard" />
                      )}
                      onChange={handleCategoryChange}
                    />
                  </Grid>
                </Grid>
                <MDBox display="flex" justifyContent="flex-end" mt={4}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    Update
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NoteEdit;
