import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { DeleteAdminBrand } from "utils/apis/ecommerce/brands";
import { useContext } from "react";

import { UserContext } from "context/user";
import { NotificationsContext } from "context/notifications";
import { BrandsContext } from "context/brands";
import { Link } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import YesOrNoDialog from "elements/YesOrNoDialog";

import { Icon } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

const ActionsCell = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // ** id is the brand id **

  // Access user context to get token and send delete request
  const { user } = useContext(UserContext);

  // Accsss brands to change the state after delete
  const { brands, setBrands } = useContext(BrandsContext);

  // Access the notifications to show alert
  const { setSuccessMSG, setErrorMSG } = useContext(NotificationsContext);

  // Delete Brands Action
  const handleDeleteBrand = async () => {
    const result = await DeleteAdminBrand(id);

    // this is a valid response
    if (!(result instanceof Error)) {
      if (result.data.status) {
        // brand deleted successfuly

        // remove it from the UI
        const { count, next, previous, data } = brands;
        const newBrands = data.filter((brand) => brand.manufacturer_id != id);

        setBrands({
          count,
          next,
          previous,
          data: newBrands,
        });

        setOpenDialog(false);

        // show deleted alert
        setSuccessMSG("Brand Deleted Successfuly");
      } else {
        // this is backend validation error
        return setErrorMSG(result.data.error);
      }
    } else {
      // this is server error or other erro that could happen
      return setErrorMSG(result.message);
    }
  };

  return (
    <>
      <YesOrNoDialog
        heading="Are you sure want to delete this brand !?"
        description="if you delete it, you can not back from this"
        functiontoDo={handleDeleteBrand}
        openDialog={openDialog}
        closeFunction={handleClose}
      />

      <Stack direction="row" spacing={1}>
        <HighlightOffIcon
          color="primary"
          fontSize="medium"
          onClick={handleClickOpen}
          sx={{ cursor: "pointer" }}
        />

        <Link to={`/ecommerce/brands/edit/${id}`}>
          <Icon color="info" fontSize="medium">
            edit
          </Icon>
        </Link>
      </Stack>
    </>
  );
};

export default ActionsCell;
